import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ProtocolListComponent } from '../protocol-list/protocol-list.component';

@Component({
  selector: 'app-protocol-scan',
  templateUrl: './protocol-scan.component.html',
  styleUrls: ['./protocol-scan.component.scss']
})
export class ProtocolScanComponent implements OnInit {
  @ViewChild('protocolList', {static: false}) protocolList: ProtocolListComponent;
  displayedColumns: string[] = ['name', 'status', 'triggers', 'action-btn'];
  ColumnSize: number[] = [35, 30, 30, 5];
  @Output() selectedLogs: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  refresh() {
    this.protocolList.refresh()
  }

  handleReload() {
    this.protocolList.selectedLogs = 2;
    this.protocolList.handleReload();
  }

}
