<div class="download-manager">
    <div class="center-screen">
        <div class="container">
            <div class="icon">
                <i [class]="iconClass"></i>
            </div>
            <h2>{{subtitle}}</h2>
            <p>{{type | titlecase}} for: <strong>{{companyName}}</strong></p>
            <button class="download-button" (click)="download()">Download</button>
        </div>
    </div>
</div>
