<!--Company Type-->
<div class="box__item box__item--sm box__item--editable" [class.align-items-start]="primaryCategory || additionalCategories">
  <div [class.txt--medium-gray]="isUnderReview">
    <p class="m--0" *ngIf="isEdit && !primaryCategory">Add Category</p>
    <div class="w--100 justify-content-between">
      <span class="m--0" [outerHTML]="primaryCategory?.displayName"></span>
      <span *ngIf="primaryCategory" class="selected-label ml--15">Primary</span>
    </div>
    <ng-container *ngIf="additionalCategories">
      <ul *ngFor="let category of additionalCategories">
        <li class="m--0 txt--lightgray" [outerHTML]="category?.displayName"></li>
      </ul>
    </ng-container>
    <p class="under-review mt--5" *ngIf="isUnderReview" role="alert">Under review</p>
  </div>
  <button mat-button (click)="openCategoryDialog()" class="btn btn-icon" *ngIf="isEdit">
    <i class="fas fa-pencil m--0"></i>
  </button>
</div>
