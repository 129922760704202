/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./compass-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/common";
import * as i10 from "./compass-button.component";
var styles_CompassButtonComponent = [i0.styles];
var RenderType_CompassButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompassButtonComponent, data: {} });
export { RenderType_CompassButtonComponent as RenderType_CompassButtonComponent };
function View_CompassButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse mr--10"]], null, null, null, null, null))], null, null); }
export function View_CompassButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "div", [], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "button", [["mat-button", ""]], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fireOutput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CompassButtonComponent_1)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(5, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "above"; var currVal_1 = (_co.disabled ? _co.disabledTooltip : (_co.loading ? _co.loadingTooltip : "")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.loading; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ("compass-button " + _co.color); var currVal_3 = _co.disabled; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.text; _ck(_v, 5, 0, currVal_5); }); }
export function View_CompassButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "compass-button", [], null, null, null, View_CompassButtonComponent_0, RenderType_CompassButtonComponent)), i1.ɵdid(1, 49152, null, 0, i10.CompassButtonComponent, [], null, null)], null, null); }
var CompassButtonComponentNgFactory = i1.ɵccf("compass-button", i10.CompassButtonComponent, View_CompassButtonComponent_Host_0, { text: "text", icon: "icon", color: "color", disabled: "disabled", disabledTooltip: "disabledTooltip", loading: "loading", loadingTooltip: "loadingTooltip" }, { clicked: "clicked" }, []);
export { CompassButtonComponentNgFactory as CompassButtonComponentNgFactory };
