// dep
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Element as StripeElement, ElementsOptions, StripeService } from 'ngx-stripe'

// app
import { PaymentsService } from '../../../services/payments.service'
import { ModalService } from "../../../services/modal.service"
import { SnackbarService } from '../../../services/snackbar.service'
import { NavigationService } from 'src/app/services/navigation.service'
import { SessionService } from 'src/app/services/session.service'
import { BaseComponent } from 'src/app/components/base.component'

// TODO: Rename to AddCardComponent, this component shows a form to add a new Credit Card, 
// NOT to update an existing one. Misleading name.
// Note this uses the deprecated Stripe "Card" Element
// Also see MAP-2234
// TODO: Remove after being full replaced by the new PaymentMethodComponent

const ELEMENT_OPTS : ElementsOptions = {
  locale: 'en'
} as const;

const ELEMENT_STYLE = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#8c91a2',
      lineHeight: '1.2',
      fontWeight: 300,
      fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
      fontSize: '16px',

      '::placeholder': { color: '#8c91a2' },
    },
    invalid: {
      iconColor: '#e85746',
      color: '#e85746',
    }
  },
  // @ts-ignore
  classes: {
    focus: 'is-focused',
    empty: 'is-empty',
  },
} as const;

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls:  ['./update-card.component.scss']
})
export class UpdateCardComponent extends BaseComponent implements OnInit {

  RETURN_TYPE : boolean
  
  private _stripeCardElement: StripeElement;

  public formCard: FormGroup;
  public loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<UpdateCardComponent>,
    private _formBuilder: FormBuilder,
    private _stripeS: StripeService,
    private _paymentS: PaymentsService,
    private _snackS: SnackbarService,
    private _modalS: ModalService,
    private _sessionS : SessionService,
    public navigationS : NavigationService,
  ) {
    super();
  }

  ngOnInit() : void {
    this.formCard = this._formBuilder.group({ name: ['', Validators.required] });

    this._subscribeSafe(this._stripeS.elements(ELEMENT_OPTS),
      elements => {
        if (this._stripeCardElement)
          // should never happen
          return

        this._stripeCardElement = elements.create('card', ELEMENT_STYLE);
        this._stripeCardElement.mount('#card-element');

        document.querySelectorAll('input.field').forEach(input => {
          input.addEventListener('focus', () => { input.classList.add(   'is-focused') })
          input.addEventListener('blur',  () => { input.classList.remove('is-focused') })
          input.addEventListener('keyup', () => {
            if (!(input as any).value.length) {
              input.classList.add('is-empty');
            } else {
              input.classList.remove('is-empty');
            }
          });
        });

        this._stripeCardElement.on('change', (event) => {
          const successElement = document.querySelector('.success');
          const errorElement = document.querySelector('.error');

          successElement.classList.remove('visible');
          errorElement.classList.remove('visible');

          if (event.token) {
            successElement.querySelector('.token').textContent = event.token.id;
            successElement.classList.add('visible');
          } else if (event.error) {
            errorElement.textContent = event.error.message;
            errorElement.classList.add('visible');
          }
        })
      })
 }


  /**
   * "Save" button handler
   */
  public async handleSaveCardButton() : Promise<void> {
    let token_id : string 
    try {
       this.loading = true

       const name = this.formCard.get('name').value

       // 1- Send the private card details and get a token that can be safely passed
       // Some validation is done here by Stripe.
       // - https://docs.stripe.com/api/tokens
       // - https://docs.stripe.com/docs/api/tokens/create_card
       const res = await this._stripeS.createToken(this._stripeCardElement, { name }).toPromise()        
    
       if (res.error)
         throw new Error(res.error?.message)

       // "token.id" has the pattern "tok_XXXXX"
       token_id = res.token.id

       // 2- Pass the token to the backend
       // It will call https://docs.stripe.com/api/cards/create
       // Stripe will do more card validation on that endpoint.
       await this._paymentS.addNewCard(this._sessionS.getSession().gid, token_id)
       this._snackS.openSuccess('New card added succesfully')
       this.dialogRef.close(true)
    } catch(e) {
      // User message comes from Stripe detailing Card validation/authorization errors
      const user_msg : string | undefined = (!token_id && e?.message) ? e.message : e?.error?.detail?.user_message

      if(token_id && !user_msg)
         // Log backend non-Stripe error to Datadog
         console.error("Error adding new Card (backend)", e)
       else
         console.log("Error adding new Card", e)
       
       // Show the error we get from Stripe or a fixed one if it's a backend non-Stripe error
       this._modalS.openErrorModal('Credit Card Failed', user_msg || 'Please try with a different credit card')
       this.dialogRef.close(false)
    } finally {
       this.loading = false
    }

  }

}
