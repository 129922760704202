import { __awaiter, __generator, __values } from "tslib";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { environment as ENV } from '@environment';
import * as moment from 'moment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UpdateCardComponent } from './../billing/update-card/update-card.component';
import { ModalService } from 'src/app/services/modal.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import { pkg_names, pkg_descriptions, pkg_features } from './../constants/pricing';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../services/session.service";
import * as i3 from "../../services/subscription.service";
import * as i4 from "../../services/payments.service";
import * as i5 from "../../services/modal.service";
import * as i6 from "../../services/snackbar.service";
var ManagePlanService = /** @class */ (function () {
    function ManagePlanService(_http, _sessionS, _subscriptionS, _paymentsS, _modalS, _snackS) {
        this._http = _http;
        this._sessionS = _sessionS;
        this._subscriptionS = _subscriptionS;
        this._paymentsS = _paymentsS;
        this._modalS = _modalS;
        this._snackS = _snackS;
        this._pricingObjectSubject$ = new BehaviorSubject(null);
        this._isLoading$ = new BehaviorSubject(false);
        this.emptyPricingObject();
    }
    ManagePlanService.prototype.loadPlan = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var momentDate1, momentDate2, trialDuration, daysLeftInTrial;
            return __generator(this, function (_c) {
                this._isLoading$.next(true);
                this.subscription = this._sessionS.getSession().subscription;
                if (this.subscription && this.subscription.locationsQty) {
                    this.fetchInvoices();
                    this.fetchCards();
                    // dates in this object are prone to cause errors if they are missing in the subscription
                    // they shouldn't be missing, but error handling logic should be implemented in the future
                    this.pricingObject.nextDueDate = this.subscription.nextDueDate;
                    this.pricingObject.billingOverride = this.subscription.billingOverride;
                    this.pricingObject.nextInvoice = this.subscription.nextDueDate || '';
                    this.pricingObject.trialStart = this.subscription.trialStart || '';
                    this.pricingObject.trialEnd = this.subscription.trialEnd || '';
                    this.pricingObject.trialActive = (this.subscription.status === 'TRIAL');
                    this.pricingObject.totalLocations = this.subscription.locationsQty;
                    this.getActiveAddons();
                    this.getTiers(); // requires totalLocations
                    this.getPricing(this.pricingObject.totalLocations, 'core');
                    if ((_b = (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.pricing) === null || _b === void 0 ? void 0 : _b.pkg_bulk_actions) {
                        this.getPricing(this.pricingObject.totalLocations, 'bulk');
                    }
                    momentDate1 = moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                    momentDate2 = moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                    trialDuration = momentDate1.diff(momentDate2, 'days');
                    // if today is after trialEnd then daysLeftInTrial has to be 0
                    if (moment().isAfter(moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) {
                        // trialProgress should be 100, because it has finished
                        this.pricingObject.trialProgress = 100;
                        this.pricingObject.trialLeft = 0;
                    }
                    else {
                        daysLeftInTrial = trialDuration - moment().diff(moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days');
                        this.pricingObject.trialLeft = daysLeftInTrial;
                        // trialProgress should be equal to daysLeftInTrial divided by trialDuration multiplied by 100
                        this.pricingObject.trialProgress = ((trialDuration - daysLeftInTrial) * 100) / trialDuration;
                    }
                    this._isLoading$.next(false);
                }
                else {
                    console.error('no subscription or missing locationsQty');
                    this._isLoading$.next(false);
                }
                this._pricingObjectSubject$.next(this.pricingObject);
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(ManagePlanService.prototype, "isLoading$", {
        // getters
        get: function () {
            return this._isLoading$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManagePlanService.prototype, "pricingObject$", {
        get: function () {
            return this._pricingObjectSubject$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    // methods for pricingObject
    ManagePlanService.prototype.fetchInvoices = function () {
        return __awaiter(this, void 0, void 0, function () {
            var invoices;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._subscriptionS.fetchInvoices(this.subscription.gid)]; // <-- move this to ngOnInit, its async
                    case 1:
                        invoices = _a.sent() // <-- move this to ngOnInit, its async
                        ;
                        this.pricingObject.invoices = invoices.data.filter(function (el) { return ['paid', 'pending', 'pre authorized', 'unpaid', 'canceled'].includes(el.status.toLowerCase()); });
                        this.pricingObject.invoices.reverse();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype.getActiveAddons = function () {
        var _a, _b;
        this.pricingObject.activeAddons.names = Object.keys((_a = this.subscription) === null || _a === void 0 ? void 0 : _a.packages);
        this.pricingObject.activeAddons.values = (_b = this.subscription) === null || _b === void 0 ? void 0 : _b.packages;
    };
    ManagePlanService.prototype.getTiers = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var coreTiers = (_c = (_b = (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.pricing) === null || _b === void 0 ? void 0 : _b.pkg_core) === null || _c === void 0 ? void 0 : _c.tiers;
        var bulkTiers = (_f = (_e = (_d = this.subscription) === null || _d === void 0 ? void 0 : _d.pricing) === null || _e === void 0 ? void 0 : _e.pkg_bulk_actions) === null || _f === void 0 ? void 0 : _f.tiers;
        var bulkFormattedTiers = [];
        coreTiers.forEach(function (tier, index) {
            var profileSegment = "" + tier.locationsQtyMin + (tier.locationsQtyMax && tier.locationsQtyMax > 1
                ? '-' + tier.locationsQtyMax
                : !tier.locationsQtyMax
                    ? '+'
                    : '');
            var isActive = _this.pricingObject.totalLocations >= tier.locationsQtyMin &&
                (tier.locationsQtyMax === undefined || _this.pricingObject.totalLocations <= tier.locationsQtyMax);
            var t = {
                tierNumber: index,
                locationsQtyMin: tier.locationsQtyMin,
                locationsQtyMax: tier.locationsQtyMax,
                price: tier.price,
                profileSegment: profileSegment,
                segmentPriceString: "" + (tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'),
                isActive: isActive
            };
            _this.pricingObject.coreTiers.push(t);
        });
        bulkTiers.forEach(function (tier, index) {
            var profileSegment = "" + tier.locationsQtyMin + (tier.locationsQtyMax && tier.locationsQtyMax > 1
                ? '-' + tier.locationsQtyMax
                : !tier.locationsQtyMax
                    ? '+'
                    : '');
            var isActive = _this.pricingObject.totalLocations >= tier.locationsQtyMin &&
                (tier.locationsQtyMax === undefined || _this.pricingObject.totalLocations <= tier.locationsQtyMax);
            var t = {
                tierNumber: index,
                locationsQtyMin: tier.locationsQtyMin,
                locationsQtyMax: tier.locationsQtyMax,
                price: tier.price,
                profileSegment: profileSegment,
                segmentPriceString: "" + (tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'),
                isActive: isActive
            };
            bulkFormattedTiers.push(t);
        });
        var bulkAddon = {
            name: 'pkg_bulk_actions',
            formattedName: pkg_names['pkg_bulk_actions'],
            assignedTierNumber: 0,
            unitPrice: 0,
            unitDiscount: 0,
            totalPrice: 0,
            tiers: bulkFormattedTiers,
            isActive: this.subscription.packages.hasOwnProperty('pkg_bulk_actions') && !this.subscription.packages['pkg_bulk_actions'].isTerminationScheduled,
            isTerminationScheduled: this.subscription.packages.hasOwnProperty('pkg_bulk_actions') && this.subscription.packages['pkg_bulk_actions'].isTerminationScheduled || false,
            terminationDate: this.pricingObject.nextDueDate || null,
            description: pkg_descriptions['pkg_bulk_actions'],
            features: pkg_features['pkg_bulk_actions'],
        };
        this.pricingObject.availableAddons.push(bulkAddon);
    };
    ManagePlanService.prototype.getPricing = function (locations, pkgName) {
        var e_1, _a, e_2, _b;
        var _c;
        switch (pkgName) {
            case 'core':
                var prcObj = this.pricingObject;
                var basePrice = (this.pricingObject.coreTiers.find(function (tier) { return tier.price > 0; })).price || 0;
                try {
                    for (var _d = __values(prcObj.coreTiers), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var tier = _e.value;
                        if (locations >= tier.locationsQtyMin &&
                            (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                            prcObj.unitPrice = tier.price;
                            prcObj.assignedCoreTierNumber = tier.tierNumber;
                            prcObj.assignedTierDiscount = prcObj.coreTiers[prcObj.assignedCoreTierNumber].price > 0
                                ? basePrice - prcObj.coreTiers[prcObj.assignedCoreTierNumber].price
                                : prcObj.coreTiers[prcObj.assignedCoreTierNumber].price;
                            prcObj.totalPrice =
                                (prcObj.coreTiers[prcObj.assignedCoreTierNumber].price - prcObj.assignedTierDiscount) * locations;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                break;
            case 'bulk':
                if (this.pricingObject.availableAddons.some(function (addon) { return addon.formattedName === pkg_names['pkg_bulk_actions']; })) {
                    var addon = this.pricingObject.availableAddons.find(function (addon) { return addon.formattedName === pkg_names['pkg_bulk_actions']; });
                    var basePrice_1 = (addon.tiers.find(function (tier) { return tier.price > 0; })).price || 0;
                    try {
                        for (var _f = __values(addon.tiers), _g = _f.next(); !_g.done; _g = _f.next()) {
                            var tier = _g.value;
                            if (locations >= tier.locationsQtyMin &&
                                (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                                addon.unitPrice = tier.price;
                                addon.assignedTierNumber = tier.tierNumber;
                                addon.assignedTierDiscount = addon.tiers[addon.assignedTierNumber].price > 0
                                    ? basePrice_1 - addon.tiers[addon.assignedTierNumber].price
                                    : addon.tiers[addon.assignedTierNumber].price;
                                addon.totalPrice =
                                    (addon.tiers[addon.assignedTierNumber].price - addon.assignedTierDiscount) * locations;
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                else {
                    console.error('addon not found');
                }
        }
        // calculate total price based on active add-ons
        this.pricingObject.totalPrice = (this.pricingObject.unitPrice - this.pricingObject.unitDiscount) * this.pricingObject.totalLocations;
        if (this.pricingObject.activeAddons.names.includes('pkg_bulk_actions')
            && !((_c = this.pricingObject.activeAddons.values['pkg_bulk_actions']) === null || _c === void 0 ? void 0 : _c.isTerminationScheduled)) {
            this.pricingObject.totalPrice +=
                this.pricingObject.availableAddons
                    .find(function (addon) { return addon.formattedName === pkg_names['pkg_bulk_actions']; }).totalPrice;
        }
    };
    ManagePlanService.prototype.reloadPricingObject = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoading$.next(true);
                        this.emptyPricingObject();
                        this.subscription = null;
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 1:
                        _a.sent();
                        this.loadPlan();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype.emptyPricingObject = function () {
        this.pricingObject = {
            unitPrice: 0,
            unitDiscount: 0,
            availableAddons: [],
            activeAddons: { names: [], values: {} },
            totalPrice: 0,
            totalLocations: 0,
            trialActive: false,
            trialDaysTotal: 0,
            trialStart: '',
            trialEnd: '',
            trialProgress: 0,
            trialLeft: 0,
            coreTiers: [],
            assignedCoreTierNumber: 0,
            assignedTierDiscount: 0,
            billingOverride: false,
            invoices: [],
            nextInvoice: '',
            cards: [],
        };
    };
    // methods for add-ons
    ManagePlanService.prototype.updateAddons = function (addOns) {
        var _a, _b;
        var gid = ((_b = (_a = this._sessionS.getSession()) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.gid) || null;
        if (!gid) {
            return of(null);
        }
        // TODO: should make the body dynamic and not hardcoded, as other addons won't work here
        var body = {
            packages: {
                pkg_core: {
                    isTerminationScheduled: false,
                },
                pkg_bulk_actions: {
                    isTerminationScheduled: !addOns[0].isActive,
                }
            }
        };
        return this._http.post(ENV.billingApiUrl + "/subscription/" + gid + "/packages", body)
            .pipe(map(function (res) {
            res = body;
            return res;
        }));
    };
    // methods for card management
    ManagePlanService.prototype.fetchCards = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cards;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._paymentsS.getCards(this.subscription.gid)];
                    case 1:
                        cards = _a.sent();
                        this.pricingObject.cards = cards || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype.onAddCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openModal(UpdateCardComponent, {})];
                    case 1:
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        return [4 /*yield*/, this._sessionS.refresh()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.fetchCards()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype.handleDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openConfirmModal("Set as default", "Are you sure you want use this Credit Card as the default one?", null, null, "Confirm", false, null, false)];
                    case 1:
                        if (_a.sent()) {
                            this.applyDefaultSource(card.card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype.applyDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._paymentsS.setDefaultSource(this.subscription.gid, card.id)];
                    case 1:
                        _a.sent();
                        this._snackS.openSuccess('Set as default Credit Card succesfully', 3000);
                        this.fetchCards();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype.handleDeleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openConfirmModal("Remove Credit Card", "Are you sure you want to remove this Credit Card?", null, null, "Confirm", false, null, false)];
                    case 1:
                        if (_a.sent()) {
                            this._deleteSource(card.card);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanService.prototype._deleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._paymentsS.deleteCard(this.subscription.gid, card.id)];
                    case 1:
                        _a.sent();
                        this._snackS.openSuccess('Removed Credit Card succesfully', 3000);
                        this.fetchCards();
                        return [2 /*return*/];
                }
            });
        });
    };
    // methods for PDF management
    ManagePlanService.prototype.onDownloadInvoicePDF = function (element) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        if (!((_a = element) === null || _a === void 0 ? void 0 : _a.invoicePDF) || ((_d = (_c = (_b = element) === null || _b === void 0 ? void 0 : _b.invoicePDF) === null || _c === void 0 ? void 0 : _c.task) === null || _d === void 0 ? void 0 : _d.status) !== 'OK') {
            return;
        }
        var invId = ((_f = (_e = element) === null || _e === void 0 ? void 0 : _e._id) === null || _f === void 0 ? void 0 : _f.$oid) || '';
        try {
            this._subscriptionS.getInvoicePDF(invId)
                .subscribe(function (blob) { return _this._downloadFile(blob, "invoice_" + invId + ".pdf"); }, function (error) { return _this._snackS.openError('There was an issue downloading the invoice'); });
        }
        catch (error) {
            this._snackS.openError('There was an issue downloading the invoice');
        }
    };
    ManagePlanService.prototype._downloadFile = function (blob, filename) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    ManagePlanService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManagePlanService_Factory() { return new ManagePlanService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.SubscriptionService), i0.ɵɵinject(i4.PaymentsService), i0.ɵɵinject(i5.ModalService), i0.ɵɵinject(i6.SnackbarService)); }, token: ManagePlanService, providedIn: "root" });
    return ManagePlanService;
}());
export { ManagePlanService };
