/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-manager.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./download-manager.component";
import * as i4 from "@angular/router";
import * as i5 from "../../services/subscription.service";
import * as i6 from "../../services/snackbar.service";
import * as i7 from "../../services/session.service";
var styles_DownloadManagerComponent = [i0.styles];
var RenderType_DownloadManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadManagerComponent, data: {} });
export { RenderType_DownloadManagerComponent as RenderType_DownloadManagerComponent };
export function View_DownloadManagerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "download-manager"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "center-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " for: "])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "download-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClass; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.subtitle; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.type)); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.companyName; _ck(_v, 12, 0, currVal_3); }); }
export function View_DownloadManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-download-manager", [], null, null, null, View_DownloadManagerComponent_0, RenderType_DownloadManagerComponent)), i1.ɵdid(1, 114688, null, 0, i3.DownloadManagerComponent, [i4.ActivatedRoute, i5.SubscriptionService, i6.SnackbarService, i7.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadManagerComponentNgFactory = i1.ɵccf("app-download-manager", i3.DownloadManagerComponent, View_DownloadManagerComponent_Host_0, {}, {}, []);
export { DownloadManagerComponentNgFactory as DownloadManagerComponentNgFactory };
