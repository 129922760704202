import { __assign, __awaiter, __generator } from "tslib";
// dep
import { isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from "@angular/fire/firestore";
// app
import { environment as ENV } from "@environment";
import { DOMAINS } from "../constants/firestore/collections";
import { HEADERS_NO_AUTH } from '../constants/auth';
import { BROWSER_DOMAIN } from '../helpers/utils.helpers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
var DomainService = /** @class */ (function () {
    function DomainService(_afs, _http) {
        this._afs = _afs;
        this._http = _http;
    }
    DomainService.prototype._fetchDomainFromFirestore = function (xdomain) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._afs.collection(DOMAINS).doc(xdomain).get().toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data()];
                }
            });
        });
    };
    DomainService.prototype.fetchCurrentDomain = function () {
        return __awaiter(this, void 0, void 0, function () {
            var domainBackP, domainFireP, pkeyDomainName, pkeyDomainFireP, domain, e_1, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        domainBackP = this._http.get(ENV.coreApiUrl + "/domains/" + BROWSER_DOMAIN.domainName, HEADERS_NO_AUTH).toPromise();
                        domainFireP = this._fetchDomainFromFirestore(BROWSER_DOMAIN.xDomainName);
                        pkeyDomainName = isDevMode() ? 'localhost' : 'app_maplabs_com';
                        pkeyDomainFireP = (pkeyDomainName === BROWSER_DOMAIN.xDomainName ?
                            domainFireP :
                            this._fetchDomainFromFirestore(pkeyDomainName));
                        domain = { trialDays: 30 };
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, domainBackP];
                    case 2:
                        domain = (_e.sent()).data;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _e.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        _a = [{}];
                        return [4 /*yield*/, domainFireP];
                    case 5:
                        _b = [__assign.apply(void 0, [__assign.apply(void 0, _a.concat([(_e.sent())])), domain])];
                        _c = {};
                        _d = {};
                        return [4 /*yield*/, pkeyDomainFireP];
                    case 6: return [2 /*return*/, __assign.apply(void 0, _b.concat([(_c.stripe_keys = (_d.public_key = (_e.sent()).stripe_keys.public_key, _d), _c)]))];
                }
            });
        });
    };
    DomainService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DomainService_Factory() { return new DomainService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: DomainService, providedIn: "root" });
    return DomainService;
}());
export { DomainService };
