<!--Phone-->
<div class="box__item box__item--editable" [class.align-items-start]="additionalPhones" [class.pb--0]="isUnderReview">
  <div class="d-flex w--100" [ngClass]="additionalPhones ? 'align-items-start' : 'align-items-center'">
    <div class="icon icon--rounded icon--gray mr--20"><i class="fas fa-phone"></i></div>
    <div class="d-block" [class.txt--medium-gray]="isUnderReview">
      <h4 class="w--100"><strong>Phone Number</strong></h4>
      <div *ngIf="hasPrimaryPhone">
        <p class="w--100" *ngIf="!primaryPhone && isEdit">Add Primary Phone</p>
        <div class="w--100 justify-content-between">
          <span *ngIf="primaryPhone" [outerHTML]="primaryPhone"></span>
          <span class="selected-label ml--15">Primary</span>
        </div>
      </div>
      <div *ngIf="additionalPhones" >
        <ng-container *ngFor="let phone of additionalPhones;">
          <p class="w--100 mb--5"> <span [outerHTML]="phone"></span></p>
        </ng-container>
      </div>
    </div>
  </div>
  <button mat-button (click)="openPhoneDialog()" class="btn btn-icon btn-icon--gray" *ngIf="isEdit"><em class="fas fa-pencil"></em></button>
</div>
<div *ngIf="isUnderReview" class="txt-pendingMask">
  <span class="under-review" role="alert">Under review</span>
</div>
