import { __assign } from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from '@environment';
import { Subject, forkJoin, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IReportFilterConfigData } from './../../../constants/report-filter-config-data';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var KeywordsReportDataService = /** @class */ (function () {
    function KeywordsReportDataService(_http) {
        this._http = _http;
        this._keywordsReportDataSubject = new Subject();
    }
    Object.defineProperty(KeywordsReportDataService.prototype, "keywordsReportDataSubject$", {
        get: function () {
            return this._keywordsReportDataSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    KeywordsReportDataService.prototype.getKeywordsUrl = function (filterConfig, keywordsBody) {
        var body = this.getAtlasBody(filterConfig, filterConfig.filteredKeyword);
        return (filterConfig.filteredKeyword ?
            this._http.post(ENV.apiUrl + "/v2/search/keyword-search", body) :
            this._http.post(ENV.apiUrl + "/v2/locations/search-keywords", keywordsBody));
    };
    KeywordsReportDataService.prototype.getKeywordsResults = function (filterConfig) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        console.log('getReportData called', filterConfig);
        var sortBy = ((_b = (_a = filterConfig) === null || _a === void 0 ? void 0 : _a.sort) === null || _b === void 0 ? void 0 : _b.sortBy.includes('Comparison')) && !((_c = filterConfig) === null || _c === void 0 ? void 0 : _c.period) ? 'impressions' : (_e = (_d = filterConfig) === null || _d === void 0 ? void 0 : _d.sort) === null || _e === void 0 ? void 0 : _e.sortBy;
        var sortOrder = ((_g = (_f = filterConfig) === null || _f === void 0 ? void 0 : _f.sort) === null || _g === void 0 ? void 0 : _g.sortBy.includes('Comparison')) && !((_h = filterConfig) === null || _h === void 0 ? void 0 : _h.period) ? -1 : (_k = (_j = filterConfig) === null || _j === void 0 ? void 0 : _j.sort) === null || _k === void 0 ? void 0 : _k.sortOrder;
        if ((_l = filterConfig) === null || _l === void 0 ? void 0 : _l.period) {
            sortBy = filterConfig.period + "Comparison";
            sortOrder = -1;
        }
        filterConfig.sort = {
            sortBy: sortBy,
            sortOrder: sortOrder
        };
        var keywordsBody = __assign(__assign({}, filterConfig), { page: (_m = filterConfig) === null || _m === void 0 ? void 0 : _m.page, size: (_o = filterConfig) === null || _o === void 0 ? void 0 : _o.size });
        var atlasKeywords = this.getAtlasBody(filterConfig, (_p = filterConfig) === null || _p === void 0 ? void 0 : _p.filteredKeyword);
        var atlasKeywords$ = this._http.post(ENV.apiUrl + "/v2/search/keyword-search", atlasKeywords);
        var keywords$ = this._http.post(ENV.apiUrl + "/v2/locations/search-keywords", keywordsBody);
        var keywordsBrand$ = this._http.post(ENV.apiUrl + "/v2/locations/keyword-brands", keywordsBody);
        var topics$ = this._http.post(ENV.apiUrl + "/v2/locations/keyword-trends", keywordsBody);
        var observables = [
            keywords$,
            keywordsBrand$,
            topics$
        ];
        if ((_q = filterConfig) === null || _q === void 0 ? void 0 : _q.filteredKeyword) {
            observables.push(atlasKeywords$);
        }
        // data retrieving
        forkJoin(observables).pipe(map(function (result) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65;
            console.log(result[0], result[1], result[2]);
            // build the report object
            // interface for reference
            _this.keywordsReportDataObject = {
                uniqueKeywords: null,
                brandedSearches: null,
                nonBrandedSearches: null,
                totalImpressions: null,
                totalKeywords: null,
                // structures taken form old keywords component
                // used in both creating the cards and part of the graphs
                // This interface needs optimization, this is the first step to
                // understand what does what and where. 
                // I will improve it to make more sense
                searchKeywordsData: {
                    comparison: {
                        actual: (_c = (_b = (_a = result[0]) === null || _a === void 0 ? void 0 : _a.comparison) === null || _b === void 0 ? void 0 : _b.keywords) === null || _c === void 0 ? void 0 : _c.actual,
                        trend: (_f = (_e = (_d = result[0]) === null || _d === void 0 ? void 0 : _d.comparison) === null || _e === void 0 ? void 0 : _e.keywords) === null || _f === void 0 ? void 0 : _f.trend,
                    },
                    graph: {
                        trend: {
                            total: (_l = (_k = (_j = (_h = (_g = result[1]) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.comparison) === null || _j === void 0 ? void 0 : _j.keywords) === null || _k === void 0 ? void 0 : _k.trend) === null || _l === void 0 ? void 0 : _l.total
                        },
                        current: {
                            total: (_r = (_q = (_p = (_o = (_m = result[1]) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.comparison) === null || _p === void 0 ? void 0 : _p.keywords) === null || _q === void 0 ? void 0 : _q.current) === null || _r === void 0 ? void 0 : _r.total
                        }
                    }
                },
                searchImpressionsData: {
                    trend: {
                        direct: (_w = (_v = (_u = (_t = (_s = result[1]) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.comparison) === null || _u === void 0 ? void 0 : _u.impressions) === null || _v === void 0 ? void 0 : _v.trend) === null || _w === void 0 ? void 0 : _w.direct,
                        discovery: (_1 = (_0 = (_z = (_y = (_x = result[1]) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.comparison) === null || _z === void 0 ? void 0 : _z.impressions) === null || _0 === void 0 ? void 0 : _0.trend) === null || _1 === void 0 ? void 0 : _1.discovery,
                        total: (_6 = (_5 = (_4 = (_3 = (_2 = result[1]) === null || _2 === void 0 ? void 0 : _2.data) === null || _3 === void 0 ? void 0 : _3.comparison) === null || _4 === void 0 ? void 0 : _4.impressions) === null || _5 === void 0 ? void 0 : _5.trend) === null || _6 === void 0 ? void 0 : _6.total,
                    },
                    current: {
                        direct: {
                            numeric: (_12 = (_11 = (_10 = (_9 = (_8 = (_7 = result[1]) === null || _7 === void 0 ? void 0 : _7.data) === null || _8 === void 0 ? void 0 : _8.comparison) === null || _9 === void 0 ? void 0 : _9.impressions) === null || _10 === void 0 ? void 0 : _10.current) === null || _11 === void 0 ? void 0 : _11.direct) === null || _12 === void 0 ? void 0 : _12.numeric,
                            percent: (_18 = (_17 = (_16 = (_15 = (_14 = (_13 = result[1]) === null || _13 === void 0 ? void 0 : _13.data) === null || _14 === void 0 ? void 0 : _14.comparison) === null || _15 === void 0 ? void 0 : _15.impressions) === null || _16 === void 0 ? void 0 : _16.current) === null || _17 === void 0 ? void 0 : _17.direct) === null || _18 === void 0 ? void 0 : _18.percent
                        },
                        discovery: {
                            numeric: (_24 = (_23 = (_22 = (_21 = (_20 = (_19 = result[1]) === null || _19 === void 0 ? void 0 : _19.data) === null || _20 === void 0 ? void 0 : _20.comparison) === null || _21 === void 0 ? void 0 : _21.impressions) === null || _22 === void 0 ? void 0 : _22.current) === null || _23 === void 0 ? void 0 : _23.discovery) === null || _24 === void 0 ? void 0 : _24.numeric,
                            percent: (_30 = (_29 = (_28 = (_27 = (_26 = (_25 = result[1]) === null || _25 === void 0 ? void 0 : _25.data) === null || _26 === void 0 ? void 0 : _26.comparison) === null || _27 === void 0 ? void 0 : _27.impressions) === null || _28 === void 0 ? void 0 : _28.current) === null || _29 === void 0 ? void 0 : _29.discovery) === null || _30 === void 0 ? void 0 : _30.percent // number; // or string?
                        },
                        total: (_35 = (_34 = (_33 = (_32 = (_31 = result[1]) === null || _31 === void 0 ? void 0 : _31.data) === null || _32 === void 0 ? void 0 : _32.comparison) === null || _33 === void 0 ? void 0 : _33.impressions) === null || _34 === void 0 ? void 0 : _34.current) === null || _35 === void 0 ? void 0 : _35.total,
                    }
                },
                labelsGraph: (_37 = (_36 = result[1]) === null || _36 === void 0 ? void 0 : _36.data) === null || _37 === void 0 ? void 0 : _37.labels,
                // used as datasets for app-chart-line
                impressionsStats: (_39 = (_38 = result[1]) === null || _38 === void 0 ? void 0 : _38.data) === null || _39 === void 0 ? void 0 : _39.impressions,
                keywordsStats: (_41 = (_40 = result[1]) === null || _40 === void 0 ? void 0 : _40.data) === null || _41 === void 0 ? void 0 : _41.keywords,
                dataPicker: null,
                // used as datasources for the keywords/topics table
                keywordsDataSource: ((_42 = result[3]) === null || _42 === void 0 ? void 0 : _42.data) || ((_43 = result[0]) === null || _43 === void 0 ? void 0 : _43.items) || [],
                keywordsPagination: {
                    total: ((_46 = (_45 = (_44 = result[0]) === null || _44 === void 0 ? void 0 : _44.comparison) === null || _45 === void 0 ? void 0 : _45.keywords) === null || _46 === void 0 ? void 0 : _46.actual) || 0,
                    page: (_47 = result[0]) === null || _47 === void 0 ? void 0 : _47.page,
                    pages: (_48 = result[0]) === null || _48 === void 0 ? void 0 : _48.pages,
                    per_page: (_49 = result[0]) === null || _49 === void 0 ? void 0 : _49.per_page,
                    sortBy: (_50 = result[0]) === null || _50 === void 0 ? void 0 : _50.sortBy,
                    sortOrder: (_51 = result[0]) === null || _51 === void 0 ? void 0 : _51.sortOrder,
                    hasNext: (_52 = result[0]) === null || _52 === void 0 ? void 0 : _52.hasNext,
                    hasPrev: (_53 = result[0]) === null || _53 === void 0 ? void 0 : _53.hasPrev
                },
                topicsDataSource: result[2].keyword_trends.items || [],
                topicsPagination: {
                    total: ((_55 = (_54 = result[2]) === null || _54 === void 0 ? void 0 : _54.keyword_trends) === null || _55 === void 0 ? void 0 : _55.total) || 0,
                    page: (_57 = (_56 = result[2]) === null || _56 === void 0 ? void 0 : _56.keyword_trends) === null || _57 === void 0 ? void 0 : _57.page,
                    pages: (_59 = (_58 = result[2]) === null || _58 === void 0 ? void 0 : _58.keyword_trends) === null || _59 === void 0 ? void 0 : _59.pages,
                    per_page: (_61 = (_60 = result[2]) === null || _60 === void 0 ? void 0 : _60.keyword_trends) === null || _61 === void 0 ? void 0 : _61.per_page,
                    hasNext: (_63 = (_62 = result[2]) === null || _62 === void 0 ? void 0 : _62.keyword_trends) === null || _63 === void 0 ? void 0 : _63.hasNext,
                    hasPrev: (_65 = (_64 = result[2]) === null || _64 === void 0 ? void 0 : _64.keyword_trends) === null || _65 === void 0 ? void 0 : _65.hasPrev
                }
            };
            _this._keywordsReportDataSubject.next(_this.keywordsReportDataObject);
        }), catchError(function (error) {
            console.error(error);
            return of({ error: true, message: 'failed when requesting data' });
        })).subscribe();
    };
    KeywordsReportDataService.prototype.getKeywordsTableData = function (filterConfig, keyword) {
        // this.keywordsReportDataObject = {
        //     ...this.keywordsReportDataObject,
        //     filteredKeyword: keyword
        // }
        if (!filterConfig.filteredKeyword || filterConfig.filteredKeyword === '') {
            this._getKeywordsTableDataUnfiltered(filterConfig);
        }
        else {
            this._getKeywordsTableDataFiltered(filterConfig, filterConfig.filteredKeyword);
        }
    };
    KeywordsReportDataService.prototype.getTableDataPaginated = function (filterConfig, tableType) {
        if (tableType === 'keywords') {
            this.getKeywordsTableData(filterConfig, filterConfig.filteredKeyword);
        }
        else if (tableType === 'topics') {
            this._getTopicsTableData(filterConfig);
        }
    };
    KeywordsReportDataService.prototype.getTableDataSorted = function (filterConfig) {
        this.getKeywordsTableData(filterConfig);
    };
    KeywordsReportDataService.prototype._getKeywordsTableDataUnfiltered = function (filterConfig) {
        var _this = this;
        var body = __assign({}, filterConfig);
        this._http.post(ENV.apiUrl + "/v2/locations/search-keywords", body)
            .subscribe(function (response) {
            _this.keywordsReportDataObject = __assign(__assign({}, _this.keywordsReportDataObject), { keywordsDataSource: response.items, keywordsPagination: {
                    total: response.comparison.keywords.actual || 0,
                    page: response.page,
                    pages: response.pages,
                    per_page: response.per_page,
                    sortBy: response.sortBy,
                    sortOrder: response.sortOrder,
                    hasNext: response.hasNext,
                    hasPrev: response.hasPrev
                } });
            _this._keywordsReportDataSubject.next(_this.keywordsReportDataObject);
        });
    };
    KeywordsReportDataService.prototype._getKeywordsTableDataFiltered = function (filterConfig, keyword) {
        var _this = this;
        // needs optimization
        var body = this.getAtlasBody(filterConfig, keyword);
        this._http.post(ENV.apiUrl + "/v2/search/keyword-search", body)
            .subscribe(function (response) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            console.log(response);
            _this.keywordsReportDataObject = __assign(__assign({}, _this.keywordsReportDataObject), { keywordsDataSource: (_a = response) === null || _a === void 0 ? void 0 : _a.data, keywordsPagination: __assign(__assign({}, _this.keywordsReportDataObject.keywordsPagination), { total: (_b = response) === null || _b === void 0 ? void 0 : _b.total, hasNext: (_c = response) === null || _c === void 0 ? void 0 : _c.hasNext, hasPrev: (_d = response) === null || _d === void 0 ? void 0 : _d.hasPrev, page: (_e = filterConfig) === null || _e === void 0 ? void 0 : _e.page, per_page: (_f = filterConfig) === null || _f === void 0 ? void 0 : _f.size, sortBy: (_h = (_g = filterConfig) === null || _g === void 0 ? void 0 : _g.sort) === null || _h === void 0 ? void 0 : _h.sortBy, sortOrder: (_k = (_j = filterConfig) === null || _j === void 0 ? void 0 : _j.sort) === null || _k === void 0 ? void 0 : _k.sortOrder, pages: Math.ceil(((_l = response) === null || _l === void 0 ? void 0 : _l.total) / ((_m = response) === null || _m === void 0 ? void 0 : _m.per_page)) }) });
            _this._keywordsReportDataSubject.next(_this.keywordsReportDataObject);
        });
    };
    KeywordsReportDataService.prototype.getAtlasBody = function (filterConfig, keyword) {
        var _a, _b;
        return __assign(__assign({}, filterConfig), { location_ids: [(_a = filterConfig) === null || _a === void 0 ? void 0 : _a.locationId], period: ((_b = filterConfig) === null || _b === void 0 ? void 0 : _b.period) || 'prevYear', keyword: keyword });
    };
    KeywordsReportDataService.prototype._getTopicsTableData = function (filterConfig) {
        var _this = this;
        console.log('calling topics', filterConfig);
        var body = __assign({}, filterConfig);
        console.log('body:', body);
        this._http.post(ENV.apiUrl + "/v2/locations/keyword-trends", body)
            .subscribe(function (response) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            _this.keywordsReportDataObject = __assign(__assign({}, _this.keywordsReportDataObject), { topicsDataSource: ((_b = (_a = response) === null || _a === void 0 ? void 0 : _a.keyword_trends) === null || _b === void 0 ? void 0 : _b.items) || [], topicsPagination: {
                    total: ((_d = (_c = response) === null || _c === void 0 ? void 0 : _c.keyword_trends) === null || _d === void 0 ? void 0 : _d.total) || 0,
                    page: (_f = (_e = response) === null || _e === void 0 ? void 0 : _e.keyword_trends) === null || _f === void 0 ? void 0 : _f.page,
                    pages: (_h = (_g = response) === null || _g === void 0 ? void 0 : _g.keyword_trends) === null || _h === void 0 ? void 0 : _h.pages,
                    per_page: (_k = (_j = response) === null || _j === void 0 ? void 0 : _j.keyword_trends) === null || _k === void 0 ? void 0 : _k.per_page,
                    hasNext: (_m = (_l = response) === null || _l === void 0 ? void 0 : _l.keyword_trends) === null || _m === void 0 ? void 0 : _m.hasNext,
                    hasPrev: (_p = (_o = response) === null || _o === void 0 ? void 0 : _o.keyword_trends) === null || _p === void 0 ? void 0 : _p.hasPrev
                } });
            _this._keywordsReportDataSubject.next(_this.keywordsReportDataObject);
        });
    };
    KeywordsReportDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KeywordsReportDataService_Factory() { return new KeywordsReportDataService(i0.ɵɵinject(i1.HttpClient)); }, token: KeywordsReportDataService, providedIn: "root" });
    return KeywordsReportDataService;
}());
export { KeywordsReportDataService };
