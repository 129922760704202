import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ReportsFilterConfigService } from './reports-filter-config.service';
import { KeywordsReportDataService } from './keywords-report-data.service';
import { IReportFilterConfigData, IPaginationData } from './../../../constants/report-filter-config-data';
import { ISelectedDateRange, IPreset, Preset, IDateRange } from './../../../constants/data-picker';
@Injectable({
    providedIn: 'root'
})
export class KeywordsFilterConfigService extends ReportsFilterConfigService {

    public keywordsFilterConfigObject: IReportFilterConfigData = null;

    constructor(
        protected _http: HttpClient,
        private _keyRepDataS: KeywordsReportDataService
    ) {
        super(_http)
    }

    setInitialFilterConfigData(configData: any): void {
        this.keywordsFilterConfigObject = {
            gid: configData.gid,
            accountId: configData.accountId,
            locationId: configData.locationId,
            startDate: this._starterMinDate?.toLocaleDateString('en-CA') || '',
            endDate: this._starterMaxDate?.toLocaleDateString('en-CA') || '',
            sort: {
                sortBy: 'impressions',
                sortOrder: -1
            },
            page: 1,
            size: 25,
            filteredKeyword: ''
        }        
    }

    // calls keywordsReportDataService and prompts the report data retrieval process
    // name pending, this one is not good
    updateFilterConfigAndGetReportData(selectedDates: IDateRange, period: any = {}): void {
        const minDateSelected = selectedDates.minDate.toLocaleDateString('en-CA');
        const maxDateSelected = selectedDates.maxDate.toLocaleDateString('en-CA');
        
        console.log('updateFilterConfig called', period);

        // update the keywordsFilterConfigObject accordingly

        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject, 
            startDate: minDateSelected, 
            endDate: maxDateSelected,
            period: period.comparisonID
        }

        // call the getReportData method
        this._keyRepDataS.getKeywordsResults(this.keywordsFilterConfigObject);
        this._dateComparisonSubject.next(period);
        super.validateComparisonPresets(selectedDates);
    }

    getTableDataFilteredByKeyword(keyword: string): void {
        // when a new keyword filter is requested (or removed), we reset sorting and pagination
        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject,
            sort: {
                sortBy: this.keywordsFilterConfigObject?.period ? `${this.keywordsFilterConfigObject.period}Comparison` : 'impressions',
                sortOrder: -1
            },
            page: 1,
            size: 25,
            filteredKeyword: keyword
        }
        this._keyRepDataS.getKeywordsTableData(this.keywordsFilterConfigObject, keyword);
    }

    getTableDataPaginated(tableType: string, pagination: IPaginationData): void {
        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject,
            page: pagination.page,
            size: pagination.size
        }
        
        this._keyRepDataS.getTableDataPaginated(this.keywordsFilterConfigObject, tableType);
    }
    
    getTableDataSorted(sort: {sortBy: string, sortOrder: string}): void {
        this.keywordsFilterConfigObject = {
            ...this.keywordsFilterConfigObject,
            sort:{
                sortBy: sort.sortBy === 'differencePercentage' ? this.keywordsFilterConfigObject.sort.sortBy : sort.sortBy,
                sortOrder: sort.sortOrder === 'asc' ? 1 : -1
            }
        }
        this._keyRepDataS.getTableDataSorted(this.keywordsFilterConfigObject);
    }
}