import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule, MatProgressBarModule, MatChipsModule, MatButtonModule, MatTooltipModule, MatTableModule, MatMenuModule, MatDividerModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

import { SharedModule } from 'src/app/shared/shared-modules';
import { SkeletonsModule } from './../skeletons/skeletons.module';
import { ReportsFilterComponent } from './reports-filter/reports-filter.component';
import { SearchKeywordsWrapperComponent } from './keywords/search-keywords-wrapper/search-keywords-wrapper.component';
import { SearchKeywordsComponent } from 'src/app/modules/reporting/keywords/search-keywords-wrapper/search-keywords/search-keywords.component';




@NgModule({
  declarations: [
    SearchKeywordsComponent, 
    SearchKeywordsWrapperComponent, 
    ReportsFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatMenuModule,
    MatDividerModule,
    MatSortModule,
    SkeletonsModule,
    DateRangePickerModule,
    SharedModule,
  ],
  entryComponents: [
    SearchKeywordsComponent,
    SearchKeywordsWrapperComponent
  ],
  providers: [
  ],
})
export class ReportingModule { }
