// dep
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router'

// app
import { AuthService } from './auth.service'

/**
 * This guard will enable the route iff:
 *   - There is a persistent authentication session available.
 *   - The session is included in the allowedSessionTypes on the route.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
  
  constructor(
    private _authS : AuthService,
    private _router : Router
  ) { 
  }

  async canActivate(route : ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const auth = await this._authS.waitAuthSession();
    if(auth && route.data.allowedSessionTypes.includes(auth.sessionType)) {
      return true
    }

    const attemptedUrl = this._router.getCurrentNavigation()?.finalUrl?.toString() || window.location.pathname;
    this._authS.redirectUrl = attemptedUrl;

    return this._router.parseUrl('/login')
  }

}
