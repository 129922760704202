<div>
    <div hidden>
        <small>state={{state}} selectedPMId={{selectedPMId}} defaultPMId={{defaultPMId}} 
               inputCompleted={{inputCompleted}} isPayMode={{isPayMode}}
        </small>
    </div>
    <div *ngIf="state === 'LOADING'">
        <!-- TODO: This should be replaced by an skeleton or something like that -->
        <i class="fas fa-spinner fa-pulse"></i>
    </div>

    <div id="stripe-payment-element" [hidden]="state === 'LOADING'">
    <!-- <div id="stripe-payment-element"> -->
        <!--  [hidden]="!showPaymentElement" -->
        <!-- The Stripe Payment Element will be injected here -->
    </div>    
    
    <div *ngIf="state !== 'LOADING'">
        <!-- Buttons on Payment Mode -->
        <ng-container *ngIf="canShowPayButton && isPayMode">
            <!-- Pay button (Saved PM or New PM) -->
            <button (click)="handlePay()" 
                    [disabled]="!payButtonEnabled"
                    mat-flat-button tabIndex="-1" color="accent" class="btn btn--action">
                Pay <i *ngIf="state === 'IN_PROGRESS_PAY'" class="fas fa-spinner fa-pulse"></i>
            </button>
        </ng-container>

        <!-- Buttons on Change Payment Methods Mode -->
        <ng-container *ngIf="!isPayMode">
            <!-- Set as default button / label -->
            <!-- TODO UX: This can also be shown when isPayMode=true, but better not to show it to declutter the
                  on a payment operation? 
            -->
            <button *ngIf="selectedPMId && selectedPMId !== defaultPMId" 
                    (click)="handlePMSetAsDefault()" 
                    [disabled]="state !== 'IDLE'"
                    mat-flat-button color="accent" class="btn btn--action">
                Set for Monthly Payments <i *ngIf="state === 'IN_PROGRESS_SET_DEFAULT'" class="fas fa-spinner fa-pulse"></i>
            </button>
            <span *ngIf="selectedPMId && selectedPMId === defaultPMId" class="pricing-badge compass-badge-blue">
                Used for Monthly Payments
            </span>
            <span *ngIf="!selectedPMId" class="text-muted" class="pricing-badge compass-badge-orange">
                Will be used for Monthly Payments
            </span>

            <!-- Save button (New PM) -->
            <button *ngIf="!selectedPMId"
                    (click)="handlePMSave()" 
                    [disabled]="state !== 'IDLE' || selectedPMId || !inputCompleted"
                    mat-flat-button tabIndex="-1" color="accent" class="btn btn--action">
                Save <i *ngIf="state === 'IN_PROGRESS_SAVE'" class="fas fa-spinner fa-pulse"></i>
            </button>

            <!-- Remove button (Saved PM) -->
            <!-- TODO UX: This can also be shown on isPayMode=true, but better not to show it to declutter the
                  on a payment operation? 
            -->
            <button *ngIf="selectedPMId"
                    (click)="handlePMRemove()" 
                    [disabled]="state !== 'IDLE'"
                    mat-flat-button color="accent" class="btn btn--action">
                Remove <i *ngIf="state === 'IN_PROGRESS_REMOVE'" class="fas fa-spinner fa-pulse"></i>
            </button>


        </ng-container>
    </div>

</div>