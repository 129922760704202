/**
Payment Method Component
 
Used for:
  - Displaying the default Payment Method (already saved)
  - Entering a new Payment Method for future offline/online usages.
  - Changing the default Payment Method.
  - Removing a Payment Method.
  - Making a Synchronous Payment by entering a new Payment Method o reusing a
    saved one. If new, it will be saved and set as the default one.
  - As it uses the Stripe Payment Element, external authorizations
    flows like the one used on like 3D Secure are also managed in-session.

TODO: This component is currently limited to Credit Cards but can be easily
extended to other payment methods when required.

Docs:
  - https://docs.stripe.com/payments/payment-element
  - https://docs.stripe.com/payments/payment-card-element-comparison
  - https://docs.stripe.com/payments/payment-element/design-an-integration
  - https://docs.stripe.com/payments/save-customer-payment-methods
  - https://docs.stripe.com/payments/build-a-two-step-confirmation#save-payment-methods
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#additional-options
  - https://docs.stripe.com/payments/payment-element/migration-ct#conditional-options
  - https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements#web-create-payment-intent
  - https://docs.stripe.com/payments/accept-a-payment-deferred
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=setup
  - https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#additional-options
  - Test
    - https://docs.stripe.com/payments/payment-element/migration?integration-path=future#test-the-integration

Implementation Notes / Design constraints:

- The Stripe Customer will be already created, even when the Maplabs subscription is
  still on the TRIAL stage. No need to create it at this point.

- The only way to make a Payment Element display a saved PM (or more) is to create a
  Customer Session. It doesn't work to create a Setup/Payment Intent that specify a
  `payment_method` and associate it to the Payment Element. It doesn't work either to
  specify the `readOnly=true` Payment Element flag, as that flag is only intended
  to prevent further user interaction with the P.E., probably meant to be set to `true`
  not on P.E. creation but by using a payel.update({readOnly : true}) method triggered by
  some post-creation UI event.

- PaymentIntents and SetupIntents doesn't have a clear expiration date, but is documented
  that they can become invalid with time. In constrast, the Customer Session has a
  clear expiration date for his clientSecret, and there is no need to delete them
  (also, there is no endpoint on Stripe to do that). Note that Payment/Setup Intents
  have an 'id' identity, but Customer Session doesn't, so it's like a singleton with
  a rolling secret.
  
- There is no specific Stripe Element do display the Payment Methods in a readonly
  way without a CustomerSession or Intent, all documentation point that you should
  fetch the payment methods list and use custom components to show them.

- Is possible to use set both the Payment/Setup Intent `clientSecret` and the
  CustomerSession `customerSessionClientSecret` on a Payment Element (docs are
  not clear on this point).

- We can trigger synchronous payments using a saved Payment Method from the backend
  without using a Payment Element, but is preferable to trigger it from the
  Payment Element to handle external authorizations like the 3D Secure flow on
  an in-session way. This forces us to use Customer Session to display the saved
  PM.

- Using a Customer Session, is possible to show a "delete" icon-button on the right
  of every saved PM, but the Payment Element doesn't give us a way to intercept that
  action. So we need a custom button to trigger it ourselves.

- The UI tweaks that can be done on a Payment Element is limited, this is related
  to the fact that the Payment Element is a Stripe-hosted iframe, an
  additional security layer by Stripe.
                                
TODO:
  - This component is not related to the skeleton-payment-method one, that
     component should be removed.
  - The update-card-component should also be removed after is fully replaced
     by this component.
  - Remove the 'as any' casting after the stripe-js typings lib is upgraded.

*/
import { __assign, __awaiter, __extends, __generator } from "tslib";
// dep
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Element as StripeElement, StripeService, Elements as StripeElements } from 'ngx-stripe';
// app
import { BaseComponent } from '../components/base.component';
import { SessionService } from '../services/session.service';
import { PaymentsService } from '../services/payments.service';
import { ModalService } from '../services/modal.service';
import { SnackbarService } from '../services/snackbar.service';
// See 
// - https://docs.stripe.com/payments/payment-element#appearance
// - https://docs.stripe.com/elements/appearance-api#rules
var ELEMENTS_APPEARANCE = { theme: 'stripe',
    variables: {
        spacingUnit: '2px',
    },
};
// See 
//  - https://docs.stripe.com/payments/payment-element#options
//  - https://docs.stripe.com/js/elements_object/create_payment_element#payment_element_create-options
var PAYMENT_ELEMENT_OPTIONS = { layout: 'accordion',
    wallets: { applePay: 'never',
        googlePay: 'never'
    },
    // Default is 'auto', setting it to 'never' could restrict the permitted 
    // payment methods?                                  
    // I we set it to 'auto' then we need to pass the WL company name to the
    // Payment Element to be included in the message.
    terms: { card: 'never' },
};
// TODO: use the lib type after upgrading stripe-js
//type ConfirmationToken = { id : string }
var PaymentMethodComponent = /** @class */ (function (_super) {
    __extends(PaymentMethodComponent, _super);
    function PaymentMethodComponent(_sessionS, _paymentsS, _stripeS, _modalS, _snackbarS) {
        var _this = _super.call(this) || this;
        _this._sessionS = _sessionS;
        _this._paymentsS = _paymentsS;
        _this._stripeS = _stripeS;
        _this._modalS = _modalS;
        _this._snackbarS = _snackbarS;
        // Input properties only used on payment mode
        _this.payAmount = null; /** Fixed int amount in cents */
        _this.payCreatePaymentIntentFn = null;
        _this.payAfterPaymentOkFn = null;
        _this.onErrorFn = null;
        /** Set to false when using an external Pay button */
        _this.canShowPayButton = true;
        _this.isPayMode = false;
        _this.selectedPMId = null; // null if enter a new PM is choosen
        _this.inputCompleted = false;
        _this.payButtonEnabled = false;
        _this._stripeElements = null;
        _this._stripePaymentEl = null;
        _this._paymentDone = false;
        // From network:
        _this.defaultPMId = null;
        _this._customerSession = null;
        // TODO add refreshing state in addition to 'loading?
        _this.state = 'LOADING';
        return _this;
    }
    PaymentMethodComponent.prototype.ngOnInit = function () {
        this._refresh(true);
    };
    PaymentMethodComponent.prototype._refresh = function (firstRun) {
        if (firstRun === void 0) { firstRun = false; }
        return __awaiter(this, void 0, void 0, function () {
            var gid, defaultP_1, _a, stripe, elements, payEl_1, err_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!firstRun && this.state === 'LOADING' || this.isDestroyed)
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        this._setState('LOADING');
                        this.isPayMode = (!!this.payCreatePaymentIntentFn &&
                            (this.payAmount !== null && this.payAmount !== undefined) &&
                            !this._paymentDone);
                        gid = this._sessionS.getSession().gid;
                        defaultP_1 = this._paymentsS.fetchPaymentMethodDefault(gid);
                        if (!!this._customerSession) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this._paymentsS.startCustomerSession(gid)];
                    case 2:
                        _a._customerSession = (_b.sent());
                        _b.label = 3;
                    case 3:
                        // console.log('got customer session', this._customerSession.client_secret)
                        if (this.isDestroyed)
                            return [2 /*return*/];
                        stripe = this._stripeS.getInstance();
                        elements = stripe.elements(__assign(__assign({ 
                            /* Don't setup an initial Setup/PaymentIntent
                               Note both `clientSecret` and `customerSessionClientSecret` can be passed
                               at the same time (not used that way here).
                            */
                            // clientSecret : xx
                            customerSessionClientSecret: this._customerSession.client_secret, loader: 'always' }, (this.isPayMode ? { mode: 'payment',
                            amount: this.payAmount
                        } :
                            { mode: 'setup' })), { currency: 'usd', 
                            /* On payment the payment method would be saved and setup for future usages
                              Notes:
                                - 'off_session' means that it will be saved BOTH for 'on_session'
                                    and 'off_session' future payment flows.
                                - This setting should match the 'setup_future_usage' flag on the
                                    P/S Intent used to confirm.
                                - This setting will make the Payment Element to show a "By providing your card information,
                                  you allow Map Labs, LLC to charge your card for future payments in accordance with their
                                  terms. "message,
                                    - Even if here setupFutureUsage=='on_session' and the CustomerSession
                                      was created with 'payment_method_save'=='disabled').
                                    - If `setupFutureUsage==undefined` then the message is not shown and the PM is
                                      not saved.
                                - By setting `setupFutureUsage` here, the PM will be saved, even if
                                  'payment_method_save'=='disabled' on the CustomerSession.
                    
                            */
                            setupFutureUsage: 'off_session', 
                            // TODO: Is not clear if this is necesary for WL Customers, as we are already creating
                            // the *Intents on the backend using the correct stripe_account_id. 
                            //'onBehalfOf' StripeAccountId
                            // Must match the 'payment_methods' on Payment Intents
                            // if 'automatic_payment_methods' is used on the PI, this should be ommited
                            paymentMethodTypes: ['card'], appearance: ELEMENTS_APPEARANCE }));
                        payEl_1 = elements.create('payment', __assign(__assign({}, PAYMENT_ELEMENT_OPTIONS), { readOnly: true }));
                        if (!this._stripeElements) return [3 /*break*/, 5];
                        // We need to do a destroy()+create+remount to refresh some externally
                        // done changes like removing a PM. 
                        // Not specified on docs, assumes that the 'elements' are also destroyed.
                        return [4 /*yield*/, this._stripePaymentEl.destroy()];
                    case 4:
                        // We need to do a destroy()+create+remount to refresh some externally
                        // done changes like removing a PM. 
                        // Not specified on docs, assumes that the 'elements' are also destroyed.
                        _b.sent();
                        _b.label = 5;
                    case 5:
                        this._stripeElements = elements;
                        this._stripePaymentEl = payEl_1;
                        payEl_1.mount('#stripe-payment-element');
                        // this.state = 'AWAITING_COMPONENT';
                        //------------------------------------------------
                        // 4- Setup Payment Element event hooks
                        //------------------------------------------------
                        // https://docs.stripe.com/js/element/events
                        payEl_1.on('ready', function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            var _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = this;
                                        return [4 /*yield*/, defaultP_1];
                                    case 1:
                                        _a.defaultPMId = ((_b = (_c.sent())) === null || _b === void 0 ? void 0 : _b.id) || null;
                                        if (this.isDestroyed)
                                            return [2 /*return*/];
                                        this._setState('IDLE');
                                        payEl_1.focus(); // TODO: Autofocus? sure?
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        payEl_1.on('change', function (event) {
                            // Enable the submit button only if the form is complete and valid
                            // TODO: Error messages are shown only at field unfocus, change to make
                            // this more clear. 
                            var _a, _b;
                            // no tiene event de delete, solamente cuando se switchea de uno a otro
                            console.log(event);
                            console.log(event.complete, event.error);
                            _this.inputCompleted = event.complete; // && !event.error);
                            _this.selectedPMId = ((_b = (_a = event.value) === null || _a === void 0 ? void 0 : _a.payment_method) === null || _b === void 0 ? void 0 : _b.id) || null;
                            _this._refreshButtons();
                        });
                        payEl_1.on('loaderror', function (event) {
                            (function () {
                                console.error('Payment Element loading Error:', event.error);
                                // Force customerSession refresh
                                _this._customerSession = null;
                                if (!_this.isDestroyed)
                                    _this._refresh();
                            });
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        err_1 = _b.sent();
                        console.error(err_1);
                        this._setState('IDLE');
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    PaymentMethodComponent.prototype._refreshButtons = function () {
        this.payButtonEnabled = (this.isPayMode && this.inputCompleted && this.state == 'IDLE');
    };
    PaymentMethodComponent.prototype.ngOnDestroy = function () {
        if (this._stripePaymentEl) {
            this._stripePaymentEl.destroy();
            this._stripePaymentEl = null;
        }
        _super.prototype.ngOnDestroy.call(this);
    };
    PaymentMethodComponent.prototype.ngOnChanges = function (changes) {
        if (changes['payAmount'] && this._stripePaymentEl) {
            var newAmount = changes['payAmount'].currentValue;
            console.log('payAmount', newAmount);
            // this._stripePaymentEl.update({ amount : changes['payAmount'].currentValue} as any);
            this._stripeElements.update({ amount: newAmount });
        }
    };
    PaymentMethodComponent.prototype._setState = function (state) {
        this.state = state;
        if (this._stripePaymentEl)
            this._stripePaymentEl.update({ readOnly: (state !== 'IDLE') });
        this._refreshButtons();
    };
    PaymentMethodComponent.prototype._showError = function (error) {
        return __awaiter(this, void 0, void 0, function () {
            var msg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msg = error.user_message || error;
                        console.error(msg);
                        if (!this.onErrorFn) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.onErrorFn(msg)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._modalS.openErrorModal('Operation failed', msg)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PaymentMethodComponent.prototype._submitSetupOrPayment = function (isPay) {
        return __awaiter(this, void 0, void 0, function () {
            var gid, mustFinalize, submitError, stripe, _a, tokenError, confirmationToken, intent, paymentMethodId, _b, error, newSetupIntent, newPaymentIntent;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        mustFinalize = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, , 19, 20]);
                        this._setState(isPay ? 'IN_PROGRESS_PAY' : 'IN_PROGRESS_SAVE');
                        return [4 /*yield*/, this._stripeElements.submit()];
                    case 2:
                        submitError = (_c.sent()).error;
                        if (!submitError) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._showError(submitError)];
                    case 3:
                        _c.sent();
                        return [2 /*return*/];
                    case 4:
                        stripe = this._stripeS.getInstance();
                        return [4 /*yield*/, stripe.createConfirmationToken({
                                elements: this._stripeElements,
                            })];
                    case 5:
                        _a = _c.sent(), tokenError = _a.error, confirmationToken = _a.confirmationToken;
                        if (!tokenError) return [3 /*break*/, 7];
                        return [4 /*yield*/, this._showError(tokenError)];
                    case 6:
                        _c.sent();
                        return [2 /*return*/];
                    case 7:
                        // console.log('conf', confirmationToken)
                        if (!confirmationToken) {
                            // Should never happen
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, (isPay ? this.payCreatePaymentIntentFn(confirmationToken.id) :
                                this._paymentsS.createSetupIntent(gid, confirmationToken.id))];
                    case 8:
                        intent = _c.sent();
                        if (!('error' in intent)) return [3 /*break*/, 10];
                        return [4 /*yield*/, this._showError(intent.user_message || intent.error)];
                    case 9:
                        _c.sent();
                        return [2 /*return*/];
                    case 10:
                        paymentMethodId = void 0;
                        if (!(intent.status === 'requires_action')) return [3 /*break*/, 14];
                        return [4 /*yield*/, stripe.handleNextAction({ clientSecret: intent.client_secret })];
                    case 11:
                        _b = _c.sent(), error = _b.error, newSetupIntent = _b.setupIntent, newPaymentIntent = _b.paymentIntent;
                        if (!error) return [3 /*break*/, 13];
                        return [4 /*yield*/, this._showError(error)];
                    case 12:
                        _c.sent();
                        return [2 /*return*/];
                    case 13:
                        paymentMethodId = (newSetupIntent || newPaymentIntent).payment_method;
                        return [3 /*break*/, 15];
                    case 14:
                        paymentMethodId = intent.payment_method;
                        _c.label = 15;
                    case 15:
                        // Succeeded
                        // TODO: Check status for more? 
                        // TODO: Some PM type like an ACH transfer can take a few days to be confirmed? In that
                        // case we need another state and show a "We are processing your payment" message.
                        // Avoid double payments
                        if (isPay)
                            this._paymentDone = true;
                        // Ensure that the new PM or the most recently PM used for saving will be the default one. 
                        return [4 /*yield*/, this._paymentsS.setPaymentMethodDefault(gid, paymentMethodId)];
                    case 16:
                        // Ensure that the new PM or the most recently PM used for saving will be the default one. 
                        _c.sent();
                        if (!this.payAfterPaymentOkFn) return [3 /*break*/, 18];
                        return [4 /*yield*/, this.payAfterPaymentOkFn()];
                    case 17:
                        _c.sent();
                        _c.label = 18;
                    case 18:
                        //changed this as the successful payment has its own success feedback in the modal
                        if (!this.isPayMode)
                            this._snackbarS.openSuccess('Payment Method Saved');
                        // The Payment Element doesn't refresh automatically, do it manually. 
                        mustFinalize = false;
                        this._refresh();
                        return [3 /*break*/, 20];
                    case 19:
                        if (mustFinalize)
                            this._setState('IDLE');
                        return [7 /*endfinally*/];
                    case 20: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Executes a synchronous payment.
     */
    PaymentMethodComponent.prototype.handlePay = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._submitSetupOrPayment(true);
                return [2 /*return*/];
            });
        });
    };
    /**
     * Saves the Payment Method entered in the Payment Element.
     * Validates it and takes any further action like 3D Secure auth.
     */
    PaymentMethodComponent.prototype.handlePMSave = function () {
        this._submitSetupOrPayment(false);
    };
    /**
     * Removes the selected Payment Method.
     */
    PaymentMethodComponent.prototype.handlePMRemove = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, mustRefresh, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._modalS.openConfirmModal('Remove Payment Method', 'Are you sure you want to remove this Payment Method?')];
                    case 1:
                        // savedPM
                        if (!(_a.sent()))
                            return [2 /*return*/];
                        gid = this._sessionS.getSession().gid;
                        mustRefresh = false;
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, 5, 6]);
                        this._setState('IN_PROGRESS_REMOVE');
                        return [4 /*yield*/, this._paymentsS.deletePaymentMethod(gid, this.selectedPMId)];
                    case 3:
                        _a.sent();
                        this._snackbarS.openSuccess('Payment Method Removed');
                        mustRefresh = true;
                        return [3 /*break*/, 6];
                    case 4:
                        err_2 = _a.sent();
                        console.error(err_2);
                        return [3 /*break*/, 6];
                    case 5:
                        if (mustRefresh)
                            this._refresh();
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Sets the selected Payment Method as the default one.
     *
     */
    PaymentMethodComponent.prototype.handlePMSetAsDefault = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this._setState('IN_PROGRESS_SET_DEFAULT');
                        return [4 /*yield*/, this._paymentsS.setPaymentMethodDefault(gid, this.selectedPMId)];
                    case 2:
                        _a.sent();
                        this._snackbarS.openSuccess('Default Payment Method Changed');
                        return [3 /*break*/, 5];
                    case 3:
                        err_3 = _a.sent();
                        console.error(err_3);
                        return [3 /*break*/, 5];
                    case 4:
                        this._refresh();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return PaymentMethodComponent;
}(BaseComponent));
export { PaymentMethodComponent };
