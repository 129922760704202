<app-dialog-template [title]="title" [actionText]="actionText" (apply)="onApply()" [color]="color">

  <gmb-spinner class="spinner" *ngIf="progress"></gmb-spinner>
  <div class="d-flex">
    <div class="profile__img reply-img-owner">
      <img class="mr--10 ml--10" [src]="this.reviewer?.profilePhotoUrl" alt="">
    </div>
    <div class="d-block">
      <p class="m--0 ">
        <strong>{{this.reviewer.displayName}}</strong>
      </p>
      <div class="d-flex flex-row">
        <app-starts [starts]="this.data?.starRatingNumber" class="stars--xs m--0"></app-starts>
        <p class="txt--lightgray fw--light txt--sm mt--5 ml--5">{{this.data.createTime.$date | timeAgo}}</p>
      </div>
      <p class="m--0">
        {{this.data.comment}}
      </p>
    </div>
  </div>


  <div class="d-flex mt--10">
    <div class="profile__img">
      <div class="mr--10 ml--10">
        <mat-progress-spinner mode="indeterminate" diameter="33" *ngIf="!profilePhoto else profile">
        </mat-progress-spinner>
        <ng-template #profile>
          <img class="" [src]="profilePhoto?.googleUrl" alt="">
        </ng-template>
      </div>
    </div>
    <div class="d-block w--100">
      <p class="m--0 d-flex align-items-center justify-content-between">
        {{this.data.company?.title}}
        <span class="alternate-theme" *ngIf="isEdit && activeDelete">
            <button (click)="handleConfirmDelete()" mat-flat-button color="primary" class="btn btn-icon">
              <i class="far fa-trash-alt"></i>
            </button>
        </span>

      </p>

      <div class="d-flex flex-column w--100 mt--15" *ngIf="!confirmDelete">
        <mat-form-field floatLabel="never"
                        class="input-group input-group--override input-group--override-label no-margin">
          <mat-label>Reply</mat-label>
          
          <textarea matInput placeholder="Title" [formControl]="comment" class="input-group__field" [readonly]="readOnly"></textarea>
          <mat-error *ngIf="comment.errors && comment.touched">
            <p *ngIf="comment.errors['required']">Comment to reply is required</p>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</app-dialog-template>
