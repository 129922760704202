<div 
  [matTooltip]="disabled ? disabledTooltip : loading ? loadingTooltip : ''"
  [matTooltipPosition]="'above'"
>

  <button 
    mat-button 
    (click)="fireOutput()" 
    [class]="'compass-button ' + color"
    [disabled]="disabled"
    
  >
    <ng-container *ngIf="loading"><i class="fas fa-spinner fa-pulse mr--10"></i></ng-container>{{ text }}
  </button>
</div>