import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'compass-button',
  templateUrl: './compass-button.component.html',
  styleUrls: ['./compass-button.component.scss']
})
export class CompassButtonComponent {
  // optional customizing parameters
  @Input() text = '';
  @Input() icon = '';
  @Input() color = '';
  @Input() disabled = false;
  @Input() disabledTooltip = '';
  @Input() loading = false;
  @Input() loadingTooltip = '';
  
  @Output() clicked = new EventEmitter();

  constructor() { }

  fireOutput() {
    this.clicked.emit();
  }

}
