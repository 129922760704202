import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { NavigationService } from 'src/app/services/navigation.service';
// app
import { SessionService } from 'src/app/services/session.service';
var ModalTrialEnded = /** @class */ (function () {
    // private _data
    function ModalTrialEnded(dialogRef, data, _sessionS, _navigationS, _router) {
        // this._data = data
        var _a, _b, _c, _d, _e, _f;
        this.dialogRef = dialogRef;
        this.data = data;
        this._sessionS = _sessionS;
        this._navigationS = _navigationS;
        this._router = _router;
        this.darkLaunch = false;
        this.paywallStep = true;
        this.paymentStep = false;
        var _g = this._sessionS.getSession(), features = _g.features, sub = _g.subscription;
        this.darkLaunch = features.userFeatures.updatedPricingModal || false;
        this.locations_qty = sub.locationsQty;
        // FIXME: This doesn't take into account CHARGE_FLAT_RATE, etc. 
        var tiers = (_b = (_a = sub.pricing) === null || _a === void 0 ? void 0 : _a.pkg_bulk_actions) === null || _b === void 0 ? void 0 : _b.tiers;
        for (var i = 0; i < tiers.length; i++) {
            var t = tiers[i];
            if (this.locations_qty >= ((_c = t) === null || _c === void 0 ? void 0 : _c.locationsQtyMin) &&
                this.locations_qty <= ((_d = t) === null || _d === void 0 ? void 0 : _d.locationsQtyMax)) {
                this.totalPrice = ((_e = t) === null || _e === void 0 ? void 0 : _e.price) * this.locations_qty;
                this.unitPrice = (_f = t) === null || _f === void 0 ? void 0 : _f.price;
                return;
            }
        }
    }
    ModalTrialEnded.prototype.accept_cta = function () {
        window.open(this.data.buttons['accept_cta'], "_blank");
    };
    ModalTrialEnded.prototype.moveToPayment = function () {
        this.paywallStep = false;
        this.paymentStep = true;
    };
    ModalTrialEnded.prototype.onClosePlanBilling = function (event) {
        if (event === void 0) { event = false; }
        if (event) {
            this._sessionS.refresh();
            this.dialogRef.close();
            this._router.navigate(['/settings/billing']);
        }
    };
    ModalTrialEnded.prototype.close = function (event) {
        if (event === void 0) { event = false; }
        if (!event) {
            this.dialogRef.close();
            this._navigationS.signOut();
        }
        else { // if event is true, it means the payment went through, so we redirect the user to locations
            this._sessionS.refresh();
            this.dialogRef.close();
            this._router.navigate(['/locations']);
        }
    };
    return ModalTrialEnded;
}());
export { ModalTrialEnded };
