import { __assign, __awaiter, __extends, __generator, __read } from "tslib";
/*
Session service
 
Provides globally available state for an User session
Also some navigation procedures and business logic.
 
Replaces most usages of auth.service.ts, user.service.ts, domain.service.ts,
group.service.ts, etc
Also provides a single point of control and caching for the domain/session info.
 
== Usage from a component ==
 
 class MyComponent ... {
   // for templates
   session$ = this._sessionS.session$
   domain$  = this._sessionS.domain$
 
   constructor(
     private _sessionS : SessionService
   ) {
     // You can be sure domain is already fetched because is always done
     // before any component creation on the APP_INITIALIZER function.
     const domain = this._sessionS.getDomain()
     
     // You can be sure session is already fetched if this is a component
     // created under a parent component that guards the session is already
     // loaded (see app.router.ts).
     const session = this._sessionS.getSession()
    }
  }

Then from the angular template:

   <span>{(domain$ | async).branding.company_name}</span>
   <span>{(session$ | async).user.email}</span>
  
Note that the domain/session is already fetched but '| async' will ensure
that the template will be updated if domain/session changes.
 
== Anti-patterns ==
 
Don't cache session data as it was done before, e.g. on components:
 
// BAD:
 constructor(private _sessionS : SessionService) {
   this._sessionS.session$.subscribe(session => this.subscription = session.subscription)
 }
 someMethod() {
  if(this.subscription ...)
 }
 
// GOOD
  {
  // Get the subscription in the place you are to read it
  const sub = this._sessionS.getSession().subscription
  }
  // On angular templates just use:
  (session$ | async).subscription
 
== Internals ==
  
State machine ($state):
 
   LOADING_DOMAIN
      |  domain$ is fetched
      v
   NOT_LOGGED (domain$ value is available)
      |  user logins
      v
   LOADING_SESSION
      |  session$ is fetched
      v
   LOGGED_IN (domain$ and session$ values are available)
      
Note, if the user logouts the page is then reloaded, so there is no
backwards LOGGED_IN -> NOT_LOGGED transition.
*/
// dep
import { ReplaySubject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import moment from 'moment';
import * as _ from 'lodash';
import { GROUP_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { PackageEnum } from '../settings/constants/pricing';
import { GroupService } from './group.service';
import { SpinnerService } from './spinner.service';
import { UserService } from './user.service';
import { BROWSER_DOMAIN } from '../helpers/utils.helpers';
import { DomainService } from './domain.service';
// import { SubscriptionService } from './subscription.service';
import { asPromisedObservable, makeOpenPromise } from '../helpers/utils.helpers';
import { environment as ENV } from 'src/environments/environment';
import { MAIL_ANONYMOUS } from '../constants/auth';
import { BaseComponent } from '../components/base.component';
import * as i0 from "@angular/core";
import * as i1 from "./group.service";
import * as i2 from "./spinner.service";
import * as i3 from "./user.service";
import * as i4 from "./domain.service";
import * as i5 from "@angular/common/http";
var AUTO_REFRESH_INTERVAL_LAPSE = 120000;
var ANONYMOUS_USER = {
    gid: '',
    uid: '',
    email: MAIL_ANONYMOUS,
    company: '',
    photoURL: '',
    displayName: '',
    timezone: 0
};
var ANONYMOUS_USER_FEATURES = {
    userFeatures: {},
    generalFeatures: {}
};
function makeSession(authSession, group, sub, user, features) {
    var _a;
    //----------------
    // Type conversion
    //----------------
    try {
        var dp = group.dismissModalDatePicker;
        if (!dp) {
            //
        }
        if (dp instanceof moment) {
            group.dismissModalDatePicker = dp.toDate();
        }
        else {
            group.dismissModalDatePicker = new Date(dp);
        }
    }
    catch (err) {
        console.error("Error parsing dismissModalDatePicker gid=" + authSession.gid, err);
    }
    //-----------
    // Inferred
    //-----------
    var bulkActionsEnabled = !!(sub.pricingVersion <= 2 || sub.packages[PackageEnum.BULK_ACTIONS]);
    var isTrial = (sub.status === GROUP_SUBSCRIPTION_TYPE.TRIAL);
    var requiresPaymentMethod = (!isTrial &&
        sub.collectionByBillingOverride[sub.billingOverride.toString()].requiresPaymentMethod);
    var restrictToPaywall = (sub.status === 'BLOCKED');
    var role = (_a = user.role) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    var isAdmin = (role === 'admin' || role === 'master_admin');
    var isMember = (role === 'member');
    var isMasterAdmin = !!user.isMasterAdmin;
    var trialEnd = (sub.trialEnd['$date'] || sub.trialEnd);
    var daysInTrial = Math.round(Math.abs((new Date(trialEnd).getTime() - Date.now()) / (86400 * 1000)));
    // TODO: missing features should be populated with default values
    // TODO: use Object.freeze or _.deepFreeze after checking if it's compatible with angular 
    // internals, also freeze 'domain'.
    return __assign(__assign({}, authSession), { 
        // Fetched:
        group: group, subscription: sub, user: user,
        features: features,
        // Inferred:
        bulkActionsEnabled: bulkActionsEnabled,
        requiresPaymentMethod: requiresPaymentMethod,
        restrictToPaywall: restrictToPaywall,
        isTrial: isTrial,
        isAdmin: isAdmin,
        isMember: isMember,
        isMasterAdmin: isMasterAdmin,
        daysInTrial: daysInTrial });
}
var SessionService = /** @class */ (function (_super) {
    __extends(SessionService, _super);
    function SessionService(
    // app
    _groupS, _spinnerS, _userS, _domainS, 
    // private _subscriptionS : SubscriptionService
    _http) {
        var _this = _super.call(this) || this;
        _this._groupS = _groupS;
        _this._spinnerS = _spinnerS;
        _this._userS = _userS;
        _this._domainS = _domainS;
        _this._http = _http;
        _this._stateIn$ = new ReplaySubject(1);
        _this.state$ = asPromisedObservable(_this._stateIn$);
        // Present both NOT_LOGGED and LOGGED_IN states:
        _this._domainIn$ = new ReplaySubject(1);
        _this.domain$ = asPromisedObservable(_this._domainIn$);
        // Only for LOGGED_IN states:
        // This observable will never complete, as a logout implies a page reload.
        // This way we avoid checking for null session values or using an outer
        // isLoggedIn observable. 
        _this._sessionIn$ = new ReplaySubject(1);
        _this.session$ = asPromisedObservable(_this._sessionIn$);
        // TODO: other app-wide state:
        // All the subscription locations:
        //  accounts
        //   private _locationsIn$ = new ReplaySubject<SavedLocation[]>(1)
        //    locations$ = asPromisedObservable(this._locationsIn$)
        // All users associated with the Subscription (includes the logged-in user)
        // users        : User[]
        // // Current route data:
        // locationId
        // location  // current edited/viewed location for single-location pages.
        _this._refreshStatus = 'IDLE';
        _this._refreshNextP = makeOpenPromise();
        _this._authSession = null;
        _this._stateIn$.next('LOADING_DOMAIN');
        _this._spinnerS.loading$.next(true);
        _this.refresh();
        // TODO: Implement changes push instead of polling or at least
        // improve by scheduling a setTimeout after each refresh() /
        // clearTimeout
        var t = setInterval(function () { _this.refresh(); }, AUTO_REFRESH_INTERVAL_LAPSE);
        _this._addFinalizer(function () { return clearInterval(t); });
        return _this;
    }
    SessionService.prototype.onLogin = function (authSession) {
        var _this = this;
        console.debug("SessionService: onLogin", authSession);
        if (this._authSession) {
            console.error("SessionService: trying to re-login from " + this._authSession + " to " + authSession);
            throw new Error('Session already logged-in');
        }
        this._authSession = authSession;
        this._spinnerS.loading$.next(true);
        this.refresh();
        this._subscribeSafe(this._userS.getUserChangesStream(authSession.gid, authSession.uid), function (_) { return _this.refresh(); });
    };
    /**
     * Refreshes the session data fetching from the backend.
     * Calling this method multiple times will not trigger multiple fetches.
     * Awaiting it ensures that the session data will be freshly fetched.
     */
    SessionService.prototype.refresh = function () {
        return __awaiter(this, void 0, void 0, function () {
            var p;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.state$.getSync() === 'ERROR_LOADING')
                            return [2 /*return*/];
                        if (this._refreshStatus === 'IN_PROGRESS') {
                            this._refreshNextP = makeOpenPromise();
                            this._refreshStatus = 'REFRESH_SCHEDULED';
                        }
                        if (!(this._refreshStatus === 'REFRESH_SCHEDULED')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._refreshNextP];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        p = this._refreshNextP;
                        this._refreshStatus = 'IN_PROGRESS';
                        return [4 /*yield*/, this._fetchAndEmit()];
                    case 3:
                        _a.sent();
                        p.resolve();
                        _a.label = 4;
                    case 4:
                        if (this._refreshStatus === 'REFRESH_SCHEDULED' &&
                            this.state$.getSync() !== 'ERROR_LOADING') return [3 /*break*/, 2];
                        _a.label = 5;
                    case 5:
                        this._refreshStatus = 'IDLE';
                        return [2 /*return*/];
                }
            });
        });
    };
    // Shortcuts, DON'T use on Angular templates, use session$ and domain$ instead
    SessionService.prototype.getState = function () {
        return this.state$.getSync();
    };
    /**
     * @returns a Promise that waits for the Domain until is first loaded, or
     *  returns the Domain immediately if it's already loaded.
     */
    SessionService.prototype.waitDomain = function () {
        return this.domain$.currentValPromise();
    };
    SessionService.prototype.hasDomain = function () {
        return this.domain$.hasValue();
    };
    /**
     * @returns the Domain synchronously if it's already loaded or throws if not.
     */
    SessionService.prototype.getDomain = function () {
        return this.domain$.getSync();
    };
    SessionService.prototype.getSession = function (sessionTypeEnsured) {
        var r = this.session$.getSync();
        if (sessionTypeEnsured && r.sessionType !== sessionTypeEnsured) {
            var m = "SessionService: Expected sessionType=" + sessionTypeEnsured + " but got " + r.sessionType;
            console.error(m);
            throw new Error();
        }
        return r;
    };
    SessionService.prototype.hasSession = function () {
        return this.session$.hasValue();
    };
    /**
     * @returns a Promise that waits for the Session until is first loaded, or
     * returns the Session immediately if it's already loaded.
     */
    SessionService.prototype.waitSession = function () {
        return this.session$.currentValPromise();
    };
    //--------------------------------------------------------------------------
    // Private
    //--------------------------------------------------------------------------
    SessionService.prototype._fetchAndEmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var authSession, emit, toState, domainP, _a, isAnon, userP, featuresP, _b, domain, sub, group, user, features, session, err_1, s, m;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        authSession = this._authSession;
                        emit = function (domain, session) {
                            var d = __assign(__assign({}, domain), BROWSER_DOMAIN);
                            // We want to ensure domain$.hasValue() === true when state$.getSync() === 'NOT_LOGGED'. 
                            // This depends on the synchronously execution of the subscribers when calling next(). 
                            // Rxjs ensures that and the promisedObservable callbacks are also synchronous, so we 
                            // are safe if we call domainIn$.next and then stateIn$.next here.
                            // The same applies for session$.hasValue() == true and state$.getSync() == 'LOGGED_IN'
                            if (!_this.domain$.hasValue() || !_.isEqual(d, _this.domain$.getSync())) {
                                console.debug("SessionService: Setting new domain info", d);
                                _this._domainIn$.next(d);
                            }
                            if (session && (!_this.session$.hasValue() || !_.isEqual(session, _this.session$.getSync()))) {
                                console.debug("SessionService: Setting new session info", session);
                                _this._sessionIn$.next(session);
                            }
                        };
                        toState = function (nextState) {
                            var curState = _this.state$.getSync();
                            if (curState !== nextState) {
                                console.debug("SessionService: " + curState + " -> " + nextState + " transition");
                                _this._stateIn$.next(nextState);
                                if (nextState === 'NOT_LOGGED' || nextState === 'LOGGED_IN' || nextState == 'ERROR_LOADING')
                                    _this._spinnerS.loading$.next(false);
                            }
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        domainP = ((this.domain$.hasValue() && !this.session$.hasValue() && authSession) ?
                            Promise.resolve(this.domain$.getSync()) :
                            this._domainS.fetchCurrentDomain());
                        if (!!authSession) return [3 /*break*/, 3];
                        // Not yet logged-in
                        _a = emit;
                        return [4 /*yield*/, domainP];
                    case 2:
                        // Not yet logged-in
                        _a.apply(void 0, [_c.sent()]);
                        toState('NOT_LOGGED');
                        if (!authSession)
                            // Nothing more to do until onLogin() is called
                            return [2 /*return*/];
                        _c.label = 3;
                    case 3:
                        //----------------------------
                        // Session info (needs login)
                        //----------------------------        
                        if (!this.session$.hasValue())
                            toState('LOADING_SESSION');
                        isAnon = (authSession.sessionType === 'ANONYMOUS');
                        userP = (isAnon ? Promise.resolve(__assign(__assign({}, ANONYMOUS_USER), { uid: authSession.uid, gid: authSession.gid })) :
                            this._userS.fetchUser(authSession.gid, authSession.uid));
                        featuresP = (isAnon ? Promise.resolve(ANONYMOUS_USER_FEATURES) :
                            this._userS.getUserFeature(authSession.uid));
                        return [4 /*yield*/, Promise.all([domainP,
                                this._fetchSubscription(authSession.gid, false),
                                this._groupS.fetchGroup(authSession.gid),
                                userP,
                                featuresP])];
                    case 4:
                        _b = __read.apply(void 0, [_c.sent(), 5]), domain = _b[0], sub = _b[1], group = _b[2], user = _b[3], features = _b[4];
                        session = makeSession(authSession, group, sub, user, features);
                        emit(domain, session);
                        if (this.state$.getSync() === 'LOADING_DOMAIN')
                            // Respect the state machine, don't jump from LOADING_DOMAIN to LOGGED_IN
                            toState('NOT_LOGGED');
                        toState('LOGGED_IN');
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _c.sent();
                        s = this.state$.getSync();
                        m = "SessionService: Error loading domain/session when " + s;
                        if (s === 'LOADING_DOMAIN' || s === 'LOADING_SESSION') {
                            console.error(m, err_1, authSession);
                            toState('ERROR_LOADING');
                        }
                        else {
                            console.debug(m, err_1, authSession);
                            setTimeout(function () { return _this.refresh(); }, 10000);
                        }
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Use subscriptionService.fetchSubscription by solving cyclic dep 
    SessionService.prototype._fetchSubscription = function (gid, products) {
        if (products === void 0) { products = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._http.get(ENV.billingApiUrl + "/subscription/" + gid + "?products=" + (products ? 1 : 0)).toPromise()];
                    case 1: return [2 /*return*/, ((_a.sent())).data];
                }
            });
        });
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.GroupService), i0.ɵɵinject(i2.SpinnerService), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.DomainService), i0.ɵɵinject(i5.HttpClient)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}(BaseComponent));
export { SessionService };
