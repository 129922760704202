/**
Payment Method Component
 
Used for:
  - Displaying the default Payment Method (already saved) 
  - Entering a new Payment Method for future offline/online usages.
  - Changing the default Payment Method.
  - Removing a Payment Method.
  - Making a Synchronous Payment by entering a new Payment Method o reusing a
    saved one. If new, it will be saved and set as the default one. 
  - As it uses the Stripe Payment Element, external authorizations 
    flows like the one used on like 3D Secure are also managed in-session.

TODO: This component is currently limited to Credit Cards but can be easily
extended to other payment methods when required.

Docs:
  - https://docs.stripe.com/payments/payment-element
  - https://docs.stripe.com/payments/payment-card-element-comparison
  - https://docs.stripe.com/payments/payment-element/design-an-integration
  - https://docs.stripe.com/payments/save-customer-payment-methods
  - https://docs.stripe.com/payments/build-a-two-step-confirmation#save-payment-methods
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#additional-options
  - https://docs.stripe.com/payments/payment-element/migration-ct#conditional-options
  - https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements#web-create-payment-intent
  - https://docs.stripe.com/payments/accept-a-payment-deferred
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=setup
  - https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#additional-options
  - Test
    - https://docs.stripe.com/payments/payment-element/migration?integration-path=future#test-the-integration

Implementation Notes / Design constraints:

- The Stripe Customer will be already created, even when the Maplabs subscription is
  still on the TRIAL stage. No need to create it at this point.

- The only way to make a Payment Element display a saved PM (or more) is to create a 
  Customer Session. It doesn't work to create a Setup/Payment Intent that specify a
  `payment_method` and associate it to the Payment Element. It doesn't work either to
  specify the `readOnly=true` Payment Element flag, as that flag is only intended
  to prevent further user interaction with the P.E., probably meant to be set to `true`
  not on P.E. creation but by using a payel.update({readOnly : true}) method triggered by
  some post-creation UI event.

- PaymentIntents and SetupIntents doesn't have a clear expiration date, but is documented
  that they can become invalid with time. In constrast, the Customer Session has a
  clear expiration date for his clientSecret, and there is no need to delete them
  (also, there is no endpoint on Stripe to do that). Note that Payment/Setup Intents
  have an 'id' identity, but Customer Session doesn't, so it's like a singleton with
  a rolling secret.
  
- There is no specific Stripe Element do display the Payment Methods in a readonly
  way without a CustomerSession or Intent, all documentation point that you should 
  fetch the payment methods list and use custom components to show them. 

- Is possible to use set both the Payment/Setup Intent `clientSecret` and the
  CustomerSession `customerSessionClientSecret` on a Payment Element (docs are
  not clear on this point). 

- We can trigger synchronous payments using a saved Payment Method from the backend
  without using a Payment Element, but is preferable to trigger it from the
  Payment Element to handle external authorizations like the 3D Secure flow on
  an in-session way. This forces us to use Customer Session to display the saved
  PM. 

- Using a Customer Session, is possible to show a "delete" icon-button on the right
  of every saved PM, but the Payment Element doesn't give us a way to intercept that
  action. So we need a custom button to trigger it ourselves.

- The UI tweaks that can be done on a Payment Element is limited, this is related
  to the fact that the Payment Element is a Stripe-hosted iframe, an
  additional security layer by Stripe.
                                
TODO:
  - This component is not related to the skeleton-payment-method one, that
     component should be removed.
  - The update-card-component should also be removed after is fully replaced
     by this component.
  - Remove the 'as any' casting after the stripe-js typings lib is upgraded.

*/

// dep
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Element as StripeElement, ElementsOptions, StripeService, Elements as StripeElements, SetupIntent } from 'ngx-stripe'

// app
import { BaseComponent } from '../components/base.component';
import { SessionService } from '../services/session.service';
import { PaymentMethod, PaymentsService } from '../services/payments.service';
import { ModalService } from '../services/modal.service';
import { SnackbarService } from '../services/snackbar.service';

// See 
// - https://docs.stripe.com/payments/payment-element#appearance
// - https://docs.stripe.com/elements/appearance-api#rules
const ELEMENTS_APPEARANCE = { theme : 'stripe', // 'flat'
  variables : {
       spacingUnit: '2px',
  },
// 
//   rules : {
//     '.Tab': {
//         border: '0px solid #E0E6EB',
//         boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
//       },
//   }

 } as const


// See 
//  - https://docs.stripe.com/payments/payment-element#options
//  - https://docs.stripe.com/js/elements_object/create_payment_element#payment_element_create-options
const PAYMENT_ELEMENT_OPTIONS = { layout : 'accordion', // 'tabs', // 'accordion', // 'tabs', // 'accordion', 
                                  wallets : { applePay  : 'never',
                                              googlePay : 'never'
                                            },

                                            // Default is 'auto', setting it to 'never' could restrict the permitted 
                                  // payment methods?                                  
                                  // I we set it to 'auto' then we need to pass the WL company name to the
                                  // Payment Element to be included in the message.
                                  terms : { card : 'never' } ,

                                  ///paymentMethodOrder: ['card'], // Restrict to card payments only
                                } as const


// TODO: use the lib type after upgrading stripe-js
//type ConfirmationToken = { id : string }

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls:  ['./payment-method.component.scss']
})
export class PaymentMethodComponent extends BaseComponent implements OnInit, OnChanges {
  // Input properties only used on payment mode
  @Input() payAmount : number | null = null;  /** Fixed int amount in cents */
  @Input() payCreatePaymentIntentFn : (confirmationTokenId : string) => 
                                        Promise<{client_secret : string, status : string, payment_method : string | null} | 
                                                { error : any, user_message : string }> | null = null;
  @Input() payAfterPaymentOkFn : () => Promise<void> | null = null;
  @Input() onErrorFn : (msg: string) => Promise<void> | null = null;

  /** Set to false when using an external Pay button */
  @Input() canShowPayButton = true;

  public isPayMode = false;
  public selectedPMId : string | null = null; // null if enter a new PM is choosen
  public inputCompleted = false;

  public payButtonEnabled = false; 

  private _stripeElements  : StripeElements | null = null;
  private _stripePaymentEl : StripeElement  | null = null;
  private _paymentDone = false; 

  // From network:
  public defaultPMId : string | null = null;
  private _customerSession : { client_secret : string } | null = null;

  // TODO add refreshing state in addition to 'loading?
  public state : ('LOADING' | 'IDLE' | 'IN_PROGRESS_PAY' | 'IN_PROGRESS_SAVE' | 
                  'IN_PROGRESS_REMOVE' |'IN_PROGRESS_SET_DEFAULT') = 'LOADING'

  constructor(private _sessionS : SessionService,
              private _paymentsS : PaymentsService,
              private _stripeS : StripeService,
              private _modalS : ModalService,
              private _snackbarS : SnackbarService,
  ) {
    super();
   }

  ngOnInit() : void {  
    this._refresh(true);
  }

  private async _refresh(firstRun = false) {
    if(!firstRun && this.state === 'LOADING' || this.isDestroyed)
      return

    try {
      this._setState('LOADING');
      this.isPayMode = (!!this.payCreatePaymentIntentFn && 
                        (this.payAmount !== null && this.payAmount !== undefined) && 
                        !this._paymentDone);

      const {gid} = this._sessionS.getSession();

      // ---------------------------------------------------------------
      // 1- Fetch the required info to create the 'elements' super object
      // ---------------------------------------------------------------

      const defaultP = this._paymentsS.fetchPaymentMethodDefault(gid);

      // console.log('fetching customer session')
      if(!this._customerSession)
        this._customerSession = (await this._paymentsS.startCustomerSession(gid) as any);

      // console.log('got customer session', this._customerSession.client_secret)
      if(this.isDestroyed)
        return

      const stripe = this._stripeS.getInstance();

      // --------------------------------------
      // 2- Create the 'elements' super object
      // --------------------------------------

      // https://docs.stripe.com/js/elements_object/create
      // https://docs.stripe.com/js/elements_object/create_without_intent
      // https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements#web-create-payment-intent
      const elements = stripe.elements({
        /* Don't setup an initial Setup/PaymentIntent 
           Note both `clientSecret` and `customerSessionClientSecret` can be passed 
           at the same time (not used that way here).
        */
        // clientSecret : xx
        customerSessionClientSecret: this._customerSession.client_secret,

        loader: 'always',

        //mode: 'setup', // 'payment', 'setup', 'subscription'
        // mode : 'payment',  // TODO: Subscription        
        // TODO: if the amount changed
        // Note is not possible to set the Payment Intent application_fee_amount and metadata here,
        // also this will not 
        ... (this.isPayMode ? { mode : 'payment', 
                                amount : this.payAmount
                              } : 
                              { mode : 'setup' }),
        
        currency: 'usd',
        
        /* On payment the payment method would be saved and setup for future usages
          Notes:
            - 'off_session' means that it will be saved BOTH for 'on_session' 
                and 'off_session' future payment flows.
            - This setting should match the 'setup_future_usage' flag on the 
                P/S Intent used to confirm.
            - This setting will make the Payment Element to show a "By providing your card information, 
              you allow Map Labs, LLC to charge your card for future payments in accordance with their
              terms. "message, 
                - Even if here setupFutureUsage=='on_session' and the CustomerSession 
                  was created with 'payment_method_save'=='disabled').
                - If `setupFutureUsage==undefined` then the message is not shown and the PM is 
                  not saved.
            - By setting `setupFutureUsage` here, the PM will be saved, even if 
              'payment_method_save'=='disabled' on the CustomerSession.

        */
        setupFutureUsage : 'off_session',

        // TODO: Is not clear if this is necesary for WL Customers, as we are already creating
        // the *Intents on the backend using the correct stripe_account_id. 
        //'onBehalfOf' StripeAccountId

        // Must match the 'payment_methods' on Payment Intents
        // if 'automatic_payment_methods' is used on the PI, this should be ommited
        paymentMethodTypes : ['card'],

        appearance: ELEMENTS_APPEARANCE,

      } as any);  // TODO: remove 'as any' after upgrading stripe-js lib

      //------------------------------------------------
      // 3- Create the Payment Element and (re)mount it
      //------------------------------------------------

      // TODO: remove 'as any' after upgrading stripe-js lib
      const payEl = elements.create('payment' as any, {
          ...PAYMENT_ELEMENT_OPTIONS as any,
          readOnly: true
      });

      if(this._stripeElements) {
        // We need to do a destroy()+create+remount to refresh some externally
        // done changes like removing a PM. 
        // Not specified on docs, assumes that the 'elements' are also destroyed.
        await (this._stripePaymentEl as any).destroy();
      }

      this._stripeElements  = elements;
      this._stripePaymentEl = payEl;

      payEl.mount('#stripe-payment-element');

      // this.state = 'AWAITING_COMPONENT';

      //------------------------------------------------
      // 4- Setup Payment Element event hooks
      //------------------------------------------------
      // https://docs.stripe.com/js/element/events

      payEl.on('ready', async () => {
        this.defaultPMId = (await defaultP)?.id || null;
        if(this.isDestroyed)
          return

        this._setState('IDLE'); 
        payEl.focus();  // TODO: Autofocus? sure?
      });

      payEl.on('change', (event) => {
        // Enable the submit button only if the form is complete and valid
        // TODO: Error messages are shown only at field unfocus, change to make
        // this more clear. 

        // no tiene event de delete, solamente cuando se switchea de uno a otro
        console.log(event)
        console.log(event.complete, event.error)
        this.inputCompleted = event.complete // && !event.error);
        this.selectedPMId   = event.value?.payment_method?.id || null;
        this._refreshButtons();
      });

      payEl.on('loaderror' as any, (event) => {
        () => {
          console.error('Payment Element loading Error:', event.error);
          // Force customerSession refresh
          this._customerSession = null;
          if(!this.isDestroyed)
            this._refresh();
        }
      })

    } catch(err) {
      console.error(err);
      this._setState('IDLE');
    }

  }

  private _refreshButtons() : void {
    this.payButtonEnabled = (this.isPayMode && this.inputCompleted && this.state == 'IDLE')
  }

  ngOnDestroy() : void {
    if(this._stripePaymentEl) {
      (this._stripePaymentEl as any).destroy();
      this._stripePaymentEl = null;
    }
    super.ngOnDestroy();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['payAmount'] && this._stripePaymentEl) {
      const newAmount = changes['payAmount'].currentValue;
      console.log('payAmount', newAmount);      
      // this._stripePaymentEl.update({ amount : changes['payAmount'].currentValue} as any);
      (this._stripeElements as any).update({ amount : newAmount} as any);
    }
  }

  private _setState(state : PaymentMethodComponent['state']) {
    this.state = state;
    if(this._stripePaymentEl) 
      this._stripePaymentEl.update({readOnly : (state !== 'IDLE')} as any);
    this._refreshButtons()
  }


  private async _showError(error : string | { user_message : string }) : Promise<void> {
    const msg = (error as any).user_message || error;
    console.error(msg)
    if (this.onErrorFn) {
      await this.onErrorFn(msg);
    } else {
      await this._modalS.openErrorModal('Operation failed', msg);
    }
 }


  private async _submitSetupOrPayment(isPay : boolean) {
    // https://docs.stripe.com/payments/build-a-two-step-confirmation
    // https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#save-payment-methods
    const {gid} = this._sessionS.getSession();

    let mustFinalize = true; 
    try {
      this._setState(isPay ? 'IN_PROGRESS_PAY' : 'IN_PROGRESS_SAVE')

      // Trigger form validation and wallet collection
      // submitBtn.disabled = true;
      const {error: submitError} = await (this._stripeElements as any).submit();
      if (submitError) {
        await this._showError(submitError);
        return;
      }
 
      // https://docs.stripe.com/payments/payment-element/migration-ct#conditional-options
      const stripe = this._stripeS.getInstance();

      const {error : tokenError, confirmationToken} = await (stripe as any).createConfirmationToken({
        elements : this._stripeElements,
      });

      if(tokenError) {
        await this._showError(tokenError)
        return;
      }

      // console.log('conf', confirmationToken)
      if(!confirmationToken) {
        // Should never happen
        return
      }

      // Note, on payment mode the selectedPM (if any) will be implicit on the Confirmation Token created
      // by the Payment Element

      // Setup: https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=setup#next-actions
      const intent = await (isPay ? this.payCreatePaymentIntentFn(confirmationToken.id) :
                                    this._paymentsS.createSetupIntent(gid, confirmationToken.id));

      if('error' in intent) {
        await this._showError(intent.user_message || intent.error);
        return
      } 

      let paymentMethodId : string | null

      if(intent.status === 'requires_action') {
        // Requires further action / user interaction, managed by stripe-js 
        // https://docs.stripe.com/js/setup_intents/handle_next_action
        const {error, setupIntent   : newSetupIntent, 
                      paymentIntent : newPaymentIntent} = await (stripe as any).handleNextAction({clientSecret : intent.client_secret});
        if(error) {
          await this._showError(error);
          return
        }
        paymentMethodId = (newSetupIntent || newPaymentIntent).payment_method;
      } else {
        paymentMethodId = intent.payment_method;
      }
      // Succeeded

      // TODO: Check status for more? 

      // TODO: Some PM type like an ACH transfer can take a few days to be confirmed? In that
      // case we need another state and show a "We are processing your payment" message.

      // Avoid double payments
      if(isPay)
        this._paymentDone = true;

      // Ensure that the new PM or the most recently PM used for saving will be the default one. 
      await this._paymentsS.setPaymentMethodDefault(gid, paymentMethodId);

      // Usually waiting until the subscription gets off the BLOCKED/TRIAL state. 
      if(this.payAfterPaymentOkFn)
        await this.payAfterPaymentOkFn();

        //changed this as the successful payment has its own success feedback in the modal
      if (!this.isPayMode)
        this._snackbarS.openSuccess('Payment Method Saved');

      // The Payment Element doesn't refresh automatically, do it manually. 
      mustFinalize = false; 
      this._refresh();

    } finally {
      if(mustFinalize)
        this._setState( 'IDLE');
    }

  }


  /**
   * Executes a synchronous payment. 
   */
  public async handlePay() : Promise<void> {
    this._submitSetupOrPayment(true)
  }


  /**
   * Saves the Payment Method entered in the Payment Element.
   * Validates it and takes any further action like 3D Secure auth. 
   */
  public handlePMSave() : void {
    this._submitSetupOrPayment(false)
  }


  /**
   * Removes the selected Payment Method. 
   */
  public async handlePMRemove() : Promise<void> {
    // savedPM
    if(!await this._modalS.openConfirmModal('Remove Payment Method', 'Are you sure you want to remove this Payment Method?'))
      return;

    const {gid} = this._sessionS.getSession();

    let mustRefresh = false; 
    try {
      this._setState('IN_PROGRESS_REMOVE');
      await this._paymentsS.deletePaymentMethod(gid, this.selectedPMId);
      this._snackbarS.openSuccess('Payment Method Removed');
      mustRefresh = true; 
    } catch(err) {
      console.error(err)
    } finally {
      if(mustRefresh)
        this._refresh();
    }
  }


  /**
   * Sets the selected Payment Method as the default one. 
   * 
   */
  public async handlePMSetAsDefault() : Promise<void> {
    const {gid} = this._sessionS.getSession();

    try {
      this._setState('IN_PROGRESS_SET_DEFAULT');
      await this._paymentsS.setPaymentMethodDefault(gid, this.selectedPMId);
      this._snackbarS.openSuccess('Default Payment Method Changed');
    } catch(err) {
      console.error(err)
    } finally {
      this._refresh();
    }

  }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 
//   public async handleSave() {
//     // https://docs.stripe.com/payments/build-a-two-step-confirmation
//     // https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#save-payment-methods
// 
//     // Trigger form validation and wallet collection
//     // submitBtn.disabled = true;
//     const {error: submitError} = (await this._stripeElements as any).submit();
//     if (submitError) {
//       console.error(submitError);
//       // submitBtn.disabled = false;
//       return;
//     }
//     
//    // https://docs.stripe.com/payments/payment-element/migration-ct#conditional-options
//     const stripe = (this._stripeS.getInstance() as any);
// 
//     const {error, confirmationToken} = await stripe.createConfirmationToken({
//       elements : this._stripeElements,
//     });
// 
//     if(error) {
//       console.error(error);
//       return;
//     }
// 
//     // await this._paymentsS.confirmOperation(this._sessionS.getSession().gid, confirmationToken.id);
// 
// 
//     const r = await this._paymentsS.startPaymentIntent2(this._sessionS.getSession().gid);
// 
// 
//     const {error2} = await stripe.confirmPayment({
//       // elements : this._stripeElements,  when using confirmation_token, elements should not be passed
//       clientSecret : r.client_secret,
//       confirmParams: {
//         confirmation_token: confirmationToken.id,
//         //return_url: 'https://example.com/order/123/complete',
//       },
// 
//       redirect : 'if_required',
//       // // Only on Setup, not on PaymentIntent
//       // confirmParams : { payment_method_data : { allow_redisplay : 'always' } }
// 
//      });
// 
//      if(error2) {
//       console.error(error2)
//       return
//      }
// 
//      // ojo con los next actions
// 
// 
//      console.log('payment done');
//      // In order to call fetchUpdates, you must pass a valid PaymentIntent or SetupIntent client secret when creating the Elements group.
//      //await (this._stripeElements as any).fetchUpdates();
// 
// 
//      // colapse pero no registra
//     //  this._stripePaymentEl.collapse()
// 
//   }



  /**
   * Fetche the existing 
   */
//   private async _refreshSavedPaymentMethod() : Promise<void> {
//     try {
//       this.isLoading = true;
//       this.savedPM = null; 
//       const r = await this._paymentsS.fetchPaymentMethodDefault(this._sessionS.getSession().gid);
//       if(r) {
//         const [desc, file] = {'amex'       : ['American Express', 'american-express.jpg'],
//                               'diners'     : ['Diners Club', "diners-club.png"], 
//                               'discover'   : ['Discover', 'discover.png'],
//                               'jcb'        : ['JCB', 'jcb.png'],
//                               'mastercard' : ['MasterCard', 'mastercard.png'],
//                               'unionpay'   : ['UnionPay', 'union-pay.png'],
//                               'visa'       : ['Visa', 'visa.png']}[r.card.brand] || ['Unknown', 'unknown.png'];      
// 
//         this.savedPM = {...r, 
//                         brandLogoPath : `/assets/images/cards/${file}`,
//                         brandDesc     : desc}
//       }    
//     } finally {
//       this.isLoading = false;
//     }
// 
//   }
// 
//   public async handleCreateOrChangePM() : Promise<void> {
//       
//     const {gid} = this._sessionS.getSession();
// 
//     // buttonState active/loading/pressed
//     const stripe = (this._stripeS.getInstance() as any);
// 
//     const initValP = (async () => {
//       const s = await this._paymentsS.startPaymentMethodSetup(gid);
//       const c = await this._paymentsS.startCustomerSession(gid);
// 
//       return {...c,
//               ...s,
//               customer_session : c.client_secret }
//               
//     })();
// 
//     
//     const submitFn = async (elements : StripeElements) => {
//       // https://docs.stripe.com/js/setup_intents/confirm_setup
//       const r = await stripe.confirmSetup({ elements, 
//                                             redirect : 'if_required',
//                                             // Only on Setup, not on PaymentIntent
//                                             confirmParams : { payment_method_data : { allow_redisplay : 'always' } }
//                                           });
//       if(!r.error) {
//           // This should be done in another step, there is no way to flag the SetupIntent
//           // to add the new PM as the default one.
//           await this._paymentsS.setPaymentMethodDefault(gid, r.setupIntent.payment_method);
//       }
// 
//       return r;                                            
//     }
// 
//     const text = this.savedPM ? 'Change Credit Card' : 'Add Credit Card';
// 
//     const r : any = await this._modalS.openModal(PaymentMethodModalComponent, 
//                                                  { initValP,
//                                                    titleText: text,
//                                                    submitButtonText: text,
//                                                    submitFn,
//                                                    readonly : false
//                                                  });
// 
//     if (r.result === 'DONE') {
//       if(!r.submitResult.error) {
//         await this._refreshSavedPaymentMethod();
//         this._snackbarS.openSuccess('Credit Card Saved');
//         return
//       } else {
//         // Card error reported by Stripe, must be shown to the user by spec
//         await this._modalS.openErrorModal('Error adding Credit Card', r.submitResult.error.message);
//       }
//     } else if (r.result !== 'CANCEL') {
//       // Internal error
//       await this._modalS.openErrorModal('Error', 'Error adding Credit Card');
//     }
// 
//     // Background cleanup on errors
//     if(r.initVal) {
//       this._paymentsS.cancelPaymentMethodSetup(gid, r.initVal.id);
//     }
// 
//   }
// 
//   /**
//    * Removes the current Payment Method. 
//    */
//   public async handleRemovePM() : Promise<void> {
//     // savedPM
//     if(!await this._modalS.openConfirmModal('Remove Credit Card', 'Are you sure you want to remove your Credit Card?'))
//       return;
// 
//     let error = true;
//     try {
//       this.isLoading = true
//       await this._paymentsS.deletePaymentMethodDefault(this._sessionS.getSession().gid);
//       error = false;
//     } catch(err) {
//       console.error(err)
//     }
// 
//     await this._refreshSavedPaymentMethod();    
//     if(!error)
//       this._snackbarS.openSuccess('Credit Card Removed');
//   }
// 
//   /**
//    * Executes a synchronous payment. 
//    * 
//    * If there is a Payment Method already saved, we still show the Payment Element modal, as is 
//    * possible that some synchronous authorization is required (e.g. 3D Secure)
//    */
//   public async handlePay() : Promise<void> {
// 
//     const {gid} = this._sessionS.getSession();
// 
//     const stripe = (this._stripeS.getInstance() as any);
// 
//     const initValP = (async () => {
//       
//     const s = await this.createPaymentIntentFn(this.savedPM?.id);
//     const c = await this._paymentsS.startCustomerSession(gid);
// 
//     return {...c,
//             ...s,
//             customer_session : c.client_secret }
//     })();
// 
//     const submitFn = async (elements : StripeElements) => {
//       const r = await stripe.confirmPayment({elements,
//                                              redirect : 'if_required',
//                                              confirmParams : {return_url : "https://www.maplabs.com"}});
// 
//       console.log('submit')
//       console.log(r)
//       if(!r.error && !this.savedPM) {
//           // This should be done in another step, there is no way to flag the PaymentIntent
//           // to add the new PM as the default one.
//           await this._paymentsS.setPaymentMethodDefault(gid, r.paymentIntent.payment_method);
//       }
// 
//       return r;                                            
//     }
// 
//     const r : any = await this._modalS.openModal(PaymentMethodModalComponent, 
//                                                  { initValP,
//                                                    titleText: 'Pay',
//                                                    submitButtonText: 'Pay`',
//                                                    submitFn,
//                                                    readonly: false // !!this.savedPM 
//                                                  });
// 
//     if (r.result === 'DONE') {
//       if(!r.submitResult.error) {
//         if(!this.savedPM)
//           await this._refreshSavedPaymentMethod();
//         this._snackbarS.openSuccess('Payment Done');
//         return
//       } else {
//         // PM error reported by Stripe, must be shown to the user by spec
//         await this._modalS.openErrorModal('Error making the Payment', r.submitResult.error.message);
//       }
//     } else if (r.result !== 'CANCEL') {
//       // Internal error
//       await this._modalS.openErrorModal('Error', 'Error making the Payment');
//     }
// 
//     // Background cleanup on errors
//     if(r.initVal) {
//       this._paymentsS.cancelPaymentIntent(gid, r.initVal.id);
//     }
// 
//   }
// 
    

}

