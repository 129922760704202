import { __assign, __awaiter, __extends, __generator } from "tslib";
// dep
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
// app
import { SessionService } from 'src/app/services/session.service';
import { ManagePlanService } from '../services/manage-plan.service';
import { ModalGetAddOn } from '../../modules/modal-get-addon/modal-get-addon.component';
import { ModalService } from 'src/app/services/modal.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PackageEnum } from '../constants/pricing';
import { BaseComponent } from 'src/app/components/base.component';
import { PaymentMethodComponent } from 'src/app/payment-method/payment-method.component';
import { GROUP_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { PaymentsService } from 'src/app/services/payments.service';
var ManagePlanComponent = /** @class */ (function (_super) {
    __extends(ManagePlanComponent, _super);
    function ManagePlanComponent(_mngPlanS, _sessionS, _modalS, _snackS, _paymentsS, _cdRef) {
        var _this = _super.call(this) || this;
        _this._mngPlanS = _mngPlanS;
        _this._sessionS = _sessionS;
        _this._modalS = _modalS;
        _this._snackS = _snackS;
        _this._paymentsS = _paymentsS;
        _this._cdRef = _cdRef;
        _this.title = 'Manage Add-Ons';
        _this.manageSubscription = false;
        _this.cancelText = 'Cancel';
        _this.confirmText = 'Update Add-Ons';
        _this.onClose = new EventEmitter();
        _this.onClosePlanBilling = new EventEmitter();
        _this.totalAmount = 0;
        _this.showNewPricingFlow = false;
        _this.supportEmail = 'support@maplabs.com'; // <- maybe whitelabels have a different one?
        _this.paymentErrorMsg = '';
        // this flag system should be replaced with something better
        _this.isPaymentInfo = false;
        _this.isProcessingPayment = false;
        _this.paymentError = false;
        _this.paymentSuccess = false;
        _this.isLoading = true;
        /**
         * Ask the backend to create the First Payment, using the packages selected by the user.
         */
        _this.createFirstPayment = function (confirmationTokenId) { return __awaiter(_this, void 0, void 0, function () {
            var gid, selectedPackages;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        selectedPackages = [];
                        // manually push the core package, so in case no add-ons are added, we don't get the "none" error from the backend
                        // selectedPackages.push(PackageEnum.CORE);
                        (_b = (_a = this.pricingObject) === null || _a === void 0 ? void 0 : _a.availableAddons) === null || _b === void 0 ? void 0 : _b.map(function (addon) {
                            switch (addon.name) { // mapping add-on names to PackageEnum values, add more as we include more add-ons
                                case 'pkg_bulk_actions':
                                    if (addon.isActive) {
                                        selectedPackages.push(PackageEnum.BULK_ACTIONS);
                                    }
                                    break;
                            }
                        });
                        this.paymentError = false; // <- flag reset
                        this.isPaymentInfo = false;
                        this.isProcessingPayment = true;
                        this.confirmText = 'Processing';
                        return [4 /*yield*/, this._paymentsS.startFirstPayment(gid, confirmationTokenId, selectedPackages)];
                    case 1: return [2 /*return*/, _c.sent()];
                }
            });
        }); };
        /**
         * Executes if an error exists when attempting the payment
         */
        _this.paymentErrorExists = function (msg) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.paymentErrorMsg = msg;
                this.confirmText = 'Pay Now';
                this.paymentError = true;
                this.isPaymentInfo = true;
                this.isProcessingPayment = false;
                return [2 /*return*/];
            });
        }); };
        /**
         * Polls until the subscription status becomes PAID
         */
        _this.afterPaymentOk = function () { return __awaiter(_this, void 0, void 0, function () {
            var s;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        s = this._sessionS;
                        _a.label = 1;
                    case 1: return [4 /*yield*/, s.refresh()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        if (s.getSession().subscription.status !== GROUP_SUBSCRIPTION_TYPE.PAID) return [3 /*break*/, 1];
                        _a.label = 4;
                    case 4:
                        this._mngPlanS.reloadPricingObject();
                        // Payment sucessfully done at this point.
                        this.isProcessingPayment = false;
                        this.paymentSuccess = true;
                        this.cancelText = 'Go to Plan and Billing';
                        this.confirmText = 'Close';
                        return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    ManagePlanComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var features;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        document.querySelector('body').style.overflow = 'hidden';
                        if (!this.manageSubscription) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._mngPlanS.reloadPricingObject()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this._subscribeSafe(this._mngPlanS.pricingObject$, function (data) {
                            if (data) {
                                _this.pricingObject = __assign({}, data);
                                console.log('pricingObject: ', _this.pricingObject);
                            }
                        });
                        this.isLoading = true;
                        features = this._sessionS.getSession().features;
                        this.renderModal(features.userFeatures.updatedPricingModal || false);
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePlanComponent.prototype.ngDoCheck = function () {
        this.updateTotal();
        this._cdRef.detectChanges();
    };
    ManagePlanComponent.prototype.renderModal = function (show) {
        if (show) {
            this.showNewPricingFlow = true;
            this.isPaymentInfo = true;
        }
        else {
            this.showNewPricingFlow = false;
            this._modalS.openModal(ModalGetAddOn, {
                title: 'Add-On: Bulk Actions and Roll-Up Reports',
                message: '',
                buttons: {
                    'cancel': 'Maybe Later',
                    'accept': 'Get Add-On'
                }
            }, {
                config: {
                    width: '830px'
                }
            });
            this.close();
        }
    };
    ManagePlanComponent.prototype.updateTotal = function () {
        var _a, _b, _c, _d;
        var total = 0;
        (_b = (_a = this.pricingObject) === null || _a === void 0 ? void 0 : _a.availableAddons) === null || _b === void 0 ? void 0 : _b.forEach(function (addon) {
            if (addon.isActive) {
                total += !isNaN(addon.totalPrice) ? addon.totalPrice : 0;
            }
        });
        if (this.manageSubscription) {
            var partial = ((_c = this.pricingObject) === null || _c === void 0 ? void 0 : _c.unitPrice) * ((_d = this.pricingObject) === null || _d === void 0 ? void 0 : _d.totalLocations);
            total += !isNaN(partial) ? partial : 0;
        }
        this.totalAmount = total;
        this._cdRef.detectChanges();
    };
    ManagePlanComponent.prototype.displayAddonsInSummary = function () {
        var _a, _b;
        return this.manageSubscription && ((_b = (_a = this.pricingObject) === null || _a === void 0 ? void 0 : _a.availableAddons.filter(function (a) { return a.isActive; })) === null || _b === void 0 ? void 0 : _b.length) > 0;
    };
    ManagePlanComponent.prototype.next = function () {
        this.manageSubscription ? this.payComponent.handlePay() : this.closeAndUpdateAddons();
    };
    // TODO: refactor once we have more than one addon, to work dynamically
    ManagePlanComponent.prototype.closeAndUpdateAddons = function () {
        var _this = this;
        var _a;
        this._mngPlanS.updateAddons((_a = this.pricingObject) === null || _a === void 0 ? void 0 : _a.availableAddons)
            .subscribe(function (response) {
            if (response.packages.pkg_bulk_actions.isTerminationScheduled) {
                _this._snackS.openSuccess('The add-on Bulk updates has been successfully removed, it will be ' +
                    'available until the end of the current billing cycle.');
            }
            else {
                _this._snackS.openSuccess('The add-on Bulk Updates has been successfully turned on, you can start using it right away.');
            }
            _this.close(true);
        }, function (error) {
            // handle the error and prevent closing the modal
            _this._snackS.openError('There has been an error while trying to update the add-ons status.');
            console.error(error);
        });
    };
    ManagePlanComponent.prototype.close = function (event) {
        if (event === void 0) { event = false; }
        document.querySelector('body').style.overflow = 'auto';
        this.onClose.emit(event);
    };
    ManagePlanComponent.prototype.closePlanBilling = function (event) {
        if (event === void 0) { event = false; }
        if (event && this.paymentSuccess) {
            this.onClosePlanBilling.emit(event);
        }
    };
    return ManagePlanComponent;
}(BaseComponent));
export { ManagePlanComponent };
