export enum ProductEnum {
    ESSENTIAL = 'essential',
    BASIC     = 'basic',
    ULTIMATE  = 'ultimate'
}

export enum PackageEnum {
    CORE         = 'pkg_core',
    BULK_ACTIONS = 'pkg_bulk_actions'
}

export enum ChargeTypeEnum {
    CHARGE_PER_UNIT  = 'CHARGE_PER_UNIT',
    CHARGE_FLAT_RATE = 'CHARGE_FLAT_RATE',
    CHARGE_FREE      = 'CHARGE_FREE'
}

// this interface could be reworked to better handle different addons, as its limited to bulk_actions
// also, pkg_core could be treated as another addon, 
// instead of having it's own properties (like assignedCoreTierNumber, coreTiers...)
export interface IPricingObject {
    nextDueDate?: string;
    unitPrice: number;
    unitDiscount: number;
    availableAddons: IAddon[];
    activeAddons: { 
        names: string[]; 
        values: { 
            [key: string]: { 
                isTerminationScheduled: boolean; 
                startDate?: string;
            }
        }
    };
    totalPrice: number;
    totalLocations: number;
    trialActive: boolean;
    trialDaysTotal: number;
    trialStart: string;
    trialEnd: string;
    trialProgress: number;
    trialLeft: number;
    coreTiers: IPricingTier[];
    assignedCoreTierNumber: number;
    assignedTierDiscount: number;
    billingOverride: boolean;
    invoices: any[];
    nextInvoice: string;
    cards: any[];
}

export interface IAddonsDetails {
    profileNumber: number;
    unitProfilePrice: number;
    totalProfilePrice: number;
    availableAddons: IAddon[];
}
export interface IAddon {
    name: IPackage;
    formattedName?: string;
    assignedTierNumber?: number;
    assignedTierDiscount?: number;
    unitPrice?: number;
    unitDiscount?: number;
    totalPrice?: number;
    tiers?: IPricingTier[];
    isActive?: boolean;
    isTerminationScheduled?: boolean;
    terminationDate?: string;
    description?: string;
    features?: IAddonFeatures[];
}
  
export interface IPricingTier {
    tierNumber: number;
    locationsQtyMin: number;
    locationsQtyMax?: number;
    price: number;
    priceChargeType?: string;
    profileSegment: string;
    isActive: boolean;
    segmentPriceString: string;
}

export interface IAddonFeatures { 
    title: string; 
    description: string;
}

// define new features for subsequent addons here, they are used to create the get add-ons modal

export const pkg_names: Record<IPackage, string> = {
    pkg_core: 'Core package',
    pkg_bulk_actions: 'Bulk Features',
    // ... pkg_apple_whatever...
  };

export const pkg_descriptions: Record<IPackage, string> = {
    pkg_core: 'The basic package, included with your subscription.', // I invented this, we don't really use it but if so, we should change it
    pkg_bulk_actions: 'An essential add-on for business with multiple locations.',
}

export const pkg_features: Record<IPackage, IAddonFeatures[]> = {
    pkg_core: [], // we don't have details for the core package yet
    pkg_bulk_actions: [
        { title: 'Roll-UP Reports', description: 'Aggregate insights and performance data from multiple locations' },
        { title: 'Bulk Updates', description: 'Change hours, URLs, attributes, menus, services, photos, and more in bulk' },
        { title: 'Bulk Posting & Scheduling', description: 'Schedule posts in advance and publish across multiple locations with one click' }
    ]
}
  
  // Products is used for:
  //   pricingVersion == 1 (legacy pricing when translated to new pricing matrix)
  //   pricingVersion == 2
  // Pakages is used for:
  //   pricingVersion == 3
  export type Pricing = Partial<{
    [key in IPackage | IProduct]: IPricingProduct
}>

export interface IPricingProduct {
    tiers: IPricingTier[]
    description: string
}


export type IPackage = 'pkg_core' | 'pkg_bulk_actions';

export type IProduct = 'essential' | 'basic' | 'ultimate';

export function pricing_product_get_matching_tier(p : IPricingProduct, locationQty : number) : IPricingTier | null {
    for (const t of p.tiers)
    if ((t.locationsQtyMin === undefined || locationQty >= t.locationsQtyMin) &&
    (t.locationsQtyMax === undefined || locationQty <= t.locationsQtyMax))
    return t
    return null
}
