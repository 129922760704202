import { __assign } from "tslib";
import { environment } from '@environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { Granularity, Preset } from './../../../constants/data-picker';
/* Definition of abstract service to be implemented by each report service
*
*/
var ReportsFilterConfigService = /** @class */ (function () {
    function ReportsFilterConfigService(_http) {
        this._http = _http;
        // convert into single observable/subject for all date-related stuff
        this._dateRestrictionSubject = new Subject();
        this._datePresetsSubject = new BehaviorSubject([]);
        this._dateComparisonSubject = new BehaviorSubject({});
        this._comparisonPresetsSubject = new BehaviorSubject([]);
        this._dateRangeSubject = new Subject();
        this._reportDataSubject = new Subject();
        this._presets = [];
        this._comparison = [];
    }
    Object.defineProperty(ReportsFilterConfigService.prototype, "dateRestrictionSubject$", {
        get: function () {
            return this._dateRestrictionSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "datePresetsSubject$", {
        get: function () {
            return this._datePresetsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "dateComparisonSubject$", {
        get: function () {
            return this._dateComparisonSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "comparisonPresetsSubject$", {
        get: function () {
            return this._comparisonPresetsSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "dateRangeSubject$", {
        get: function () {
            return this._dateRangeSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportsFilterConfigService.prototype, "keywordsReportSubject$", {
        get: function () {
            return this._reportDataSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ReportsFilterConfigService.prototype.initializeDateRestriction = function (dateRangeLimits, reportType) {
        var newDateRangeLimits = {
            minDate: new Date(dateRangeLimits.minDate),
            maxDate: new Date(dateRangeLimits.maxDate)
        };
        console.log('newDateRangeLimits', newDateRangeLimits);
        // definition of new date variables
        var newMaxDate = null;
        var newMinDate = null;
        // definition of calculation variables
        var today = new Date();
        var todayDay = today.getDate();
        var todayMonth = today.getMonth();
        var currentMaxDate = new Date(newDateRangeLimits.maxDate);
        var currentMaxDateDay = currentMaxDate.getDate();
        var currentMaxDateMonth = currentMaxDate.getMonth();
        var currentMaxDateYear = currentMaxDate.getFullYear();
        var currentMinDate = new Date(newDateRangeLimits.minDate);
        var currentMinDateDay = currentMinDate.getDate();
        var currentMinDateMonth = currentMinDate.getMonth();
        var currentMinDateYear = currentMinDate.getFullYear();
        // first convert currentMaxDate to be the last day of the previous month, if its not already the last day of its month
        if (currentMaxDateDay !== new Date(currentMaxDateYear, currentMaxDateMonth + 1, 0).getDate()) { // not the last day of the month
            newMaxDate = new Date(currentMaxDateYear, currentMaxDateMonth, 0); // last day of the previous month
            console.log('newMaxDate', newMaxDate);
            newDateRangeLimits = __assign(__assign({}, newDateRangeLimits), { maxDate: newMaxDate });
        }
        // if today is between 1 and 6 of the month and currentMaxDateMonth is this month, we substract one month from newMaxDate
        // only applies for keywords and performance reports
        if ((reportType === 'keyword' || reportType === 'performance')
            && todayDay >= 1 && todayDay <= 6
            && ((newDateRangeLimits.maxDate.getMonth() === todayMonth) || (newDateRangeLimits.maxDate.getMonth() === todayMonth - 1))) {
            if (newDateRangeLimits.maxDate.getMonth() === 0) {
                newMaxDate = new Date(newMaxDate.getFullYear() - 1, 11, 31);
            }
            else {
                newMaxDate = new Date(newMaxDate.getFullYear(), newMaxDate.getMonth(), 0); // last day of the previous month
            }
            newDateRangeLimits = __assign(__assign({}, newDateRangeLimits), { maxDate: newMaxDate });
        }
        // we convert the currentMinDate to be the first day of the next month, if its not already the first day of its month
        if (currentMinDateDay !== 1) { // not the first day of the month
            console.log('not the first day of the month');
            if (currentMinDateMonth === 11) { // last month
                newMinDate = new Date(currentMinDateYear + 1, 0, 1);
            }
            else {
                newMinDate = new Date(currentMinDateYear, currentMinDateMonth + 1, 1);
            }
            newDateRangeLimits = __assign(__assign({}, newDateRangeLimits), { minDate: newMinDate });
        }
        // we could end up with newMaxDate or newMinDate being null, if validations were unsuccessfull
        // that is OK, it means there are no valid date ranges
        console.log('newDateRangeLimits after conversions', newDateRangeLimits);
        this._starterMinDate = newDateRangeLimits.minDate;
        this._starterMaxDate = newDateRangeLimits.maxDate;
        this._dateRestrictionSubject.next(newDateRangeLimits);
    };
    ReportsFilterConfigService.prototype.emitDateRestriction = function (dateRestriction) {
        if (dateRestriction) {
            this._dateRestrictionSubject.next(dateRestriction);
        }
    };
    // should fire the re-renderization of associated graphs
    ReportsFilterConfigService.prototype.emitDateRange = function (dateRange) {
        this._dateRangeSubject.next(dateRange);
    };
    ReportsFilterConfigService.prototype.buildGranularity = function (startDate, endDate) {
        var granularity = null;
        // DAY available when selectedRange = 1 month (default on 1 month)
        // WEEK available when selectedRange >= 2 months AND selectedRange <= 12 months (default on 2 - 5 months)
        // MONTH available when selectedRange >= 3 months (default on 6+ months)
        if (startDate.getMonth() === endDate.getMonth()) {
            granularity = Granularity.Day;
        }
        else if (endDate.getMonth() - startDate.getMonth() >= 1 && endDate.getMonth() - startDate.getMonth() <= 2) {
            granularity = Granularity.Day;
        }
        else if (endDate.getMonth() - startDate.getMonth() >= 3) {
            granularity = Granularity.Month;
        }
        return granularity;
    };
    ReportsFilterConfigService.prototype.getDateValidation = function (reportType, accountIds, gids, locationIds) {
        var data = {
            "locationIds": locationIds,
            "accountIds": accountIds,
            "gids": gids,
            "type": reportType
        };
        return this._http.post(environment.apiUrl + "/v2/locations/date-validation", data);
    };
    /**
     * Builds the necessary presets for the datePicker. As of right now it is defined to work with keywords
     * In the future, the instance of this service could define which date presets should be available
     * After validation, it pushes the values through the datePresetsSubject
     */
    ReportsFilterConfigService.prototype.getDatePresets = function () {
        // Previous month
        // Previous 3 months
        // Previous 6 months
        // Previous 9 months
        // Previous 12 months
        // Previous 18 months
        // Year to date
        // Last year
        // All data
        // const currentDate = new Date(2025, 1, 7); // test
        this._presets = [];
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth();
        var currentDay = currentDate.getDate();
        var firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1);
        var offset = currentDay > 6 ? 0 : 1; // we subtract one more month when we are between 1 - 6
        var lastDayOfPreviousValidMonth = new Date(currentYear, currentMonth - offset, 0);
        if (this._starterMinDate instanceof Date && this._starterMaxDate instanceof Date) {
            //test purposes
            // this._starterMinDate = new Date(2024, 0, 1);
            // this._starterMaxDate = new Date(2024, 11, 31);
            var minDate = new Date(this._starterMinDate);
            var maxDate = this._starterMaxDate >= lastDayOfPreviousValidMonth ? new Date(lastDayOfPreviousValidMonth) : new Date(this._starterMaxDate);
            // Previous Month
            var firstDayOfPreviousValidMonth = new Date(maxDate);
            firstDayOfPreviousValidMonth.setDate(1);
            if (minDate <= firstDayOfPreviousValidMonth) {
                this._buildPreset(Preset.LAST_MONTH, 'Previous Month', firstDayOfPreviousValidMonth, maxDate);
            }
            // Previous 3 months
            var threeMonthsAgo = new Date(maxDate);
            threeMonthsAgo.setMonth(maxDate.getMonth() - 2);
            threeMonthsAgo.setDate(1);
            if (minDate <= threeMonthsAgo) {
                this._buildPreset(Preset.LAST_3_MONTHS, 'Previous 3 months', threeMonthsAgo, maxDate);
            }
            // Previous 6 months
            var sixMonthsAgo = new Date(maxDate);
            sixMonthsAgo.setMonth(maxDate.getMonth() - 5);
            sixMonthsAgo.setDate(1);
            if (minDate <= sixMonthsAgo) {
                this._buildPreset(Preset.LAST_6_MONTHS, 'Previous 6 months', sixMonthsAgo, maxDate);
            }
            // Previous 9 months
            var nineMonthsAgo = new Date(maxDate);
            nineMonthsAgo.setMonth(maxDate.getMonth() - 8);
            nineMonthsAgo.setDate(1);
            if (minDate <= nineMonthsAgo) {
                this._buildPreset(Preset.LAST_9_MONTHS, 'Previous 9 months', nineMonthsAgo, maxDate);
            }
            // Previous 12 months
            var twelveMonthsAgo = new Date(maxDate);
            twelveMonthsAgo.setFullYear(maxDate.getFullYear() - 1);
            twelveMonthsAgo.setDate(1);
            if (minDate <= twelveMonthsAgo) {
                this._buildPreset(Preset.LAST_12_MONTHS, 'Previous 12 months', twelveMonthsAgo, maxDate);
            }
            // Previous 18 months
            var eighteenMonthsAgo = new Date(maxDate);
            eighteenMonthsAgo.setFullYear(maxDate.getFullYear() - 1);
            eighteenMonthsAgo.setMonth(maxDate.getMonth() - 5);
            eighteenMonthsAgo.setDate(1);
            if (minDate <= eighteenMonthsAgo) {
                this._buildPreset(Preset.LAST_18_MONTHS, 'Previous 18 months', eighteenMonthsAgo, maxDate);
            }
            // Last year
            var firstDayOfPreviousYear = new Date(maxDate);
            firstDayOfPreviousYear.setFullYear(currentYear - 1);
            firstDayOfPreviousYear.setMonth(0);
            firstDayOfPreviousYear.setDate(1);
            var lastDayOfPreviousYear = new Date(firstDayOfPreviousYear);
            lastDayOfPreviousYear.setMonth(11);
            lastDayOfPreviousYear.setDate(31);
            if (minDate <= firstDayOfPreviousYear && lastDayOfPreviousYear <= maxDate) {
                this._buildPreset(Preset.LAST_YEAR, 'Last year', firstDayOfPreviousYear, lastDayOfPreviousYear);
            }
            // YTD: we ensure that maxDate is in this year
            var maxYear = maxDate.getFullYear();
            if (currentYear === maxYear) {
                var correctMinDate = minDate > firstDayOfCurrentYear ? minDate : firstDayOfCurrentYear;
                this._buildPreset(Preset.YEAR_TO_DATE, 'Year to Date', correctMinDate, maxDate);
            }
            // All data
            this._buildPreset(Preset.ALL_TIME, 'All data', minDate, maxDate);
        }
        this._datePresetsSubject.next(this._presets);
    };
    ReportsFilterConfigService.prototype._buildPreset = function (presetID, name, minDate, maxDate) {
        if (minDate > maxDate) {
            return;
        }
        console.log("Adding preset: " + name + " | Min: " + minDate + " | Max: " + maxDate);
        this._presets.push({
            presetID: presetID,
            name: name,
            dateRange: { maxDate: maxDate, minDate: minDate }
        });
    };
    ReportsFilterConfigService.prototype.validateComparisonPresets = function (dates) {
        this._comparison = [];
        var isEnabled = false;
        // Group headers
        var compareCurrentRangeTo = [];
        var quickComparison = [];
        // "None" option
        this._comparison.push({
            header: null,
            items: [{ comparisonID: null, name: 'None', enabled: true }]
        });
        //  QUIZAS CONVIENE ESTO GUARDARLO EN UNA VARIABLE ASI NO LO REPETIMOS //
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth();
        var currentDay = currentDate.getDate();
        var offset = currentDay > 6 ? 0 : 1;
        var lastDayOfPreviousValidMonth = new Date(currentYear, currentMonth - offset, 0);
        var minDate = new Date(this._starterMinDate);
        var maxDate = this._starterMaxDate >= lastDayOfPreviousValidMonth ? new Date(lastDayOfPreviousValidMonth) : new Date(this._starterMaxDate);
        var startDatepicker = dates.minDate;
        var endDatepicker = dates.maxDate;
        // Previous period
        var diff = endDatepicker.getTime() - startDatepicker.getTime();
        var previousStartDate = new Date(startDatepicker.getTime() - diff);
        var previousEndDate = new Date(endDatepicker.getTime() - diff);
        var isPreviousStartDateInRange = previousStartDate >= minDate && previousStartDate <= maxDate;
        var isPreviousEndDateInRange = previousEndDate >= minDate && previousEndDate <= maxDate;
        isEnabled = isPreviousStartDateInRange && isPreviousEndDateInRange;
        this._comparison[0].items.push({ comparisonID: Preset.PREVIOUS_PERIOD, name: 'Previous Period', enabled: isEnabled });
        // this.buildComparisonPresets('Previous Period', isEnabled, Preset.PREVIOUS_PERIOD, compareCurrentRangeTo);
        // Previous month
        var previousMonthStartDate = new Date(startDatepicker.getFullYear(), startDatepicker.getMonth() - 1, startDatepicker.getDate());
        var previousMonthEndDate = new Date(endDatepicker.getFullYear(), endDatepicker.getMonth() - 1, endDatepicker.getDate());
        var isPreviousMonthStartDateInRange = previousMonthStartDate >= minDate && previousMonthStartDate <= maxDate;
        var isPreviousMonthEndDateInRange = previousMonthEndDate >= minDate && previousMonthEndDate <= maxDate;
        isEnabled = isPreviousMonthStartDateInRange && isPreviousMonthEndDateInRange;
        this._comparison[0].items.push({ comparisonID: Preset.MONTH_TO_MONTH, name: 'Previous Month', enabled: isEnabled });
        // this.buildComparisonPresets('Month to month', isEnabled, Preset.MONTH_TO_MONTH, quickComparison);
        // Previous year
        var previousYearStartDate = new Date(startDatepicker.getFullYear() - 1, startDatepicker.getMonth(), startDatepicker.getDate());
        var previousYearEndDate = new Date(endDatepicker.getFullYear() - 1, endDatepicker.getMonth(), endDatepicker.getDate());
        var isPreviousYearStartDateInRange = previousYearStartDate >= minDate && previousYearStartDate <= maxDate;
        var isPreviousYearEndDateInRange = previousYearEndDate >= minDate && previousYearEndDate <= maxDate;
        isEnabled = isPreviousYearStartDateInRange && isPreviousYearEndDateInRange;
        this._comparison[0].items.push({ comparisonID: Preset.YEAR_TO_YEAR, name: 'Previous Year', enabled: isEnabled });
        // this.buildComparisonPresets('Year to year', isEnabled, Preset.YEAR_TO_YEAR, quickComparison);
        // Add grouped data to _comparison
        // this._comparison.push({ header: 'Compare current range to', items: compareCurrentRangeTo });
        // this._comparison.push({ header: 'Quick Comparison', items: quickComparison });
        this._comparisonPresetsSubject.next(this._comparison);
    };
    ReportsFilterConfigService.prototype.buildComparisonPresets = function (name, enabled, comparisonID, group) {
        group.push({
            comparisonID: comparisonID,
            name: name,
            enabled: enabled
        });
    };
    return ReportsFilterConfigService;
}());
export { ReportsFilterConfigService };
