import { __awaiter, __extends, __generator, __values } from "tslib";
// dep
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';
var FlexSearch = require('flexsearch');
import { LocationService } from '../../services/location.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { ProtocolService } from '../../services/protocol.service';
import { environment as ENV } from '@environment';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var ToggleLocationsInAccountComponent = /** @class */ (function (_super) {
    __extends(ToggleLocationsInAccountComponent, _super);
    function ToggleLocationsInAccountComponent(_locationS, _protocolService, _sessionS, _groupS, _http, _router, _cdRef) {
        var _this = _super.call(this) || this;
        _this._locationS = _locationS;
        _this._protocolService = _protocolService;
        _this._sessionS = _sessionS;
        _this._groupS = _groupS;
        _this._http = _http;
        _this._router = _router;
        _this._cdRef = _cdRef;
        _this._isEdit = false;
        _this.loadingSearch = false;
        // public accountsFilter: any; // TODO: Unused, remove.
        _this.noAccounts = false;
        _this.flexSearch = new FlexSearch({
            encode: "advanced",
            tokenize: "reverse",
            suggest: true,
            cache: true,
            doc: {
                id: 'id',
                field: [
                    'accountName',
                    'locationName',
                    'locName',
                    'address',
                    'serviceArea',
                    'reportName',
                    'labels'
                ]
            }
        });
        _this.accounts = [];
        _this.isFilter = false;
        _this._listEventSelect = [];
        _this.hasInfoNotification = false;
        // TimeOut search
        _this.timeout = null;
        _this.session$ = _this._sessionS.session$;
        _this.selectByUser = false;
        _this.checkUpgrade = false;
        _this.isPost = false;
        // @Input() safeNumberLocations = true;
        _this.filterProtocolLocations = false;
        _this.isSliderOpened = false;
        _this.isSlider = false;
        _this.txtInfoNotification = null;
        _this.hasGoToLocation = false;
        _this.selected = new EventEmitter();
        return _this;
    }
    ToggleLocationsInAccountComponent.prototype._disableAccounts = function (accounts) {
        return accounts.map(function (account) {
            account.checked = false;
            return account;
        });
    };
    ToggleLocationsInAccountComponent.prototype.ngOnInit = function () {
        this.search = null;
        if (this.reportEdit && this.reportEdit.length > 0) {
            this._listEventSelect = JSON.parse(JSON.stringify(this.reportEdit));
        }
        this._setInfoNotification();
        this._fetchAccounts();
    };
    ToggleLocationsInAccountComponent.prototype.ngOnChanges = function () {
        var _a;
        if (this.isSlider) {
            if (!this.isSliderOpened) {
                this._removeAllChecked();
                this.ngOnDestroy();
                (_a = this.accordion) === null || _a === void 0 ? void 0 : _a.closeAll();
            }
            else {
                this.ngOnInit();
            }
        }
    };
    ToggleLocationsInAccountComponent.prototype._removeAllChecked = function () {
        var _this = this;
        var _a;
        (_a = this._listEventSelect) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
            var _a, _b, _c;
            var account = _this.accounts.find(function (a) { return a.accountId === el.accountId; });
            if ((_a = account) === null || _a === void 0 ? void 0 : _a.selectAll) {
                _this.changeAccountSelected(account);
            }
            else {
                (_c = (_b = account) === null || _b === void 0 ? void 0 : _b.locations) === null || _c === void 0 ? void 0 : _c.forEach(function (l) {
                    var _a;
                    if ((_a = l) === null || _a === void 0 ? void 0 : _a.checked) {
                        _this.changeLocationsSelected(l, account);
                    }
                });
            }
        });
    };
    ToggleLocationsInAccountComponent.prototype._fetchAccounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var gid, accounts, accounts;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        if (!this.filterProtocolLocations) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._protocolService.getAccountsProtocols()];
                    case 1:
                        accounts = _a.sent();
                        if (accounts.length) {
                            this.accounts = this._accountsCopy = this._disableAccounts(accounts);
                        }
                        else {
                            this.noAccounts = true;
                        }
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._groupS.get_accounts(gid)];
                    case 3:
                        accounts = _a.sent();
                        if (accounts) {
                            this.accounts = this._accountsCopy = this._disableAccounts(accounts);
                        }
                        _a.label = 4;
                    case 4:
                        if (this.reportEdit) {
                            this.reportEdit.forEach(function (el) { return __awaiter(_this, void 0, void 0, function () {
                                var accountIndex;
                                return __generator(this, function (_a) {
                                    accountIndex = this.accounts.findIndex(function (acc) { return acc.accountId === el.accountId; });
                                    this.accounts[accountIndex].locations = el.locations;
                                    return [2 /*return*/];
                                });
                            }); });
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit);
                            });
                        }
                        this._onChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Move to location.service.ts:getLocationsIdsByStringQuery
    ToggleLocationsInAccountComponent.prototype._getAccountsLocations = function (query) {
        var gid = this._sessionS.getSession().gid;
        return this._http.post((ENV.apiUrl + "/v2/search/gid/" + gid + "/account-locations" +
            (this.filterProtocolLocations ? '/protocols' : '')), { query: query }).toPromise();
    };
    ToggleLocationsInAccountComponent.prototype.changeAccountSelected = function (a) {
        var _this = this;
        if (a.checked && a.selectAll) {
            a.checked = false;
            a.selectAll = false;
            a.locations.forEach(function (l) {
                l.checked = false;
                _this._setChange(l, a);
                _this._removeToList(l, a);
            });
        }
        else if (!a.selectAll) {
            a.selectAll = true;
            a.checked = true;
            a.locations.forEach(function (l) {
                l.checked = !l.deny;
                _this._setChange(l, a);
                if (!l.deny) {
                    _this._AddToList(l, a);
                }
            });
        }
        this._onChanges();
    };
    ToggleLocationsInAccountComponent.prototype._removeToList = function (l, a) {
        var Search_Account = this._listEventSelect.find(function (r) { return r.accountId === a.accountId; });
        if (Search_Account) {
            var search_locations = Search_Account.locations.find(function (loc) { return loc.locationId === l.locationId; });
            if (search_locations) {
                var locationsFiltered_1 = Search_Account.locations.filter(function (loc) { return loc.locationId !== l.locationId; });
                if (locationsFiltered_1.length === 0) {
                    this._listEventSelect = this._listEventSelect.filter(function (acc) { return acc.accountId !== a.accountId; });
                }
                else {
                    this._listEventSelect = this._listEventSelect.map(function (acc) {
                        if (acc.accountId === a.accountId) {
                            acc.locations = locationsFiltered_1;
                        }
                        return acc;
                    });
                }
            }
        }
    };
    ToggleLocationsInAccountComponent.prototype._AddToList = function (l, a) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (this._listEventSelect.length === 0) {
            var element = {
                accountId: a.accountId,
                locationId: l.locationId,
                accountName: a.accountName,
                locationName: l.locationName
            };
            if (l.location) {
                if (l.location.labels)
                    element['labels'] = l.location.labels;
                if (l.location.address)
                    element['address'] = l.location.address;
                if (l.location.serviceArea)
                    element['serviceArea'] = l.location.serviceArea;
            }
            else if ((_a = l) === null || _a === void 0 ? void 0 : _a.labels) {
                element['labels'] = l.labels;
                if ((_b = l) === null || _b === void 0 ? void 0 : _b.address)
                    element['address'] = l.address;
            }
            else if ((_c = l) === null || _c === void 0 ? void 0 : _c.address) {
                element['address'] = l.address;
            }
            else if ((_d = l) === null || _d === void 0 ? void 0 : _d.serviceArea) {
                element['serviceArea'] = l.serviceArea;
            }
            this._listEventSelect.push({
                accountId: a.accountId,
                gid: a.gid,
                locations: [element]
            });
        }
        else {
            var Search_Account = this._listEventSelect.find(function (r) { return r.accountId === a.accountId; });
            if (Search_Account) {
                var search_locations = Search_Account.locations.find(function (loc) { return loc.locationId === l.locationId; });
                if (search_locations) {
                    var locationsFilter_1 = Search_Account.locations.filter(function (loc) { return loc.locationId !== l.locationId; });
                    if (locationsFilter_1.length === 0 && !l.checked) {
                        if ((_e = a) === null || _e === void 0 ? void 0 : _e.selectAll)
                            a.selectAll = false;
                        this._listEventSelect = this._listEventSelect.filter(function (acc) { return acc.accountId !== a.accountId; });
                    }
                    else {
                        this._listEventSelect = this._listEventSelect.map(function (acc) {
                            if (acc.accountId === a.accountId && !l.checked) {
                                acc.locations = locationsFilter_1;
                            }
                            return acc;
                        });
                    }
                }
                else {
                    this._listEventSelect = this._listEventSelect.map(function (acc) {
                        var _a, _b, _c, _d;
                        if (acc.accountId === a.accountId) {
                            var element = {
                                accountId: a.accountId,
                                locationId: l.locationId,
                                accountName: a.accountName,
                                locationName: l.locationName
                            };
                            if (l.location) {
                                if (l.location.labels)
                                    element['labels'] = l.location.labels;
                                if (l.location.address)
                                    element['address'] = l.location.address;
                                if (l.location.serviceArea)
                                    element['serviceArea'] = l.location.serviceArea;
                            }
                            else if ((_a = l) === null || _a === void 0 ? void 0 : _a.labels) {
                                element['labels'] = l.labels;
                                if ((_b = l) === null || _b === void 0 ? void 0 : _b.address)
                                    element['address'] = l.address;
                            }
                            else if ((_c = l) === null || _c === void 0 ? void 0 : _c.address) {
                                element['address'] = l.address;
                            }
                            else if ((_d = l) === null || _d === void 0 ? void 0 : _d.serviceArea) {
                                element['serviceArea'] = l.serviceArea;
                            }
                            acc.locations.push(element);
                        }
                        return acc;
                    });
                }
            }
            else {
                var element = {
                    accountId: a.accountId,
                    locationId: l.locationId,
                    accountName: a.accountName,
                    locationName: l.locationName
                };
                if (l.location) {
                    if (l.location.labels)
                        element['labels'] = l.location.labels;
                    if (l.location.address)
                        element['address'] = l.location.address;
                    if (l.location.serviceArea)
                        element['serviceArea'] = l.location.serviceArea;
                }
                else if ((_f = l) === null || _f === void 0 ? void 0 : _f.labels) {
                    element['labels'] = l.labels;
                    if ((_g = l) === null || _g === void 0 ? void 0 : _g.address)
                        element['address'] = l.address;
                }
                else if ((_h = l) === null || _h === void 0 ? void 0 : _h.serviceArea) {
                    element['serviceArea'] = l.serviceArea;
                }
                this._listEventSelect.push({
                    accountId: a.accountId,
                    gid: a.gid,
                    locations: [element]
                });
            }
        }
        var locationChecked = (_j = a.locations) === null || _j === void 0 ? void 0 : _j.filter(function (l) { return l.checked; });
        var possibleLocations = (_k = a.locations) === null || _k === void 0 ? void 0 : _k.filter(function (l) { return !l.deny; });
        a.selectAll = locationChecked.length === possibleLocations.length;
        this._listEventSelect.forEach(function (loc) {
            if ('locationsBasics' in loc) {
                delete loc['locationsBasics'];
            }
        });
    };
    ToggleLocationsInAccountComponent.prototype.changeLocationsSelected = function (loc, acc) {
        loc.checked = !loc.checked;
        var accountLocationsActive = acc.locations.filter(function (l) { return l.checked; });
        acc.checked = accountLocationsActive.length !== 0;
        this._setChange(loc, acc);
        this._AddToList(loc, acc);
        this._onChanges();
    };
    ToggleLocationsInAccountComponent.prototype._onChanges = function () {
        this._setInfoNotification();
        this.selected.emit(this._listEventSelect);
        this._cdRef.detectChanges();
    };
    ToggleLocationsInAccountComponent.prototype._setChange = function (l, a) {
        var account = _.find(this._accountsCopy, { 'accountId': a.accountId });
        var location = _.find(account.locations, { 'locationId': l.locationId });
        location.checked = l.checked;
        // if toggle uncheck and it depends on reporEdit, delete it.
        if (l.checked === false && this.search) {
            var accountReportEdit = _.find(this.reportEdit, { 'accountId': a.accountId });
            if (accountReportEdit) {
                var locationIndex = _.findIndex(accountReportEdit.locations, { 'locationId': l.locationId });
                if (locationIndex !== -1) {
                    accountReportEdit.locations.splice(locationIndex, 1);
                }
            }
        }
    };
    ToggleLocationsInAccountComponent.prototype._isActiveAccount = function (account, report, isFromFilterSearch) {
        var _this = this;
        if (isFromFilterSearch === void 0) { isFromFilterSearch = false; }
        var isTrial = this._sessionS.getSession().isTrial;
        Promise.all(report.map(function (activeAccount) {
            var _a;
            if ((activeAccount.gid + activeAccount.accountId) === (account.gid + account.accountId)) {
                account.selectAll = false;
                account.checked = true;
                (_a = account.locations) === null || _a === void 0 ? void 0 : _a.forEach(function (location) {
                    var locationId = location.locationId;
                    activeAccount.locations.forEach(function (l) {
                        if (locationId === l.locationId) {
                            location.checked = true;
                        }
                    });
                    if ((!isTrial && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) ||
                        (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !_this.selectByUser)) {
                        location.deny = true;
                    }
                });
            }
        }));
        if (!isFromFilterSearch) {
            this._accountsCopy = this.accounts;
        }
    };
    ToggleLocationsInAccountComponent.prototype.filterLocation = function ($event, key) {
        var _this = this;
        if ($event[$event.length - 1] === ' ') {
            this._onChanges();
            return;
        }
        clearTimeout(this.timeout);
        this.loadingSearch = true;
        this.timeout = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, text, result, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(!$event || $event === '')) return [3 /*break*/, 2];
                        this.isFilter = false;
                        _a = this;
                        return [4 /*yield*/, this._checkSelected(this._accountsCopy, this.accounts)];
                    case 1:
                        _a.accounts = _c.sent();
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit);
                            });
                        }
                        this.loadingSearch = false;
                        return [3 /*break*/, 5];
                    case 2:
                        text = $event.toLowerCase();
                        return [4 /*yield*/, this._getAccountsLocations(text)];
                    case 3:
                        result = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this._checkSelected(result.accounts, this._accountsCopy)];
                    case 4:
                        _b.accounts = _c.sent();
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit, true);
                            });
                        }
                        this.isFilter = true;
                        this.loadingSearch = false;
                        _c.label = 5;
                    case 5:
                        this._onChanges();
                        return [2 /*return*/];
                }
            });
        }); }, 350);
        this._onChanges();
    };
    ToggleLocationsInAccountComponent.prototype._checkSelected = function (source, otherSource) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var gid, _loop_1, this_1, source_1, source_1_1, acc, e_1_1;
            var e_1, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        gid = this._sessionS.getSession().gid;
                        _loop_1 = function (acc) {
                            var selectAcc, _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        selectAcc = _.find(otherSource, { 'accountId': acc.accountId });
                                        if (!((_a = selectAcc) === null || _a === void 0 ? void 0 : _a.locations)) return [3 /*break*/, 1];
                                        selectAcc.locations.forEach(function (l) {
                                            var _a;
                                            var currentloc = _.find(acc.locations, { 'locationId': l.locationId });
                                            if (currentloc) {
                                                currentloc.checked = l.checked || false;
                                                currentloc.deny = (l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE);
                                                currentloc.subscriptionType = l.subscriptionType;
                                                currentloc.serviceArea = ((_a = l.location) === null || _a === void 0 ? void 0 : _a.serviceArea) || l.serviceArea;
                                            }
                                        });
                                        return [3 /*break*/, 3];
                                    case 1:
                                        if (!(selectAcc && !selectAcc.locations && acc.locations)) return [3 /*break*/, 3];
                                        _a = selectAcc;
                                        return [4 /*yield*/, this_1._locationS.fetchAccountLocations(gid, selectAcc.accountId)];
                                    case 2:
                                        _a.locations = _b.sent();
                                        selectAcc.locations.forEach(function (l) {
                                            var _a, _b;
                                            var currentloc = _.find(acc.locations, { 'locationId': l.locationId });
                                            if (currentloc) {
                                                currentloc.checked = l.checked || false;
                                                currentloc.deny = (l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE);
                                                currentloc.subscriptionType = l.subscriptionType;
                                                currentloc.serviceArea = ((_a = l.location) === null || _a === void 0 ? void 0 : _a.serviceArea) || ((_b = l) === null || _b === void 0 ? void 0 : _b.serviceArea);
                                            }
                                        });
                                        _b.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, 7, 8]);
                        source_1 = __values(source), source_1_1 = source_1.next();
                        _c.label = 2;
                    case 2:
                        if (!!source_1_1.done) return [3 /*break*/, 5];
                        acc = source_1_1.value;
                        return [5 /*yield**/, _loop_1(acc)];
                    case 3:
                        _c.sent();
                        _c.label = 4;
                    case 4:
                        source_1_1 = source_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _c.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (source_1_1 && !source_1_1.done && (_b = source_1.return)) _b.call(source_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, source];
                }
            });
        });
    };
    ToggleLocationsInAccountComponent.prototype._setInfoNotification = function () {
        if (!this.txtInfoNotification) {
            return;
        }
        var totalLocations = 0;
        this._listEventSelect.forEach(function (account) {
            totalLocations += account.locations.length;
        });
        this.hasInfoNotification = (totalLocations < 2);
    };
    ToggleLocationsInAccountComponent.prototype.ngOnDestroy = function () {
        this.accounts = this._disableAccounts(this.accounts); // TODO: probably superflous, remove.
        _super.prototype.ngOnDestroy.call(this);
    };
    ToggleLocationsInAccountComponent.prototype.openAccount = function (account) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, user, gid, isMember, _b, locations, memberLocations_1, memberLocation_1, prevAccounts, _c, _d, _e, _f, _g, _h;
            var _this = this;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _a = this._sessionS.getSession(), user = _a.user, gid = _a.gid, isMember = _a.isMember;
                        if (this.accounts.length === 1 && this._isEdit) {
                            this._onChanges();
                            return [2 /*return*/];
                        }
                        if (!!_.has(account, 'locations')) return [3 /*break*/, 5];
                        if (!this.filterProtocolLocations) return [3 /*break*/, 2];
                        _b = account;
                        return [4 /*yield*/, this._protocolService.getLocationsProtocols(account.accountId)];
                    case 1:
                        _b.locations = _j.sent();
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit);
                            });
                            this._setInfoNotification();
                            this.selected.emit(this._listEventSelect);
                            this._isEdit = true;
                        }
                        account['loadAllLocations'] = true;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this._groupS.get_locations(gid, account.accountId)];
                    case 3:
                        locations = _j.sent();
                        if (isMember) {
                            memberLocations_1 = [];
                            user.accounts.forEach(function (account) {
                                account.locations.forEach(function (location) {
                                    memberLocations_1.push(location.locationId);
                                });
                            });
                            memberLocation_1 = [];
                            locations.forEach(function (location) {
                                if (memberLocations_1.includes(location.locationId)) {
                                    memberLocation_1.push(location);
                                }
                            });
                            account.locations = memberLocation_1;
                        }
                        else {
                            account.locations = locations;
                        }
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit);
                            });
                            this._setInfoNotification();
                            this.selected.emit(this._listEventSelect);
                            this._isEdit = true;
                        }
                        account['loadAllLocations'] = true;
                        _j.label = 4;
                    case 4: return [3 /*break*/, 11];
                    case 5:
                        if (!(!_.get(account, 'loadAllLocations') && !this.isFilter)) return [3 /*break*/, 11];
                        prevAccounts = _.cloneDeep(this._accountsCopy);
                        account.locations = null;
                        if (!this.filterProtocolLocations) return [3 /*break*/, 8];
                        _c = account;
                        return [4 /*yield*/, this._protocolService.getLocationsProtocols(account.accountId)];
                    case 6:
                        _c.locations = _j.sent();
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit);
                            });
                            this._setInfoNotification();
                            this.selected.emit(this._listEventSelect);
                            this._isEdit = true;
                        }
                        account['loadAllLocations'] = true;
                        _e = (_d = _).head;
                        return [4 /*yield*/, this._checkSelected([account], prevAccounts)];
                    case 7:
                        account = _e.apply(_d, [_j.sent()]);
                        return [3 /*break*/, 11];
                    case 8:
                        if (_.has(account, 'locations'))
                            account.locations = null;
                        _f = account;
                        return [4 /*yield*/, this._groupS.get_locations(gid, account.accountId)];
                    case 9:
                        _f.locations = _j.sent();
                        if (this.reportEdit && this.reportEdit.length > 0) {
                            this.accounts.forEach(function (account) {
                                _this._isActiveAccount(account, _this.reportEdit);
                            });
                            this._setInfoNotification();
                            this.selected.emit(this._listEventSelect);
                            this._isEdit = true;
                        }
                        account['loadAllLocations'] = true;
                        _h = (_g = _).head;
                        return [4 /*yield*/, this._checkSelected([account], prevAccounts)];
                    case 10:
                        account = _h.apply(_g, [_j.sent()]);
                        _j.label = 11;
                    case 11:
                        if (this.checkUpgrade) {
                            account.locations = account.locations.filter(function (loc) { return (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC); });
                        }
                        this._onChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Unused, remove
    // changeIconToggle(e) : void {
    //   const icon = document.getElementById(`Icon${e}`);
    //   if (icon.classList.contains('fa-plus')) {
    //     icon.classList.remove('fa-plus');
    //     icon.classList.add('fa-minus');
    //   } else {
    //     icon.classList.remove('fa-minus');
    //     icon.classList.add('fa-plus');
    //   }
    // }
    ToggleLocationsInAccountComponent.prototype.goToLocation = function (account, location) {
        var _a, _b;
        this._router.navigate(['/account', (_a = account) === null || _a === void 0 ? void 0 : _a.accountId, 'location', (_b = location) === null || _b === void 0 ? void 0 : _b.locationId, 'post-management']);
    };
    ToggleLocationsInAccountComponent.prototype.detectToggle = function () {
        console.log('detected!');
        this._onChanges();
    };
    return ToggleLocationsInAccountComponent;
}(BaseComponent));
export { ToggleLocationsInAccountComponent };
