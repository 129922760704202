import { __awaiter, __extends, __generator } from "tslib";
// dep
import { OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { FormBuilder, Validators } from '@angular/forms';
import { StripeService } from 'ngx-stripe';
// app
import { PaymentsService } from '../../../services/payments.service';
import { ModalService } from "../../../services/modal.service";
import { SnackbarService } from '../../../services/snackbar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
// TODO: Rename to AddCardComponent, this component shows a form to add a new Credit Card, 
// NOT to update an existing one. Misleading name.
// Note this uses the deprecated Stripe "Card" Element
// Also see MAP-2234
// TODO: Remove after being full replaced by the new PaymentMethodComponent
var ELEMENT_OPTS = {
    locale: 'en'
};
var ELEMENT_STYLE = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#8c91a2',
            lineHeight: '1.2',
            fontWeight: 300,
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            fontSize: '16px',
            '::placeholder': { color: '#8c91a2' },
        },
        invalid: {
            iconColor: '#e85746',
            color: '#e85746',
        }
    },
    // @ts-ignore
    classes: {
        focus: 'is-focused',
        empty: 'is-empty',
    },
};
var UpdateCardComponent = /** @class */ (function (_super) {
    __extends(UpdateCardComponent, _super);
    function UpdateCardComponent(data, dialogRef, _formBuilder, _stripeS, _paymentS, _snackS, _modalS, _sessionS, navigationS) {
        var _this = _super.call(this) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this._formBuilder = _formBuilder;
        _this._stripeS = _stripeS;
        _this._paymentS = _paymentS;
        _this._snackS = _snackS;
        _this._modalS = _modalS;
        _this._sessionS = _sessionS;
        _this.navigationS = navigationS;
        _this.loading = false;
        return _this;
    }
    UpdateCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formCard = this._formBuilder.group({ name: ['', Validators.required] });
        this._subscribeSafe(this._stripeS.elements(ELEMENT_OPTS), function (elements) {
            if (_this._stripeCardElement)
                // should never happen
                return;
            _this._stripeCardElement = elements.create('card', ELEMENT_STYLE);
            _this._stripeCardElement.mount('#card-element');
            document.querySelectorAll('input.field').forEach(function (input) {
                input.addEventListener('focus', function () { input.classList.add('is-focused'); });
                input.addEventListener('blur', function () { input.classList.remove('is-focused'); });
                input.addEventListener('keyup', function () {
                    if (!input.value.length) {
                        input.classList.add('is-empty');
                    }
                    else {
                        input.classList.remove('is-empty');
                    }
                });
            });
            _this._stripeCardElement.on('change', function (event) {
                var successElement = document.querySelector('.success');
                var errorElement = document.querySelector('.error');
                successElement.classList.remove('visible');
                errorElement.classList.remove('visible');
                if (event.token) {
                    successElement.querySelector('.token').textContent = event.token.id;
                    successElement.classList.add('visible');
                }
                else if (event.error) {
                    errorElement.textContent = event.error.message;
                    errorElement.classList.add('visible');
                }
            });
        });
    };
    /**
     * "Save" button handler
     */
    UpdateCardComponent.prototype.handleSaveCardButton = function () {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var token_id, name_1, res, e_1, user_msg;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 3, 4, 5]);
                        this.loading = true;
                        name_1 = this.formCard.get('name').value;
                        return [4 /*yield*/, this._stripeS.createToken(this._stripeCardElement, { name: name_1 }).toPromise()];
                    case 1:
                        res = _f.sent();
                        if (res.error)
                            throw new Error((_a = res.error) === null || _a === void 0 ? void 0 : _a.message);
                        // "token.id" has the pattern "tok_XXXXX"
                        token_id = res.token.id;
                        // 2- Pass the token to the backend
                        // It will call https://docs.stripe.com/api/cards/create
                        // Stripe will do more card validation on that endpoint.
                        return [4 /*yield*/, this._paymentS.addNewCard(this._sessionS.getSession().gid, token_id)];
                    case 2:
                        // 2- Pass the token to the backend
                        // It will call https://docs.stripe.com/api/cards/create
                        // Stripe will do more card validation on that endpoint.
                        _f.sent();
                        this._snackS.openSuccess('New card added succesfully');
                        this.dialogRef.close(true);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _f.sent();
                        user_msg = (!token_id && ((_b = e_1) === null || _b === void 0 ? void 0 : _b.message)) ? e_1.message : (_e = (_d = (_c = e_1) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.detail) === null || _e === void 0 ? void 0 : _e.user_message;
                        if (token_id && !user_msg)
                            // Log backend non-Stripe error to Datadog
                            console.error("Error adding new Card (backend)", e_1);
                        else
                            console.log("Error adding new Card", e_1);
                        // Show the error we get from Stripe or a fixed one if it's a backend non-Stripe error
                        this._modalS.openErrorModal('Credit Card Failed', user_msg || 'Please try with a different credit card');
                        this.dialogRef.close(false);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return UpdateCardComponent;
}(BaseComponent));
export { UpdateCardComponent };
