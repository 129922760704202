import { __awaiter, __generator } from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/services/navigation.service';
import { ManagePlanService } from '../services/manage-plan.service';
import { ModalService } from 'src/app/services/modal.service';
var PlanComponent = /** @class */ (function () {
    function PlanComponent(_mngPlanS, _navigationS, _modalS) {
        this._mngPlanS = _mngPlanS;
        this._navigationS = _navigationS;
        this._modalS = _modalS;
        this.firstLoad = true;
        this.isPricingVisible = true;
        this.isLoading = true;
        this.showAddonsModal = false;
        this.modalTitle = '';
        this.daysLeftInTrial = 0;
        this.trialProgress = 0;
        this.brandYellow = '#FBBC05';
        this.listColumns = ['number', 'date', 'status', 'amount', 'actions'];
        this._ngUnsubscribe$ = new Subject();
    }
    PlanComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.firstLoad = false;
        this._mngPlanS.isLoading$
            .pipe(takeUntil(this._ngUnsubscribe$))
            .subscribe(function (value) { return _this.isLoading = value; });
        this._mngPlanS.pricingObject$
            .pipe(takeUntil(this._ngUnsubscribe$))
            .subscribe(function (data) {
            if (data) {
                _this.pricingObject = data;
                _this.isPricingVisible = !_this.pricingObject.billingOverride;
            }
        });
        this._mngPlanS.loadPlan();
    };
    PlanComponent.prototype.onAddAddons = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.modalTitle = 'Manage Add-Ons';
                this.showAddonsModal = true;
                return [2 /*return*/];
            });
        });
    };
    PlanComponent.prototype.onAddCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._mngPlanS.onAddCard();
                return [2 /*return*/];
            });
        });
    };
    PlanComponent.prototype.handleDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._mngPlanS.handleDefaultSource(card);
                return [2 /*return*/];
            });
        });
    };
    PlanComponent.prototype.applyDefaultSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._mngPlanS.applyDefaultSource(card);
                return [2 /*return*/];
            });
        });
    };
    PlanComponent.prototype.handleDeleteSource = function (card) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._mngPlanS.handleDeleteSource(card);
                return [2 /*return*/];
            });
        });
    };
    PlanComponent.prototype.updatePlanStatus = function (event) {
        this.showAddonsModal = false;
        this._mngPlanS.reloadPricingObject();
    };
    PlanComponent.prototype.navigatePricingWeb = function () {
        this._navigationS.goToPricingWeb();
    };
    PlanComponent.prototype.navigateProfServicesWeb = function () {
        this._navigationS.goToProfessionalServicesWeb();
    };
    PlanComponent.prototype.onDownloadInvoicePDF = function (element) {
        this._mngPlanS.onDownloadInvoicePDF(element);
    };
    PlanComponent.prototype.ngOnDestroy = function () {
        this._ngUnsubscribe$.next();
        this._ngUnsubscribe$.complete();
    };
    return PlanComponent;
}());
export { PlanComponent };
