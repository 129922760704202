// dep
import { Injectable } from '@angular/core'

// app
import { ModalTrialEnded } from '../modules/modal-trial-ended/modal-trial-ended.component'
import { ModalService } from './modal.service'
import { ISession, SessionService } from './session.service';
import { ISubscription } from '../constants/subscription';
import { BaseComponent } from '../components/base.component'

const BLOCK_REASON_MESSAGES : {[reason in ISubscription['statusReason']] : 
 { title : string, messageV2 : string, messageV3: string}} = {
  'TRIAL_EXPIRED'   : { title     : 'Your Free Trial Has Ended',
                        messageV2 : ('Schedule a call with us to upgrade your account and make the best local search '+
                                     'investment for your business; 100 TIMES MORE users than other local search channels!'),
                        messageV3 : ('Reach out to our sales team to upgrade your account and make the best local search '+
                                     'investment for your business; 100 TIMES MORE users than other local search channels!')},

  'UNPAID_TOO_LONG' : { title     : 'Your Subscription is Unpaid',
                        messageV2 : 'Schedule a call with us to assist with your payments to continue using Map Labs.',
                        messageV3 : 'Reach out to our sales team to assist with your payments and continue using Map Labs.' },
                          
  'MANUAL_BLOCK'    : { title     : 'Your Subscription has been Blocked ',
                        messageV2 : 'Schedule a call with us to unblock your subscription and continue using Map Labs.',
                        messageV3 : 'Reach out to our sales team to unblock your subscription and continue using Map Labs.'}
} as const;

// Only used on transition, TODO: Remove after MAP-2504 backend is deployed
const BLOCK_REASON_MESSAGES_DEFAULT = 'MANUAL_BLOCK';


@Injectable({
  providedIn: 'root'
})
export class PaywallService extends BaseComponent {
  
  constructor(
    private _modalService: ModalService,
    private _sessionS : SessionService,
  ) {
    super();

    let paywallIsOpen = false;
    this._subscribeSafe(this._sessionS.session$, s => {
        if(s.restrictToPaywall && !paywallIsOpen) {
          paywallIsOpen = true;
          this._openPaywallModal(s)
        }
    });

  }

  private _blockReason(): typeof BLOCK_REASON_MESSAGES['MANUAL_BLOCK'] {
    return BLOCK_REASON_MESSAGES[this._sessionS.getSession().subscription.statusReason || BLOCK_REASON_MESSAGES_DEFAULT];
  }

  private _openAndRestrictToModal(buttons: any, message: string): void {
    this._modalService.openModal(ModalTrialEnded,
      {
        title: this._blockReason().title,
        message: message,
        buttons: buttons
      }, 
      { 
        config: { 
          width: '750px',
          // Restrict navigation, the user cannot navigate out of the Paywall modal
          disableClose: true 
        }
      }
    )
  }


  /**
   * Returns false if the user is not authenticated
   * Waits until the session is loaded by SessionService.
   */
  /*
  async canActivate(): Promise<boolean> {
    return true;

    const auth = await this._authS.waitAuthSession();
    if(!auth)
      // No persistent session found. 
      return false;

    // if(auth.sessionType === 'EXTERNAL_GRADER')
    //   // External Grader is only allowed to navigate External Component
    //   return false;

    // Ensure the session is loaded before continuing loading
    // the route, so components can get the session 
    // synchronously. 
    // Other router guards must still use waitSession(), as they will be
    // executed in parallel with this. 
    await this._sessionS.waitSession();

    return true;
  }
  */


  private _openPaywallModal(session : ISession) : boolean {
    let message
    let buttons
    switch (session.subscription.pricingVersion) {
      case 1:
      case 2: {
        message = this._blockReason().messageV2,
        buttons = {
          'accept_icon': 'far fa-calendar',
          'accept_text': 'Schedule Call',
          'accept_cta': 'https://calendly.com/maplabs/discovery-call',
          'cancel_text': 'Logout',
        }
        break
      }
      case 3: {
        message = this._blockReason().messageV3,
        buttons = {
          'accept_icon': 'fas fa-paper-plane',
          'accept_text': 'Contact Sales',
          'accept_cta': 'mailto:sales@maplabs.com',
          'cancel_text': 'Logout',
        }
        break
      }
    }
    this._openAndRestrictToModal(buttons, message)
    return true
  }
}
