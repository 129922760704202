import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss']
})
export class DialogTemplateComponent implements OnInit {
  @Output() apply = new EventEmitter();

  @Input() title: string;
  @Input() actionText: string;
  @Input() color: 'primary' | 'accent' | 'warn';

  ngOnInit() {
    if (!this.color) {
      this.color = 'accent'
    }
  }

  action() {
    this.apply.emit();
  }
}
