import { __values } from "tslib";
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Note, the VSCode angular extensions here marks the following error:
// "Class is using Angular features but is not decorated. Please add an explicit 
// Angular decorator.(-992007)". But that should be done only on later
// Angular versions. (on later versions use a simple @Directive() decorator)
/**
 * Base class to manage finalizers.
 */
var BaseComponent = /** @class */ (function () {
    function BaseComponent() {
        this._finalizers = [];
        this._destroy$ = new Subject();
        this.isDestroyed = false;
    }
    BaseComponent.prototype._addFinalizer = function (f) {
        this._finalizers.push(f);
    };
    /**
     * Subscribes to an observable, the subscription is automatically
     * unsubscribed when the component is destroyed.
     */
    BaseComponent.prototype._subscribeSafe = function (o, next, error, complete) {
        return o.pipe(takeUntil(this._destroy$)).subscribe(next, error, complete);
    };
    /**
     * Run all finalizers
     * If derived classes override ngOnDestroy(), then super.ngOnDestroy() must
     * be called at the end of their ngOnDestroy() implementation.
     */
    BaseComponent.prototype.ngOnDestroy = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this._finalizers.reverse()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var f = _c.value;
                try {
                    if (f instanceof Subscription) {
                        f.unsubscribe();
                    }
                    else {
                        f();
                    }
                }
                catch (e) {
                    console.error("Error running finalizer", e);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this._finalizers = [];
        this._destroy$.next();
        this._destroy$.complete();
        this.isDestroyed = true;
    };
    return BaseComponent;
}());
export { BaseComponent };
