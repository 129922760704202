import { __assign } from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LocationEditService } from '../../services/location-edit.service';
import { REGEX_COMPLEX_URL } from '../../constants/regex';
var ModalAttributeUrlComponent = /** @class */ (function () {
    function ModalAttributeUrlComponent(config, locationEditS, dialog) {
        this.config = config;
        this.locationEditS = locationEditS;
        this.dialog = dialog;
        this.bulk = false;
        this.attributesUrl = null;
        this.validResult = new EventEmitter(false);
    }
    ModalAttributeUrlComponent.prototype.ngOnInit = function () {
        var _a, _b;
        if (this.config) {
            this.attribute = (_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attribute;
            this.initForm();
            this.setForm();
        }
        else {
            this.initForm();
        }
    };
    ModalAttributeUrlComponent.prototype.initForm = function () {
        this.form = new FormGroup({
            urls: new FormArray([])
        });
    };
    ModalAttributeUrlComponent.prototype.setForm = function () {
        var _this = this;
        var _a, _b;
        (_b = (_a = this.attribute) === null || _a === void 0 ? void 0 : _a.urlValues) === null || _b === void 0 ? void 0 : _b.forEach(function (el) {
            _this.addNewUrl(el.url);
        });
    };
    ModalAttributeUrlComponent.prototype.addNewUrl = function (url) {
        if (url === void 0) { url = ''; }
        this.UrlsArray.push(new FormGroup({
            url: new FormControl(url, [Validators.pattern(REGEX_COMPLEX_URL)])
        }));
    };
    ModalAttributeUrlComponent.prototype.removeUrl = function (index) {
        this.UrlsArray.removeAt(index);
    };
    ModalAttributeUrlComponent.prototype.apply = function () {
        var _this = this;
        this.buildUrl();
        if (this.form.valid) {
            var data = this.buildData();
            var index = this.locationEditS.locationEdit.attributes.findIndex(function (attribute) { return attribute.attributeId == _this.attribute.attributeId; });
            var attr = this.locationEditS.locationEdit.attributes[index];
            if (data.length > 0) {
                if (index != -1) {
                    if (Object.keys(attr).includes('uriValues') && !Object.keys(attr).includes('urlValues')) {
                        attr.urlValues = attr.uriValues;
                        attr.urlValues.forEach(function (el) {
                            el.url = el.uri;
                            delete el.uri;
                        });
                    }
                    attr.urlValues = data;
                }
                else {
                    this.locationEditS.locationEdit.attributes.push({
                        attributeId: this.attribute.attributeId,
                        valueType: this.attribute.valueType,
                        urlValues: data
                    });
                }
            }
            else {
                this.locationEditS.locationEdit.attributes.splice(index, 1);
            }
            this.loading = true;
            this.locationEditS.setAndUpdate().toPromise().then(function () {
                _this.loading = false;
                _this.dialog.close();
            });
        }
    };
    ModalAttributeUrlComponent.prototype.buildUrl = function () {
        this.UrlsArray.controls.forEach(function (c) {
            var _a;
            var url = (_a = c.value) === null || _a === void 0 ? void 0 : _a.url;
            var hasHttp = url.substr(0, 7) === 'http://' ? true : false;
            var hasHttps = url.substr(0, 8) === 'https://' ? true : false;
            if (!hasHttp && !hasHttps) {
                c['controls']['url'].setValue("http://" + url);
            }
        });
    };
    ModalAttributeUrlComponent.prototype.buildData = function () {
        var data = [];
        this.UrlsArray.controls.forEach(function (el) {
            var _a, _b;
            if (((_a = el.value) === null || _a === void 0 ? void 0 : _a.url) != '') {
                el.value.url = (_b = el.value.url) === null || _b === void 0 ? void 0 : _b.replace(/ /g, '%20');
                data.push(el.value);
            }
        });
        return data;
    };
    ModalAttributeUrlComponent.prototype.getResult = function () {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            this.validResult.emit(false);
            return;
        }
        var data = this.buildData();
        if (this.attribute) {
            if ('active' in this.attribute)
                delete this.attribute['active'];
            if ('displayName' in this.attribute)
                delete this.attribute['displayName'];
            if ('groupDisplayName' in this.attribute)
                delete this.attribute['groupDisplayName'];
            if ('isRepeatable' in this.attribute)
                delete this.attribute['isRepeatable'];
            if ('isDeprecated' in this.attribute)
                delete this.attribute['isDeprecated'];
        }
        this.validResult.emit(true);
        return { attributes: [__assign(__assign({}, this.attribute), { urlValues: data })] };
    };
    Object.defineProperty(ModalAttributeUrlComponent.prototype, "UrlsArray", {
        get: function () {
            return this.form.get('urls');
        },
        enumerable: true,
        configurable: true
    });
    ModalAttributeUrlComponent.prototype.getUrlArray = function (index) {
        return this.form.get('urls').at(index);
    };
    ModalAttributeUrlComponent.prototype.addBtnShown = function () {
        var _a, _b, _c, _d;
        return (this.UrlsArray.controls.length == 0 ||
            (this.bulk && ((_a = this.attributesUrl[0]) === null || _a === void 0 ? void 0 : _a.attributeId) == 'url_appointment' ||
                ((_b = this.attribute) === null || _b === void 0 ? void 0 : _b['attributeId']) == 'url_appointment' ||
                ((_c = this.attribute) === null || _c === void 0 ? void 0 : _c['attributeId']) == 'url_order_ahead' ||
                ((_d = this.attribute) === null || _d === void 0 ? void 0 : _d['attributeId']) == 'url_reservations') &&
                this.UrlsArray.controls.length < 10);
    };
    return ModalAttributeUrlComponent;
}());
export { ModalAttributeUrlComponent };
