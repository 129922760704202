// dep
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient /*, HttpParams*/ } from '@angular/common/http';
import { Observable } from 'rxjs';

// app
import Group from '../constants/firestore/group';
import { GROUPS, SEARCH_INDEX } from '../constants/firestore/collections';
import { environment as ENV } from '@environment';
import { ApiResponse } from '../constants/api-response';
import SavedLocation from '../constants/firestore/saved-location';

// TODO: Inconsistent API naming on backend, solve
const groupURL  = (gid : string) => `${ENV.apiUrl}/v2/groups/${gid}`
const groupURL2 = (gid : string) => `${ENV.apiUrl}/v2/groups/gid/${gid}`

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  // private groupRef = this.afs.collection(GROUPS);

  constructor(
    private _afs: AngularFirestore,
    private _http: HttpClient,
  ) {
  }

  updateGroup(gid : string, changes: Partial<Group>) : Promise<unknown> {
    return this._http.put(groupURL(gid), changes).toPromise();
  }

  // TODO: Unused, remove
  // 
  // searchIndex(gid): Observable<Group> {
  //   return this.afs.collection<Group>(GROUPS).doc<Group>(gid).valueChanges();
  // }

  fetchGroup(gid: string) : Promise<Group> {
    return this._http.get<Group>(groupURL(gid)).toPromise()
  }

  getIndexes(gid: string) : Observable<any[]> {
    return this._afs.collection(`${GROUPS}/${gid}/${SEARCH_INDEX}`).valueChanges();
  }

  getReportIndexes(gid: string, type: string) {
    return this._afs.collection(`${GROUPS}/${gid}/${SEARCH_INDEX}`,
      rf => rf.where('type', '==', 'report').where('reportType', '==', type)
    ).valueChanges();
  }

  // TODO: Unused, remove
  // getLocationIndexes(docId: string) {
  //   return this.afs.collection(`${GROUPS}/${docId}/${SEARCH_INDEX}`,
  //     rf => rf.where('type', '==', 'location')
  //   ).valueChanges();
  // }

// TODO: Unused, remove?
// 
//   getGroupByDomain(domain: string) {
//     return this.afs.collection(GROUPS,
//       rf => rf.where('domain', '==', domain)
//     ).get();
//   }
// 
//   refreshIndexes(enqueue: string): Observable<any> {
//     let params = new HttpParams();
//     if (enqueue && ENV.queuesEnabled) {
//       params = params.append('enqueue', enqueue);
//     }
// 
//     return this.http.get<any>(`${ENV.apiUrl}/v2/groups/refresh/indexes`, { params });
//   }
// 
//   async groupsByUser(list) {
//     await Promise.all(list.map(
//       async (user) => {
//         if (user.gid) {
//           const group = this.getByDocId(user.gid);
//           const asyncData = await Promise.all([group]);
//           if (asyncData[0]) {
//             await new Promise(resolve =>
//               asyncData[0]
//                 .subscribe(g => {
//                   user.locationsCount = _.get(g, 'totalLocationsCount', 0);
//                   resolve(g);
//                 }));
//           }
//         } else {
//           return;
//         }
//       }
//     ));
//   }

  async get_accounts(gid: string): Promise<any[]> {
    return (await this._http.get<ApiResponse>(groupURL2(gid)+'/get_accounts').toPromise()).data as any[]
  }

  // TODO: Probably can be replaced by locationService.fetchLocationsByAccount, duplicate backend endpoints?
  async get_locations(gid: string, accountId: string): Promise<SavedLocation[]> {
    return (await this._http.get<ApiResponse>(groupURL2(gid)+`/account/${accountId}/get_locations`).toPromise()).data || []
  }

  // TODO: Unused, remove
  // getReports(filter?: { domain: string }) {
  //   let domain = ''
  //   if (filter.domain !== null && filter.domain !== '') {
  //     domain = `?domain=${filter.domain}`
  //   }
  //   return this.http.get<ApiResponse>(`${ENV.apiUrl}/v2/groups/report/domain${domain}`).pipe(map(value => value.data));
  // }

}


