// dep
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

// app
import { SessionService } from 'src/app/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class PlanGuardService implements CanActivate {

  constructor(private _sessionS: SessionService, 
              private _router: Router) {}
  
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      const {pricingVersion} = (await this._sessionS.waitSession()).subscription;
      if (pricingVersion !== 3) {
        await this._router.navigate(['/settings/billing']); // Redirect to billing if not type 3
        return false;
      }
      return true;
  }  
}
