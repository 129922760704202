import { MatTooltipModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompassButtonComponent } from './compass-button/compass-button.component';

@NgModule({
    declarations: [CompassButtonComponent],
    imports: [CommonModule, MatTooltipModule],
    exports: [CompassButtonComponent]
})
export class CompassModule { }