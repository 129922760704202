import { __read, __spread } from "tslib";
// dep
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// app
import { GoogleService } from '../../../services/google.service';
import { LocationEditService } from '../../../services/location-edit.service';
import { ModalAttributesComponent } from '../../modal-attributes/modal-attributes.component';
import { SessionService } from 'src/app/services/session.service';
var LocationAttributesComponent = /** @class */ (function () {
    function LocationAttributesComponent(_sessionS, _dialogS, _googleS, _locationEditS) {
        this._sessionS = _sessionS;
        this._dialogS = _dialogS;
        this._googleS = _googleS;
        this._locationEditS = _locationEditS;
        this.attributes = [];
        this.socialMedia = [];
        this.isEdit = true;
        this.urls = new EventEmitter();
        this.onUpdate = new EventEmitter();
        this.urlAttributes = [];
        this.excludedAtributes = [];
        this.socialMediaAttributes = {};
        this.allSocialMediaOptions = [];
    }
    LocationAttributesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allSocialMediaOptions = this._locationEditS.allSocialMediaOptions;
        if (this.isEdit) {
            this.sortAttributes();
        }
        else {
            var clonedAttributes = __spread(this.attributes);
            this.socialMedia = clonedAttributes.filter(function (el) { return _this._locationEditS.isSocialMedia(el); });
            this.attributes = clonedAttributes.filter(function (el) { return !_this._locationEditS.isSocialMedia(el); });
        }
    };
    LocationAttributesComponent.prototype.sortAttributes = function () {
        var _this = this;
        this._locationEditS.locationEdit$.subscribe(function (locationEdit) {
            if (locationEdit) {
                _this.isProgressAttributes = true;
                _this._googleS
                    .groupsWithAttributeSaved(_this._sessionS.getSession().gid, _this._locationEditS.accountId, _this._locationEditS.locationId, locationEdit.primaryCategory.categoryId, _this.attributes, _this.googleAttributes)
                    .then(function (groupResult) {
                    _this.attributesInGroups = [];
                    _this.urlAttributes = [];
                    _this.socialMediaAttributes = [];
                    _this.isProgressAttributes = false;
                    _this.socialMediaAttributes = groupResult.groups.find(function (el) { return el.groupName == 'Place page URLs'; });
                    _this.attributesInGroups = _this.sortAttrInGroups(groupResult.groups);
                    var socialMediaIndex = groupResult.groups.findIndex(function (g) { return g.groupName === 'Place page URLs'; });
                    // this.attributesInGroups.splice(socialMediaIndex, 1);
                    _this.attributesInGroups[socialMediaIndex].items = _this.removeSocialMedia(groupResult.groups, socialMediaIndex);
                    _this.excludedAtributes = groupResult.excludedAtributes;
                    _this.leastOneGroupActive = false;
                    _this.googleItemActive = false;
                    _this.attributesInGroups.forEach(function (group) {
                        if (group.active) {
                            group.items.forEach(function (item) {
                                var _a, _b;
                                if (item.valueType === "URL" && !_this._locationEditS.isSocialMedia(item)) {
                                    var excludedItem = groupResult.excludedAtributes.find(function (el) { return el.attributeId === item.attributeId; });
                                    if (((_a = excludedItem) === null || _a === void 0 ? void 0 : _a.uriValues) && !((_b = excludedItem) === null || _b === void 0 ? void 0 : _b.urlValues)) {
                                        item.urlValues = excludedItem.uriValues;
                                        item.urlValues.forEach(function (el) {
                                            el.url = el['uri'];
                                            delete el['uri'];
                                        });
                                    }
                                    _this.urlAttributes.push(item);
                                }
                                if (item.active) {
                                    _this.googleItemActive = true;
                                }
                            });
                            _this.urls.emit(_this.urlAttributes);
                            _this.leastOneGroupActive = true;
                        }
                    });
                    _this.googleAttributes = groupResult.googleAttributes;
                });
            }
        });
    };
    LocationAttributesComponent.prototype.removeSocialMedia = function (groups, socialMediaIndex) {
        var _a, _b;
        var excludedAttributeIds = ["url_facebook", "url_pinterest", "url_youtube", "url_instagram", "url_twitter", "url_linkedin", "url_tiktok"];
        return (_b = (_a = groups[socialMediaIndex]) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return !excludedAttributeIds.includes(item.attributeId); });
    };
    LocationAttributesComponent.prototype.sortAttrInGroups = function (data) {
        data = data.sort(function (a, b) { return (a.groupName < b.groupName ? -1 : 1); });
        var indexFromBuss = data.findIndex(function (el) { return el.groupName == 'From the business'; });
        if (indexFromBuss != -1) {
            data.unshift(data[indexFromBuss]);
            data.splice((indexFromBuss + 1), 1);
        }
        return data;
    };
    // edit attributes
    LocationAttributesComponent.prototype.openAttributesDialog = function (isSocialMedia) {
        var _this = this;
        if (isSocialMedia === void 0) { isSocialMedia = false; }
        var dialogRef = this._dialogS.open(ModalAttributesComponent, {
            width: '680px',
            data: {
                isSocialMedia: isSocialMedia,
                groupAttributes: this.attributesInGroups,
                excludedAtributes: this.excludedAtributes,
                socialMedia: this.socialMedia
            }
        });
        dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe(function (result) {
            if (result != '') {
                _this.attributes = result.attributes;
                _this.attributesEnabled = result.attributesEnabled;
                _this.sortAttributes();
                _this.onUpdate.emit(true);
            }
        });
    };
    LocationAttributesComponent.prototype.getSocialMediaItems = function (item) {
        return this.allSocialMediaOptions.find(function (opt) { return opt.value === item; });
    };
    LocationAttributesComponent.prototype.getUrl = function (item) {
        var _a, _b, _c;
        return ((_c = (_b = (_a = item) === null || _a === void 0 ? void 0 : _a.urlValues) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.url) || '-';
    };
    LocationAttributesComponent.prototype.hasAttributes = function () {
        return !!this.attributes.find(function (el) { return el.valueType != 'URL'; });
    };
    Object.defineProperty(LocationAttributesComponent.prototype, "socialMediaItems", {
        get: function () {
            return this.socialMedia;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationAttributesComponent.prototype, "attributesItems", {
        get: function () {
            return this.attributes;
        },
        enumerable: true,
        configurable: true
    });
    return LocationAttributesComponent;
}());
export { LocationAttributesComponent };
