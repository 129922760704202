// dep
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';

const FlexSearch = require('flexsearch');

// app
import SavedAccount from '../../constants/firestore/saved-account';
import SavedLocation from '../../constants/firestore/saved-location';
import AccountReport from '../../services/account-report';
import { LocationService } from '../../services/location.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { GroupService } from '../../services/group.service';
import { ProtocolService } from '../../services/protocol.service';
import { environment as ENV } from '@environment';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';


@Component({
  selector: 'app-toggle-locations-in-account',
  templateUrl: './toggle-locations-in-account.component.html',
  styleUrls:  ['./toggle-locations-in-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleLocationsInAccountComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  private _accountsCopy: SavedAccount[]; // TODO: Probably some are not a copy / not a shallow one, review. 
  private _isEdit = false;

  public loadingSearch = false;

  // public accountsFilter: any; // TODO: Unused, remove.
  public noAccounts = false;
  public flexSearch = new FlexSearch({
    encode: "advanced",
    tokenize: "reverse",
    suggest: true,
    cache: true,
    doc: {
      id: 'id',
      field: [
        'accountName',
        'locationName',
        'locName',
        'address',
        'serviceArea',
        'reportName',
        'labels'
      ]
    }
  });
  public accounts: SavedAccount[] = [];
  public search: string;
  public isFilter = false;
  private _listEventSelect: AccountReport[] = [];
  public hasInfoNotification = false;

  // TimeOut search
  public timeout: any = null;

  session$ = this._sessionS.session$;

  constructor(
    private _locationS: LocationService,
    private _protocolService: ProtocolService,
    private _sessionS : SessionService,
    private _groupS: GroupService,
    private _http: HttpClient,
    private _router: Router,
    private _cdRef: ChangeDetectorRef
  ) {
    super();
  }

  @Input() reportEdit: AccountReport[];
  @Input() selectByUser = false;
  @Input() checkUpgrade = false;
  @Input() isPost = false;
  // @Input() safeNumberLocations = true;
  @Input() filterProtocolLocations = false;
  @Input() isSliderOpened = false;
  @Input() isSlider = false;
  @Input() txtInfoNotification = null;
  @Input() hasGoToLocation = false;
  
  @Output() selected = new EventEmitter<AccountReport[]>();

  @ViewChild(MatAccordion, {static: false}) 
  accordion: MatAccordion;

  private _disableAccounts(accounts: SavedAccount[]) {
    return accounts.map(account => {
      account.checked = false;
      return account;
    });
  }


  ngOnInit() : void {
    this.search = null;
    if (this.reportEdit && this.reportEdit.length > 0) {
      this._listEventSelect = JSON.parse(JSON.stringify(this.reportEdit));
    }

    this._setInfoNotification();
    this._fetchAccounts();
  }

  ngOnChanges() : void {
    if (this.isSlider) {
      if (!this.isSliderOpened) {
        this._removeAllChecked();
        this.ngOnDestroy();
        this.accordion?.closeAll();
      } else {
        this.ngOnInit();
      }
    }
  }

  private _removeAllChecked() {
    this._listEventSelect?.forEach(el => {
      const account = this.accounts.find(a => a.accountId === el.accountId);
      if (account?.selectAll) {
        this.changeAccountSelected(account);
      } else {
        account?.locations?.forEach(l => {
          if (l?.checked) {
            this.changeLocationsSelected(l, account);
          }
        })
      }
    })
  }

  private async _fetchAccounts() : Promise<void> {
    const {gid} = this._sessionS.getSession();

    if (this.filterProtocolLocations) {
      const accounts = await this._protocolService.getAccountsProtocols();

      if (accounts.length) {
        this.accounts = this._accountsCopy = this._disableAccounts(accounts);
      } else {
        this.noAccounts = true
      }

    } else {
      const accounts = await this._groupS.get_accounts(gid);
      if (accounts) {
        this.accounts = this._accountsCopy = this._disableAccounts(accounts);
      }
      // FIXME: Why no { else this.noAccounts = true} here? In purpose or bug?

    }

    if (this.reportEdit) {
      this.reportEdit.forEach(async el => {
        const accountIndex = this.accounts.findIndex(acc => acc.accountId === el.accountId);
        this.accounts[accountIndex].locations = el.locations;
      })
      this.accounts.forEach(account => {
        this._isActiveAccount(account, this.reportEdit);
      });
    }

    this._onChanges();
  }


  // TODO: Move to location.service.ts:getLocationsIdsByStringQuery
  private _getAccountsLocations(query: string) : Promise<{accounts : SavedAccount[]}>{
    const {gid} = this._sessionS.getSession();
    return this._http.post<any>((`${ENV.apiUrl}/v2/search/gid/${gid}/account-locations` + 
                                  (this.filterProtocolLocations ? '/protocols' : '')),
                                { query }).toPromise();
  }


  public changeAccountSelected(a: SavedAccount) : void {
    if (a.checked && a.selectAll) {
      a.checked = false;
      a.selectAll = false;
      a.locations.forEach(l => {
        l.checked = false;
        this._setChange(l, a);
        this._removeToList(l, a);
      });
    } else if (!a.selectAll) {
      a.selectAll = true;
      a.checked = true;
      a.locations.forEach(l => {
        l.checked = !l.deny;
        this._setChange(l, a);
        if (!l.deny) {
          this._AddToList(l, a);
        }
      });
    }

    this._onChanges();
  }

  private _removeToList(l: SavedLocation, a: SavedAccount) {
    const Search_Account = this._listEventSelect.find(r => r.accountId === a.accountId);
    if (Search_Account) {
      const search_locations = Search_Account.locations.find(loc => loc.locationId === l.locationId);
      if (search_locations) {
        const locationsFiltered = Search_Account.locations.filter(loc => loc.locationId !== l.locationId);
        if (locationsFiltered.length === 0) {
          this._listEventSelect = this._listEventSelect.filter(acc => acc.accountId !== a.accountId);
        } else {
          this._listEventSelect = this._listEventSelect.map(acc => {
            if (acc.accountId === a.accountId) {
              acc.locations = locationsFiltered
            }
            return acc;
          });
        }
      }
    }
  }

  private _AddToList(l: SavedLocation, a: SavedAccount) {
    if (this._listEventSelect.length === 0) {
      const element = {
        accountId: a.accountId,
        locationId: l.locationId,
        accountName: a.accountName,
        locationName: l.locationName
      }
      if (l.location) {
        if (l.location.labels) element['labels'] = l.location.labels
        if (l.location.address) element['address'] = l.location.address
        if (l.location.serviceArea) element['serviceArea'] = l.location.serviceArea
      } else if (l?.labels) {
        element['labels'] = l.labels;
        if (l?.address) element['address'] = l.address
      } else if (l?.address) {
        element['address'] = l.address;
      } else if (l?.serviceArea) {
        element['serviceArea'] = l.serviceArea;
      }

      this._listEventSelect.push({
        accountId: a.accountId,
        gid: a.gid,
        locations: [element]
      });
    } else {
      const Search_Account = this._listEventSelect.find(r => r.accountId === a.accountId);
      if (Search_Account) {
        const search_locations = Search_Account.locations.find(loc => loc.locationId === l.locationId);
        if (search_locations) {
          const locationsFilter = Search_Account.locations.filter(loc => loc.locationId !== l.locationId);
          if (locationsFilter.length === 0 && !l.checked) {
            if (a?.selectAll) a.selectAll = false;
            this._listEventSelect = this._listEventSelect.filter(acc => acc.accountId !== a.accountId);
          } else {
            this._listEventSelect = this._listEventSelect.map(acc => {
              if (acc.accountId === a.accountId && !l.checked) {
                acc.locations = locationsFilter
              }
              return acc;
            });
          }
        } else {
          this._listEventSelect = this._listEventSelect.map(acc => {
            if (acc.accountId === a.accountId) {
              const element = {
                accountId: a.accountId,
                locationId: l.locationId,
                accountName: a.accountName,
                locationName: l.locationName
              }
              if (l.location) {
                if (l.location.labels) element['labels'] = l.location.labels
                if (l.location.address) element['address'] = l.location.address
                if (l.location.serviceArea) element['serviceArea'] = l.location.serviceArea
              } else if (l?.labels) {
                element['labels'] = l.labels;
                if (l?.address) element['address'] = l.address
              } else if (l?.address) {
                element['address'] = l.address;
              } else if (l?.serviceArea) {
                element['serviceArea'] = l.serviceArea;
              }
              acc.locations.push(element)
            }
            return acc;
          });
        }
      } else {
        const element = {
          accountId: a.accountId,
          locationId: l.locationId,
          accountName: a.accountName,
          locationName: l.locationName
        }
        if (l.location) {
          if (l.location.labels) element['labels'] = l.location.labels
          if (l.location.address) element['address'] = l.location.address
          if (l.location.serviceArea) element['serviceArea'] = l.location.serviceArea
        } else if (l?.labels) {
          element['labels'] = l.labels;
          if (l?.address) element['address'] = l.address;
        } else if (l?.serviceArea) {
          element['serviceArea'] = l.serviceArea;
        }
        this._listEventSelect.push({
          accountId: a.accountId,
          gid: a.gid,
          locations: [element]
        });
      }
    }

    const locationChecked   = a.locations?.filter(l => l.checked);
    const possibleLocations = a.locations?.filter(l => !l.deny);
    a.selectAll = locationChecked.length === possibleLocations.length;

    this._listEventSelect.forEach(loc => {
      if ('locationsBasics' in loc) {
        delete loc['locationsBasics']
      }
    })

  }

  public changeLocationsSelected(loc: SavedLocation, acc: SavedAccount) : void {
    loc.checked = !loc.checked;
    const accountLocationsActive = acc.locations.filter(l => l.checked);
    acc.checked = accountLocationsActive.length !== 0;
    this._setChange(loc, acc);
    this._AddToList(loc, acc);
    this._onChanges();
  }


  private _onChanges() : void {
    this._setInfoNotification();
    this.selected.emit(this._listEventSelect);
    this._cdRef.detectChanges();
  }

  private _setChange(l: SavedLocation, a: SavedAccount) {
    const account = _.find(this._accountsCopy, { 'accountId': a.accountId });
    const location = _.find(account.locations, { 'locationId': l.locationId });
    location.checked = l.checked;

    // if toggle uncheck and it depends on reporEdit, delete it.
    if (l.checked === false && this.search){
      const accountReportEdit = _.find(this.reportEdit, { 'accountId': a.accountId })
      if (accountReportEdit){
        const locationIndex = _.findIndex(accountReportEdit.locations, { 'locationId': l.locationId });
        if (locationIndex !== -1) {
          accountReportEdit.locations.splice(locationIndex, 1);
        }
      }
    }
  }

  private _isActiveAccount(account: SavedAccount, report: any[], isFromFilterSearch = false) {
    const {isTrial} = this._sessionS.getSession();
    
    Promise.all(report.map((activeAccount) => {
      if ((activeAccount.gid + activeAccount.accountId) === (account.gid + account.accountId)) {
        account.selectAll = false;
        account.checked = true;
        account.locations?.forEach(location => {
          const locationId = location.locationId;
          activeAccount.locations.forEach(l => {
            if (locationId === l.locationId) {
              location.checked = true;
            }
          });

          if ((!isTrial && location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) || 
              (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.selectByUser)) {
            location.deny = true;
          }
        });
      }
    }));
    if (!isFromFilterSearch){
      this._accountsCopy = this.accounts
    }
  }

  public filterLocation($event: string, key: any) : void {
    if ($event[$event.length - 1] === ' ') {
      this._onChanges();
      return
    }

    clearTimeout(this.timeout);
    this.loadingSearch = true;
    this.timeout = setTimeout(async () => {
      if (!$event || $event === '') {
        this.isFilter = false;
        this.accounts = await this._checkSelected(this._accountsCopy, this.accounts);
        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this._isActiveAccount(account, this.reportEdit);
          });
        }
        this.loadingSearch = false;
      } else {
        const text = $event.toLowerCase();
        const result = await this._getAccountsLocations(text);
        this.accounts = await this._checkSelected(result.accounts, this._accountsCopy);
        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this._isActiveAccount(account, this.reportEdit, true);
          })
        }
        this.isFilter = true;
        this.loadingSearch = false;
      }
      this._onChanges();
    }, 350);
    this._onChanges();
  }


  private async _checkSelected(source : SavedAccount[], otherSource : SavedAccount[]) : Promise<SavedAccount[]> {
    const {gid} = this._sessionS.getSession();
    
    for (const acc of source) {
      const selectAcc = _.find(otherSource, { 'accountId': acc.accountId });
      if (selectAcc?.locations) {
        selectAcc.locations.forEach(l => {
          const currentloc : SavedLocation = _.find(acc.locations, { 'locationId': l.locationId });
          if (currentloc) {
            currentloc.checked          = l.checked || false;
            currentloc.deny             = (l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE);
            currentloc.subscriptionType = l.subscriptionType;
            currentloc.serviceArea      = l.location?.serviceArea || l.serviceArea;
          }
        });
      } else if (selectAcc && !selectAcc.locations && acc.locations) {
        selectAcc.locations = await this._locationS.fetchAccountLocations(gid, selectAcc.accountId);
        selectAcc.locations.forEach(l => {
          const currentloc : SavedLocation = _.find(acc.locations, { 'locationId': l.locationId });
          if (currentloc) {
            currentloc.checked          = l.checked || false;
            currentloc.deny             = (l.deny || l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE);
            currentloc.subscriptionType = l.subscriptionType;
            currentloc.serviceArea      = l.location?.serviceArea || l?.serviceArea;
          }
        });
      }
    }

    return source;
  }

  private _setInfoNotification(): void {
    if(!this.txtInfoNotification) { 
      return;
    }

    let totalLocations = 0;
    this._listEventSelect.forEach(account => {
      totalLocations += account.locations.length;
    });

    this.hasInfoNotification = (totalLocations < 2);
  }

  ngOnDestroy(): void {
    this.accounts = this._disableAccounts(this.accounts); // TODO: probably superflous, remove.
    super.ngOnDestroy();
  }

  public async openAccount(account: SavedAccount): Promise<void> {
    const { user, gid, isMember } = this._sessionS.getSession();

    if (this.accounts.length === 1 && this._isEdit) {
      this._onChanges();
      return;
    }

    if (!_.has(account, 'locations')) {
      if (this.filterProtocolLocations) {
        account.locations = await this._protocolService.getLocationsProtocols(account.accountId);
        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this._isActiveAccount(account, this.reportEdit);
          });
          this._setInfoNotification();
          this.selected.emit(this._listEventSelect);
          this._isEdit = true;
        }
        account['loadAllLocations'] = true;
      } else {
        const locations = await this._groupS.get_locations(gid, account.accountId);
        if (isMember) {
          const memberLocations = [];
          user.accounts.forEach(account => {
            account.locations.forEach(location => {
              memberLocations.push(location.locationId)
            });
          });
          const memberLocation = [];
          locations.forEach(location => {
            if (memberLocations.includes(location.locationId)) {
              memberLocation.push(location);
            }
          });
          account.locations = memberLocation;
        } else {
          account.locations = locations;
        }

        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this._isActiveAccount(account, this.reportEdit);
          });
          this._setInfoNotification();
          this.selected.emit(this._listEventSelect);
          this._isEdit = true;
        }
        account['loadAllLocations'] = true;
      }
    } else if (!_.get(account, 'loadAllLocations') && !this.isFilter) {
      const prevAccounts = _.cloneDeep(this._accountsCopy);
      account.locations = null;
      if (this.filterProtocolLocations) {
        account.locations = await this._protocolService.getLocationsProtocols(account.accountId);
        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this._isActiveAccount(account, this.reportEdit);
          });
          this._setInfoNotification();
          this.selected.emit(this._listEventSelect);
          this._isEdit = true;
        }
        account['loadAllLocations'] = true;
        account = _.head(await this._checkSelected([account], prevAccounts));

      } else {
        if (_.has(account, 'locations')) 
          account.locations = null;

        account.locations = await this._groupS.get_locations(gid, account.accountId);
        if (this.reportEdit && this.reportEdit.length > 0) {
          this.accounts.forEach(account => {
            this._isActiveAccount(account, this.reportEdit);
          });
          this._setInfoNotification();
          this.selected.emit(this._listEventSelect);
          this._isEdit = true;
        }
        account['loadAllLocations'] = true;
        account = _.head(await this._checkSelected([account], prevAccounts));
      }
    }

    if (this.checkUpgrade) {
      account.locations = account.locations.filter(loc => (loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
                                                           loc.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC));
    }

    this._onChanges();
  }

  // TODO: Unused, remove
  // changeIconToggle(e) : void {
  //   const icon = document.getElementById(`Icon${e}`);
  //   if (icon.classList.contains('fa-plus')) {
  //     icon.classList.remove('fa-plus');
  //     icon.classList.add('fa-minus');
  //   } else {
  //     icon.classList.remove('fa-minus');
  //     icon.classList.add('fa-plus');
  //   }
  // }

  public goToLocation(account : SavedAccount, location : SavedLocation): void {
    this._router.navigate(['/account', account?.accountId, 'location', location?.locationId, 'post-management'])
  }

  public detectToggle(): void {
    console.log('detected!');
    this._onChanges()    
  }

}
