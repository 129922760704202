import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-download-manager',
  templateUrl: './download-manager.component.html',
  styleUrls: ['./download-manager.component.scss']
})
export class DownloadManagerComponent implements OnInit {
  public title: string;
  public subtitle: string;
  public iconClass: string;
  public type: string;
  public id: string;
  public companyName: string;

  constructor(
    private _route: ActivatedRoute,
    private _subService: SubscriptionService,
    private _snackS: SnackbarService,
    private _sessionS: SessionService
  ) { }

  ngOnInit() {
    this.type = this._route.snapshot.paramMap.get('type');
    this.id = this._route.snapshot.paramMap.get('id');
    this.companyName = this._sessionS.getDomain()?.branding?.company_name || null;

    switch (this.type) {
      case 'invoice':
        this.title = 'Invoice Download';
        this.subtitle = 'Your Invoice';
        this.iconClass = 'fas fa-file-invoice-dollar'
        break;
    }
  }

  download(): void  {
    let filename;
    let apiUrl;

    switch (this.type) {
      case 'invoice':
        filename = `invoice_${this.id}.pdf`
        apiUrl = this._subService.getInvoicePDF(this.id);
        break;
      default:
        console.error('Unknown download type:', this.type);
        return;
    }

    this.getFile(apiUrl, filename);
  }

  handleDownload(type: string, id): void {
    let apiUrl = null;
    let filename = null;

    switch (type) {
      case 'invoice':
        filename = `invoice_${id}.pdf`
        apiUrl = this._subService.getInvoicePDF(id);
        
        this.title = 'Invoice Download';
        this.subtitle = 'Your Invoice'
        this.iconClass = 'fas fa-file-invoice-dollar'
        break;
      default:
        console.error('Unknown download type:', type);
        return;
    }

    this.getFile(apiUrl, filename);
  }

  getFile(apiUrl, filename: string): void {
    if (!apiUrl) {
      this._snackS.openError('The API URL is invalid');
      return;
    }
  
    apiUrl.subscribe({
      next: (response: Blob) => {
        if (response && response.size > 0) {
          this.downloadFile(response, filename);
        } else {
          this._snackS.openError('The file could not be downloaded. Blob is empty.');
        }
      },
      error: (err) => {
        console.error('Download failed:', err);
        this._snackS.openError('There was an issue downloading the file');
      },
    });
  }

  downloadFile(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

}
