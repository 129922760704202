// dep
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// app
import { environment as ENV} from '@environment';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { ISubscription } from '../constants/subscription';


// Normalized on the backend from Stripe old Sources API and new Payment Methods API
// Subset of the Stripe Payment Method interface
export type PaymentMethod = {
  id : string,  // e.g. "pm_1NVChw2eZvKYlo2CHxiM5E2E",
  type : 'card', // More payment types in the future, see MAP-2112
  card : {
    brand     : 'amex' | 'diners' | 'discover' | 'jcb' | 'mastercard' | 'unionpay' | 'eftpos_au' | 'visa',
    exp_month : number,  // 1 to 12
    exp_year  : number, 
    last4     : string   // four digit string
  }
}


// TODO: replace with lib type after upgrading stripe-js
type SetupIntent = {
  client_secret : string
  status: 'requires_payment_method' | 'requires_confirmation' | 'requires_action' | 'processing' | 'canceled' | 'succeeded';
  payment_method : string | null
}

type PaymentIntent = {
  client_secret : string
  status: 'requires_payment_method' | 'requires_confirmation' | 'requires_action' | 'processing' | 'requires_capture' | 'canceled' | 'succeeded'
  payment_method : string | null
}

type StripeError = {
  error : string
  user_message? : string
}

const URL_CARD = (gid : string, cardId? : string) => 
                   `${ENV.billingApiUrl}/card/by-gid/${gid}` + (cardId ? '/' + cardId : '')

const URL_PM = (gid : string, suffix = '') => `${ENV.billingApiUrl}/card/payment-methods/${gid}` + suffix

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient) {} 
  
  async addNewCard(gid: string, token: string) {
    return await this.http.post(URL_CARD(gid), {source: token}).toPromise();
  }

  deleteCard(gid : string, cardId: string) : Promise<any> {
    return this.http.delete(URL_CARD(gid, cardId)).toPromise()
  }

  async getCards(gid : string) : Promise<any[]> {
    return (await this.http.get<{data: any[]}>(URL_CARD(gid)).toPromise()).data
  }

  async hasPaymentMethods(gid : string) : Promise<boolean> {
    try { 
      return !!(await this.getCards(gid)).length
    } catch {
      return false
    }
  }

  // TODO: Change to "fetchPlan" and return a promise, not Observable (is single-shot)
  getPlan(planId : LOCATION_SUBSCRIPTION_TYPE) {
    return this.http.get(`${ENV.billingApiUrl}/product/${planId.toLowerCase()}`)
  }

  setDefaultSource(gid : string, cardId : string) {
    return this.http.post(URL_CARD(gid, cardId)+'/default', {}).toPromise()
  }


  //--------------------------------------------------------------------------
  // New Payment Methods interface 
  //--------------------------------------------------------------------------
  //
  // TODO: Remove the old one after we migrated to the new PaymentMethodComponent for all PM operations
  //

  public async fetchPaymentMethodDefault(gid : string) : Promise<PaymentMethod | null> {
    return (await this.http.get<any>(URL_PM(gid, '/default')).toPromise()).data
  }



  /**
   * Ask the Backend to use his Stripe Secret key to create a new Customer Session on
   * Stripe.
   */
  public async startCustomerSession(gid : string) : Promise<{client_secret : string}> {
    return (await this.http.post<any>(URL_PM(gid, '/customer-session'), {}).toPromise()).data
  }

  /**
   * Asks the backend to request Stripe to create a Setup Intent using a Confirmation Token
   */
  public async createSetupIntent(gid : string, confirmationTokenId : string) : 
    Promise<Pick<SetupIntent, 'client_secret' | 'status' | 'payment_method'> | StripeError> {

    return (await this.http.post<any>(URL_PM(gid, '/setup'), {confirmationTokenId}).toPromise()).data
  }

  /**
   * Deletes a Payment Method
   */
  public async deletePaymentMethod(gid : string, paymentMethodId : string) : Promise<void> {
    await this.http.delete<any>(URL_PM(gid, '/'+paymentMethodId)).toPromise()
  }

  /**
   * Sets a Payment Method as the default one
   */  
  public async setPaymentMethodDefault(gid : string, paymentMethodId : string) : Promise<void> {
    await this.http.post<any>(URL_PM(gid, '/default'), {paymentMethodId}).toPromise()
  }


  /**
   * Asks the backend to create the First Payment Invoice and and his Payment Intent on Stripe,
   * for the given initial list of Packages, using a Confirmation Token.
   */  
  public async startFirstPayment(gid : string, 
                                 confirmationTokenId : string, 
                                 initialPackages : (keyof ISubscription['packages'])[]) :
    Promise<Pick<PaymentIntent, 'client_secret' | 'status' | 'payment_method'>  | StripeError> {

    return (await this.http.post<any>(URL_PM(gid, '/first-payment'),
                                      { confirmationTokenId,
                                        initialPackages, 
                                       }).toPromise()).data
  }

}
