import { __awaiter, __generator } from "tslib";
import { Router } from '@angular/router';
// app
import { SessionService } from 'src/app/services/session.service';
import * as i0 from "@angular/core";
import * as i1 from "./session.service";
import * as i2 from "@angular/router";
var PlanGuardService = /** @class */ (function () {
    function PlanGuardService(_sessionS, _router) {
        this._sessionS = _sessionS;
        this._router = _router;
    }
    PlanGuardService.prototype.canActivate = function (next, state) {
        return __awaiter(this, void 0, void 0, function () {
            var pricingVersion;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        pricingVersion = (_a.sent()).subscription.pricingVersion;
                        if (!(pricingVersion !== 3)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this._router.navigate(['/settings/billing'])];
                    case 2:
                        _a.sent(); // Redirect to billing if not type 3
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/, true];
                }
            });
        });
    };
    PlanGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlanGuardService_Factory() { return new PlanGuardService(i0.ɵɵinject(i1.SessionService), i0.ɵɵinject(i2.Router)); }, token: PlanGuardService, providedIn: "root" });
    return PlanGuardService;
}());
export { PlanGuardService };
