/*
Session service
 
Provides globally available state for an User session
Also some navigation procedures and business logic. 
 
Replaces most usages of auth.service.ts, user.service.ts, domain.service.ts, 
group.service.ts, etc
Also provides a single point of control and caching for the domain/session info.
 
== Usage from a component ==
 
 class MyComponent ... {
   // for templates
   session$ = this._sessionS.session$
   domain$  = this._sessionS.domain$
 
   constructor(
     private _sessionS : SessionService
   ) {
     // You can be sure domain is already fetched because is always done
     // before any component creation on the APP_INITIALIZER function.
     const domain = this._sessionS.getDomain()
     
     // You can be sure session is already fetched if this is a component
     // created under a parent component that guards the session is already 
     // loaded (see app.router.ts).
     const session = this._sessionS.getSession()
    }
  }

Then from the angular template:

   <span>{(domain$ | async).branding.company_name}</span>
   <span>{(session$ | async).user.email}</span>
  
Note that the domain/session is already fetched but '| async' will ensure 
that the template will be updated if domain/session changes.
 
== Anti-patterns ==
 
Don't cache session data as it was done before, e.g. on components:
 
// BAD:
 constructor(private _sessionS : SessionService) {
   this._sessionS.session$.subscribe(session => this.subscription = session.subscription)
 }
 someMethod() {
  if(this.subscription ...)
 }
 
// GOOD
  { 
  // Get the subscription in the place you are to read it
  const sub = this._sessionS.getSession().subscription
  }
  // On angular templates just use:
  (session$ | async).subscription
 
== Internals ==
  
State machine ($state):
 
   LOADING_DOMAIN
      |  domain$ is fetched
      v
   NOT_LOGGED (domain$ value is available)
      |  user logins  
      v
   LOADING_SESSION
      |  session$ is fetched
      v
   LOGGED_IN (domain$ and session$ values are available)
      
Note, if the user logouts the page is then reloaded, so there is no
backwards LOGGED_IN -> NOT_LOGGED transition.
*/ 

// dep
import { ReplaySubject } from 'rxjs'
import { Injectable, OnDestroy } from '@angular/core'
import { HttpClient } from "@angular/common/http"
import moment from 'moment';
import * as _ from 'lodash';

// app
import { Domain } from '../constants/firestore/domain';
import Group from '../constants/firestore/group';
import User from '../constants/firestore/user';
// import SavedLocation from '../constants/firestore/saved-location';
import { ISubscription } from '../constants/subscription';
import { GROUP_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { UserFeatures } from '../constants/user-features'
import { PackageEnum } from '../settings/constants/pricing';
import { GroupService } from './group.service';
import { SpinnerService } from './spinner.service';
import { UserService } from './user.service';
import { BROWSER_DOMAIN } from '../helpers/utils.helpers'
import { DomainService } from './domain.service';
// import { SubscriptionService } from './subscription.service';
import { asPromisedObservable, makeOpenPromise } from '../helpers/utils.helpers';
import { environment as ENV } from 'src/environments/environment';
import { MAIL_ANONYMOUS } from '../constants/auth';
import { BaseComponent } from '../components/base.component';
import { ApiResponse2 } from '../constants/api-response';

const AUTO_REFRESH_INTERVAL_LAPSE = 120_000;

// Firebase Auth by default persists his authentication state, see:
// https://firebase.google.com/docs/auth/web/auth-state-persistence
// But we also need to persist the gid for anonymous sesions,
// as for them the gid cannot be inferred from the uid
export type IAuthSession = {
  // Already present in the session stored by Firebase Auth:
  uid : string 
  // Inferred from uid or set as a forced gid on ANONYMOUS sessionType:
  gid : string
} & 
  // Determined on signIn:
  ({ sessionType : "NORMAL",          isImpersonating : true}   |
   { sessionType : "NORMAL",          isImpersonating : false}  |
   { sessionType : "ANONYMOUS",       isImpersonating : false}  |
   { sessionType : "EXTERNAL_GRADER", isImpersonating : false, externalGradeId : string });

type SessionType = IAuthSession['sessionType']

// All the data on this object will be updated on the most atomically way possible
// TODO: Type with DeepReadOnly from ts-essentials when tsc is upgraded
export type ISession = IAuthSession & {
    group        : Group
    subscription : ISubscription
    // Current logged-in user
    user         : User // | firebase.UserInfo when anonymous?
    features     : UserFeatures

    // Inferred
    // Those are not defined as getters because Angular runs them too often
    // instead of doing a value comparison to check if the value has changed.
    bulkActionsEnabled : boolean
    requiresPaymentMethod : boolean 
    restrictToPaywall : boolean
    isTrial  : boolean
    daysInTrial : number
    isAdmin  : boolean
    isMember : boolean
    isMasterAdmin : boolean
    //paymentMethodRequiredAndMissing : boolean
};

type State = 'LOADING_DOMAIN' | 'NOT_LOGGED' | 'LOADING_SESSION' | 'LOGGED_IN' | 'ERROR_LOADING'

type DomainExpanded = Domain & typeof BROWSER_DOMAIN

const ANONYMOUS_USER : User = {
  gid : '',
  uid : '',
  email : MAIL_ANONYMOUS,
  company : '',
  photoURL : '', 
  displayName : '',
  timezone : 0
}

const ANONYMOUS_USER_FEATURES : UserFeatures = {
  userFeatures    : {},
  generalFeatures : {}
}

function makeSession(authSession : IAuthSession, group : Group, sub: ISubscription,
                     user : User, features : UserFeatures) : ISession {
  //----------------
  // Type conversion
  //----------------
  try {
    const dp = group.dismissModalDatePicker;
    if (!dp) {
      //
    } if (dp instanceof moment) {
      group.dismissModalDatePicker = (dp as unknown as moment.Moment).toDate();
    } else {
      group.dismissModalDatePicker = new Date(dp as unknown as string);
    }
  } catch (err) {
    console.error(`Error parsing dismissModalDatePicker gid=${authSession.gid}`, err);
  }

  //-----------
  // Inferred
  //-----------
  const bulkActionsEnabled = !!(sub.pricingVersion <= 2 || sub.packages[PackageEnum.BULK_ACTIONS])
  const isTrial = (sub.status === GROUP_SUBSCRIPTION_TYPE.TRIAL)
  const requiresPaymentMethod = (!isTrial &&
                                  sub.collectionByBillingOverride[
                                    sub.billingOverride.toString() as 'true' | 'false'].requiresPaymentMethod)
  const restrictToPaywall = (sub.status === 'BLOCKED')

  const role = user.role?.toLowerCase()
  const isAdmin  = (role === 'admin' || role === 'master_admin')
  const isMember = (role === 'member')
  const isMasterAdmin = !!user.isMasterAdmin

  const trialEnd: number = (sub.trialEnd['$date'] || sub.trialEnd) 
  const daysInTrial = Math.round(Math.abs((new Date(trialEnd).getTime() - Date.now()) / (86400*1000)))

  // TODO: missing features should be populated with default values

  // TODO: use Object.freeze or _.deepFreeze after checking if it's compatible with angular 
  // internals, also freeze 'domain'.
  return { ... authSession,
            // Fetched:
            group,
            subscription : sub,
            user,
            features,
            // Inferred:
            bulkActionsEnabled,
            requiresPaymentMethod, 
            restrictToPaywall,
            isTrial,
            isAdmin,
            isMember,
            isMasterAdmin,
            daysInTrial
          }
}

@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseComponent implements OnDestroy {
  private _stateIn$ = new ReplaySubject<State>(1)
  state$ = asPromisedObservable(this._stateIn$)

  // Present both NOT_LOGGED and LOGGED_IN states:
  private _domainIn$ = new ReplaySubject<DomainExpanded>(1)
  domain$ = asPromisedObservable(this._domainIn$)

  // Only for LOGGED_IN states:
  // This observable will never complete, as a logout implies a page reload.
  // This way we avoid checking for null session values or using an outer
  // isLoggedIn observable. 
  private _sessionIn$ = new ReplaySubject<ISession>(1)
  session$ = asPromisedObservable(this._sessionIn$)

  // TODO: other app-wide state:
  // All the subscription locations:
  //  accounts
  //   private _locationsIn$ = new ReplaySubject<SavedLocation[]>(1)
  //    locations$ = asPromisedObservable(this._locationsIn$)
  // All users associated with the Subscription (includes the logged-in user)
  // users        : User[]
  // // Current route data:
  // locationId
  // location  // current edited/viewed location for single-location pages.

  private _refreshStatus : 'IDLE' | 'IN_PROGRESS' | 'REFRESH_SCHEDULED' = 'IDLE'
  private _refreshNextP = makeOpenPromise<void>()

  private _authSession : IAuthSession | null = null

  constructor(
    // app
    private _groupS   : GroupService,
    private _spinnerS : SpinnerService,
    private _userS    : UserService,
    private _domainS  : DomainService,
    // private _subscriptionS : SubscriptionService
    private _http : HttpClient
  ) {
    super();
    this._stateIn$.next('LOADING_DOMAIN')
    this._spinnerS.loading$.next(true)

    this.refresh()
    // TODO: Implement changes push instead of polling or at least
    // improve by scheduling a setTimeout after each refresh() /
    // clearTimeout
    const t = setInterval(() => { this.refresh() }, AUTO_REFRESH_INTERVAL_LAPSE);

    this._addFinalizer(() => clearInterval(t))
  }

  public onLogin(authSession : IAuthSession) : void {
    console.debug(`SessionService: onLogin`, authSession);
    if(this._authSession) {      
      console.error(`SessionService: trying to re-login from ${this._authSession} to ${authSession}`);
      throw new Error('Session already logged-in');
    }

    this._authSession = authSession
    this._spinnerS.loading$.next(true)

    this.refresh()

    this._subscribeSafe(this._userS.getUserChangesStream(authSession.gid, authSession.uid),
                        _ => this.refresh());
   }

  /**
   * Refreshes the session data fetching from the backend.
   * Calling this method multiple times will not trigger multiple fetches.
   * Awaiting it ensures that the session data will be freshly fetched.
   */
  public async refresh() : Promise<void> {
    if(this.state$.getSync() === 'ERROR_LOADING')
      return

    if(this._refreshStatus === 'IN_PROGRESS') {
      this._refreshNextP  = makeOpenPromise<void>();
      this._refreshStatus = 'REFRESH_SCHEDULED';
    }

    if(this._refreshStatus === 'REFRESH_SCHEDULED') {
      return await this._refreshNextP;
    }

    do {
      const p = this._refreshNextP;
      this._refreshStatus = 'IN_PROGRESS';
      await this._fetchAndEmit();
      p.resolve();
      // @ts-ignore 
      // TS doesn't understand that this member can be changed by a concurrent call
    } while(this._refreshStatus === 'REFRESH_SCHEDULED' && 
            this.state$.getSync() !== 'ERROR_LOADING');

    this._refreshStatus = 'IDLE';
  }

  // Shortcuts, DON'T use on Angular templates, use session$ and domain$ instead
  public getState() : State {
    return this.state$.getSync()
  }

  /**
   * @returns a Promise that waits for the Domain until is first loaded, or
   *  returns the Domain immediately if it's already loaded.
   */   
  public waitDomain() : Promise<DomainExpanded> {
    return this.domain$.currentValPromise()
  }

  public hasDomain() : boolean {
    return this.domain$.hasValue();
  }

  /**
   * @returns the Domain synchronously if it's already loaded or throws if not.
   */   
  public getDomain() : DomainExpanded {
    return this.domain$.getSync()
  }

  /**
   * @returns the Session synchronously if it's already loaded or throws if not.
   */  
  public getSession<T extends SessionType>(sessionTypeEnsured? : T) : Extract<ISession, { sessionType : T}>
  public getSession(sessionTypeEnsured? : SessionType) : ISession {
    const r = this.session$.getSync()
    if(sessionTypeEnsured && r.sessionType !== sessionTypeEnsured) {
      const m = `SessionService: Expected sessionType=${sessionTypeEnsured} but got ${r.sessionType}`;
      console.error(m);
      throw new Error();
    }
    return r;
  }

  public hasSession() : boolean {
    return this.session$.hasValue();
  }

  /**
   * @returns a Promise that waits for the Session until is first loaded, or
   * returns the Session immediately if it's already loaded.
   */  
  public waitSession() : Promise<ISession> {
    return this.session$.currentValPromise()
  }

  //--------------------------------------------------------------------------
  // Private
  //--------------------------------------------------------------------------
  private async _fetchAndEmit() : Promise<void> {
    const authSession = this._authSession
 
    const emit = (domain : Domain, session? : ISession) => {
      const d  = {... domain, 
                  ... BROWSER_DOMAIN }
      // We want to ensure domain$.hasValue() === true when state$.getSync() === 'NOT_LOGGED'. 
      // This depends on the synchronously execution of the subscribers when calling next(). 
      // Rxjs ensures that and the promisedObservable callbacks are also synchronous, so we 
      // are safe if we call domainIn$.next and then stateIn$.next here.
      // The same applies for session$.hasValue() == true and state$.getSync() == 'LOGGED_IN'
      if(!this.domain$.hasValue() || !_.isEqual(d, this.domain$.getSync())) {
        console.debug("SessionService: Setting new domain info", d)
        this._domainIn$.next(d)
      }

      if(session && (!this.session$.hasValue() || !_.isEqual(session, this.session$.getSync())) ) {
        console.debug("SessionService: Setting new session info", session)
        this._sessionIn$.next(session)
      }
    }

    const toState = (nextState : State) => {
      const curState = this.state$.getSync()
      if(curState !== nextState) {
        console.debug(`SessionService: ${curState} -> ${nextState} transition`)
        this._stateIn$.next(nextState)
        if(nextState === 'NOT_LOGGED' || nextState === 'LOGGED_IN' || nextState == 'ERROR_LOADING')
          this._spinnerS.loading$.next(false);
      }
    }
  
    try {
        //----------------------------
        // Domain info
        //----------------------------

        // Fetch the domain info, no need to be logged-in,
        // Optimization: don't fetch it if domain was already fetched and now
        // session info is requested.
        const domainP = ((this.domain$.hasValue() && !this.session$.hasValue() && authSession) ?
                         Promise.resolve(this.domain$.getSync()) :
                         this._domainS.fetchCurrentDomain());

        if(!authSession) {
          // Not yet logged-in
          emit(await domainP)
          toState('NOT_LOGGED');
          if(!authSession)
            // Nothing more to do until onLogin() is called
            return;
        }

        //----------------------------
        // Session info (needs login)
        //----------------------------        
        if(!this.session$.hasValue())
          toState('LOADING_SESSION');

        const isAnon = (authSession.sessionType === 'ANONYMOUS')
        const userP = (isAnon ? Promise.resolve({... ANONYMOUS_USER, 
                                                 uid : authSession.uid, 
                                                 gid : authSession.gid}) :
                                this._userS.fetchUser(authSession.gid, authSession.uid));
        const featuresP = (isAnon ? Promise.resolve(ANONYMOUS_USER_FEATURES) : 
                                    this._userS.getUserFeature(authSession.uid));

        // TODO: Move all of those network requests to a single backend endpoint
        const [domain, sub, group, user, features] = 
          await Promise.all([domainP,
                             this._fetchSubscription(authSession.gid, false), 
                             this._groupS.fetchGroup(authSession.gid),
                             userP,
                             featuresP] as const);
                             
        const session = makeSession(authSession, group, sub, user, features);

        emit(domain, session);
        if(this.state$.getSync() === 'LOADING_DOMAIN')
          // Respect the state machine, don't jump from LOADING_DOMAIN to LOGGED_IN
          toState('NOT_LOGGED');

        toState('LOGGED_IN');
              
      } catch(err) {
        // Only propagate the error if was raised on initial loading, if not keep working 
        // with the latest last cached values.
        const s = this.state$.getSync();
        const m = `SessionService: Error loading domain/session when ${s}`;
        if(s === 'LOADING_DOMAIN' || s === 'LOADING_SESSION') {
          console.error(m, err, authSession);
          toState('ERROR_LOADING');
        } else {
          console.debug(m, err, authSession);
          setTimeout(() => this.refresh(), 10_000);
        }
      } 
  
    }

    // TODO: Use subscriptionService.fetchSubscription by solving cyclic dep 
    async _fetchSubscription(gid : string, products=false) : Promise<ISubscription> {
      return ((await this._http.get<ApiResponse2<ISubscription>>(
                 `${ENV.billingApiUrl}/subscription/${gid}?products=${products ? 1 : 0}`).toPromise())).data
    }
  
}
