import { __awaiter, __extends, __generator } from "tslib";
// dep
import { Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import _ from 'lodash';
import { HttpClient } from '@angular/common/http';
// app
import { ModalService } from './services/modal.service';
import { HEADERS_NO_AUTH } from './constants/auth';
import { STORAGE_CUSTOM_SCRIPT } from "./constants/session";
import { AlertType } from './components/alert.component';
import { isRunningEmbedded } from './helpers/utils.helpers';
import { SessionService } from './services/session.service';
import { BaseComponent } from './components/base.component';
import { AuthService } from './services/auth.service';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(router, renderer, httpClient, modalService, _sessionS, _authS, document) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.renderer = renderer;
        _this.httpClient = httpClient;
        _this.modalService = modalService;
        _this._sessionS = _sessionS;
        _this._authS = _authS;
        _this.document = document;
        var _a = _this._sessionS.getDomain(), branding = _a.branding, domainName = _a.domainName;
        //this.titleService.setTitle(domain.branding.company_name);
        console.debug("Domain \"" + domainName + "\" loaded");
        _this._subscribeSafe(_this.router.events, function (event) {
            if (!(event instanceof NavigationEnd))
                return;
            var isShared = _.endsWith(_this.router.url, 'shared');
            if (!isShared) {
                if (branding.podium) {
                    _this._loadPodiumScript(branding.podium);
                }
                else if (branding.beacon) {
                    _this._loadBeaconScript(branding.beacon);
                }
            }
            if (typeof gtag === 'function') { // Check if gtag is defined
                gtag('config', branding.analytics || 'UA-138537135-2', { 'page_path': event.urlAfterRedirects });
            }
            else {
                console.error('gtag is not defined');
            }
        });
        if (!isRunningEmbedded()) {
            localStorage.setItem(STORAGE_CUSTOM_SCRIPT, branding.customScript);
        }
        _this._spawnPeriodicVersionChecking(1000 * 60 * 2);
        _this._spawnPeriodicDomainValidation(1000 * 60 * 1);
        return _this;
    }
    AppComponent.prototype._spawnPeriodicVersionChecking = function (freq) {
        var _this = this;
        // TODO: Note, there is a very improbable race condition that happens
        // if browserVersion is first fetched after the code was changed.
        // browserVersion should start from the local /assets/version.json
        var running = false;
        var browserVersion = null;
        var f = function () { return __awaiter(_this, void 0, void 0, function () {
            var remoteVersion;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, , 4, 5]);
                        if (running)
                            return [2 /*return*/];
                        running = true;
                        return [4 /*yield*/, this.httpClient.get('/assets/version.json', HEADERS_NO_AUTH).toPromise()];
                    case 1:
                        remoteVersion = (_a.sent()).version;
                        if (!(browserVersion && browserVersion !== remoteVersion)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.modalService.openAlertModal('New Version Available', 'A new version is available, please refresh the page to continue.', AlertType.WARNING, null, 'Reload Page')
                            // Reload without signout
                        ];
                    case 2:
                        _a.sent();
                        // Reload without signout
                        window.location.reload();
                        _a.label = 3;
                    case 3:
                        browserVersion = remoteVersion;
                        return [3 /*break*/, 5];
                    case 4:
                        running = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        setInterval(f, freq);
    };
    AppComponent.prototype._spawnPeriodicDomainValidation = function (freq) {
        var _this = this;
        var running = false;
        var f = function () { return __awaiter(_this, void 0, void 0, function () {
            var isShared, isExternalUrlGrade, s, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, 4, 5]);
                        if (running)
                            return [2 /*return*/];
                        running = true;
                        isShared = _.endsWith(this.router.url, 'shared');
                        isExternalUrlGrade = _.endsWith(this.router.url, 'grade');
                        if (isShared || isExternalUrlGrade)
                            return [2 /*return*/];
                        return [4 /*yield*/, this._sessionS.waitSession()];
                    case 1:
                        s = _a.sent();
                        if (s.sessionType !== 'NORMAL')
                            return [2 /*return*/];
                        return [4 /*yield*/, this._authS.logoutIfDomainValidationFails(s.user)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [3 /*break*/, 5];
                    case 4:
                        running = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        setInterval(f, freq);
    };
    AppComponent.prototype._loadPodiumScript = function (token) {
        var script = this.renderer.createElement('script');
        script['defer'] = true;
        script['type'] = 'text/javascript';
        script['src'] = "https://connect.podium.com/widget.js#API_TOKEN=" + token;
        script['id'] = "podium-widget";
        script['data-api-token'] = token;
        this.renderer.appendChild(this.document.body, script);
        return script;
    };
    AppComponent.prototype._loadBeaconScript = function (id) {
        var container = this.renderer.createElement('section');
        var script = this.renderer.createElement('script');
        script['type'] = 'text/javascript';
        script.innerText = function (e, t, n) { function a() { var e = t.getElementsByTagName("script")[0], n = t.createElement("script"); n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e); } if (e['Beacon'] = n = function (t, n, a) { e['Beacon'].readyQueue.push({ method: t, options: n, data: a }); }, n.readyQueue = [], "complete" === t.readyState)
            return a(); e['attachEvent'] ? e['attachEvent']("onload", a) : e.addEventListener("load", a, !1); }(window, document, window['Beacon'] || function () { });
        container.appendChild(script);
        var scriptId = this.renderer.createElement('script');
        scriptId['type'] = 'text/javascript';
        scriptId.innerText = window['Beacon']('init', id);
        container.appendChild(scriptId);
        this.renderer.appendChild(this.document.head, container);
        return container;
    };
    return AppComponent;
}(BaseComponent));
export { AppComponent };
