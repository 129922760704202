import { __awaiter, __extends, __generator } from "tslib";
// dep
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
// app
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';
import { ReviewsService } from '../../services/reviews.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
var WidgetComponent = /** @class */ (function (_super) {
    __extends(WidgetComponent, _super);
    function WidgetComponent(reviewS, _sessionS, route, router, _authS, locationS, cdf) {
        var _a;
        var _this = _super.call(this) || this;
        _this.reviewS = reviewS;
        _this._sessionS = _sessionS;
        _this.route = route;
        _this.router = router;
        _this._authS = _authS;
        _this.locationS = locationS;
        _this.cdf = cdf;
        _this.location = null;
        _this.reviewsProgress = true;
        _this._order = 'createTime';
        var session = _this._sessionS.hasSession() ? _this._sessionS.getSession() : null;
        var params = _this.route.snapshot.params;
        var queryParams = _this.route.snapshot.queryParams;
        if (session !== null) {
            params = _this.route.parent.snapshot.params;
            queryParams = _this.route.parent.snapshot.queryParams;
        }
        _this.locationId = params.locationId;
        _this.accountId = params.accountId;
        _this.gid = params.gid || ((_a = session) === null || _a === void 0 ? void 0 : _a.gid);
        _this.locations = [{ accountId: _this.accountId, locationId: _this.locationId }];
        var order = queryParams.order;
        if (order) {
            _this._order = order;
        }
        var starts = queryParams.filter;
        if (starts) {
            _this._starts = starts;
            var nStarts_1 = [];
            var selectedStarts = _this._starts.split(',');
            selectedStarts.forEach(function (item) {
                nStarts_1.push(parseInt(item, 10));
            });
            _this.selectedStarts = nStarts_1;
        }
        var backColor = queryParams.back_color;
        if (backColor) {
            _this.backgroundColor = '#' + backColor;
        }
        var btnColor = queryParams.btn_color;
        if (btnColor) {
            _this.buttonColor = '#' + btnColor;
        }
        var btnTextColor = queryParams.btn_txt_color;
        if (btnTextColor) {
            _this.buttonTextColor = '#' + btnTextColor;
        }
        var textColorAll = queryParams.text_color;
        if (textColorAll) {
            _this.textColor = '#' + textColorAll;
        }
        return _this;
    }
    Object.defineProperty(WidgetComponent.prototype, "starts", {
        get: function () {
            return this.startsI;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WidgetComponent.prototype, "order", {
        get: function () {
            var _a;
            return (_a = this.orderI) === null || _a === void 0 ? void 0 : _a.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WidgetComponent.prototype, "responses", {
        get: function () {
            var _a;
            return (_a = this.responsesI) === null || _a === void 0 ? void 0 : _a.value;
        },
        enumerable: true,
        configurable: true
    });
    WidgetComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.cdf.detectChanges();
                        if (!!this.router.url.includes('location')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._authS.signInAnonymously(this.gid)];
                    case 1:
                        _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this._subscribeSafe(this.events, function () { return _this._filter(); });
                        _b.label = 3;
                    case 3:
                        this.locationS.review_summary(this.gid, this.locations).subscribe(function (resume) {
                            var _a, _b;
                            _this.average = (_a = resume) === null || _a === void 0 ? void 0 : _a.averageRating;
                            _this.total = (_b = resume) === null || _b === void 0 ? void 0 : _b.totalReviewCount;
                        });
                        this._filter();
                        _a = this;
                        return [4 /*yield*/, this.locationS.fetchLocation(this.gid, this.accountId, this.locationId)];
                    case 4:
                        _a.location = _b.sent();
                        this.reviewsProgress = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    WidgetComponent.prototype._filter = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var sort, reviews, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        this.reviewsProgress = true;
                        sort = {
                            'direction': null,
                            'sortBy': this.order
                        };
                        return [4 /*yield*/, this.reviewS.filter(this.locations, 1, null, this.selectedStarts, null, null, null, null, sort, this.gid).toPromise()];
                    case 1:
                        reviews = _b.sent();
                        this.reviews = (_a = reviews) === null || _a === void 0 ? void 0 : _a.items;
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _b.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 3:
                        this.reviewsProgress = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WidgetComponent.prototype.ngOnChanges = function (changes) {
        if (changes.backColor) {
            this.backgroundColor = changes.backColor.currentValue;
        }
        if (changes.btnColor) {
            this.buttonColor = changes.btnColor.currentValue;
        }
        if (changes.btnTextColor) {
            this.buttonTextColor = changes.btnTextColor.currentValue;
        }
        if (changes.textColorAll) {
            this.textColor = changes.textColorAll.currentValue;
        }
        if (changes.orderI) {
            this._order = changes.orderI.currentValue;
        }
        if (changes.responsesI) {
            this._responses = changes.responsesI.currentValue;
        }
        if (changes.startsI) {
            this._starts = changes.startsI.currentValue;
            if (this._starts)
                this.selectedStarts = this._starts.split(',').map(function (item) { return parseInt(item, 10); });
        }
    };
    return WidgetComponent;
}(BaseComponent));
export { WidgetComponent };
