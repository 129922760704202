/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
Payment Method Component
 
Used for:
  - Displaying the default Payment Method (already saved)
  - Entering a new Payment Method for future offline/online usages.
  - Changing the default Payment Method.
  - Removing a Payment Method.
  - Making a Synchronous Payment by entering a new Payment Method o reusing a
    saved one. If new, it will be saved and set as the default one.
  - As it uses the Stripe Payment Element, external authorizations
    flows like the one used on like 3D Secure are also managed in-session.

TODO: This component is currently limited to Credit Cards but can be easily
extended to other payment methods when required.

Docs:
  - https://docs.stripe.com/payments/payment-element
  - https://docs.stripe.com/payments/payment-card-element-comparison
  - https://docs.stripe.com/payments/payment-element/design-an-integration
  - https://docs.stripe.com/payments/save-customer-payment-methods
  - https://docs.stripe.com/payments/build-a-two-step-confirmation#save-payment-methods
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#additional-options
  - https://docs.stripe.com/payments/payment-element/migration-ct#conditional-options
  - https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements#web-create-payment-intent
  - https://docs.stripe.com/payments/accept-a-payment-deferred
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=setup
  - https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#additional-options
  - Test
    - https://docs.stripe.com/payments/payment-element/migration?integration-path=future#test-the-integration

Implementation Notes / Design constraints:

- The Stripe Customer will be already created, even when the Maplabs subscription is
  still on the TRIAL stage. No need to create it at this point.

- The only way to make a Payment Element display a saved PM (or more) is to create a
  Customer Session. It doesn't work to create a Setup/Payment Intent that specify a
  `payment_method` and associate it to the Payment Element. It doesn't work either to
  specify the `readOnly=true` Payment Element flag, as that flag is only intended
  to prevent further user interaction with the P.E., probably meant to be set to `true`
  not on P.E. creation but by using a payel.update({readOnly : true}) method triggered by
  some post-creation UI event.

- PaymentIntents and SetupIntents doesn't have a clear expiration date, but is documented
  that they can become invalid with time. In constrast, the Customer Session has a
  clear expiration date for his clientSecret, and there is no need to delete them
  (also, there is no endpoint on Stripe to do that). Note that Payment/Setup Intents
  have an 'id' identity, but Customer Session doesn't, so it's like a singleton with
  a rolling secret.
  
- There is no specific Stripe Element do display the Payment Methods in a readonly
  way without a CustomerSession or Intent, all documentation point that you should
  fetch the payment methods list and use custom components to show them.

- Is possible to use set both the Payment/Setup Intent `clientSecret` and the
  CustomerSession `customerSessionClientSecret` on a Payment Element (docs are
  not clear on this point).

- We can trigger synchronous payments using a saved Payment Method from the backend
  without using a Payment Element, but is preferable to trigger it from the
  Payment Element to handle external authorizations like the 3D Secure flow on
  an in-session way. This forces us to use Customer Session to display the saved
  PM.

- Using a Customer Session, is possible to show a "delete" icon-button on the right
  of every saved PM, but the Payment Element doesn't give us a way to intercept that
  action. So we need a custom button to trigger it ourselves.

- The UI tweaks that can be done on a Payment Element is limited, this is related
  to the fact that the Payment Element is a Stripe-hosted iframe, an
  additional security layer by Stripe.
                                
TODO:
  - This component is not related to the skeleton-payment-method one, that
     component should be removed.
  - The update-card-component should also be removed after is fully replaced
     by this component.
  - Remove the 'as any' casting after the stripe-js typings lib is upgraded.


 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
var styles = [""];
export { styles as styles };
