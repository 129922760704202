// dep
import {Injectable, isDevMode} from '@angular/core';
import { HttpClient } from '@angular/common/http'
import {AngularFirestore} from "@angular/fire/firestore";

// app
import {environment as ENV} from "@environment";
import {DOMAINS} from "../constants/firestore/collections";
import { HEADERS_NO_AUTH } from '../constants/auth';
import { Domain } from '../constants/firestore/domain';
import { BROWSER_DOMAIN } from '../helpers/utils.helpers'

@Injectable({
  providedIn: 'root'
})

/**
 * This service fetchs the Domain info
 */
export class DomainService {
  constructor(
    private _afs  : AngularFirestore,
    private _http : HttpClient,
    ) {}  

  private async _fetchDomainFromFirestore(xdomain : string) : Promise<Domain> {
    return (await this._afs.collection(DOMAINS).doc(xdomain).get().toPromise()).data() as Domain
  }

  async fetchCurrentDomain() : Promise<Domain> {
    // TODO: Should be coalesced to the coreApi endpoint when ditching Firestore
    // => better yet, coalesce to a single session endpoint, see session.service TODO comments
    // for more info.

    // Fetch from Backend->Mongo
    const domainBackP = this._http.get<any>(`${ENV.coreApiUrl}/domains/${BROWSER_DOMAIN.domainName}`, 
                                            HEADERS_NO_AUTH).toPromise();

    // Fetch from Firestore (TODO: Remove)
    const domainFireP = this._fetchDomainFromFirestore(BROWSER_DOMAIN.xDomainName)

    // Fetch the public key used to create add-credit-card tokens
    // - for Dev mode, an Stripe Test public key will be used (stored on 'localhost')
    // - for Prod mode, always uses the Maplabs PK, no matter the WL domain, 
    //   because billing-api makes requests always using the 
    //   Mplabs Secret Stripe key, not a WL Secret Stripe key (we shouldn't 
    //   have those)
    const pkeyDomainName  = isDevMode() ? 'localhost' : 'app_maplabs_com';
    const pkeyDomainFireP = (pkeyDomainName === BROWSER_DOMAIN.xDomainName ?
                             domainFireP :
                             this._fetchDomainFromFirestore(pkeyDomainName));

    let domain = { trialDays : 30 }
    try {
      domain = (await domainBackP).data
    } catch(e) {
      // Can fail because not all domains are yet in Mongo, pass
      // Remove this try/catch after they are migrated from FS
    }

    return {...(await domainFireP),
            ...domain,
            stripe_keys : { public_key : (await pkeyDomainFireP).stripe_keys.public_key }
          }
  }

}
