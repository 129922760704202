import { __awaiter, __generator } from "tslib";
import { HttpClient } from '@angular/common/http';
// app
import { environment as ENV } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var URL_CARD = function (gid, cardId) {
    return ENV.billingApiUrl + "/card/by-gid/" + gid + (cardId ? '/' + cardId : '');
};
var ɵ0 = URL_CARD;
var URL_PM = function (gid, suffix) {
    if (suffix === void 0) { suffix = ''; }
    return ENV.billingApiUrl + "/card/payment-methods/" + gid + suffix;
};
var ɵ1 = URL_PM;
var PaymentsService = /** @class */ (function () {
    function PaymentsService(http) {
        this.http = http;
    }
    PaymentsService.prototype.addNewCard = function (gid, token) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(URL_CARD(gid), { source: token }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PaymentsService.prototype.deleteCard = function (gid, cardId) {
        return this.http.delete(URL_CARD(gid, cardId)).toPromise();
    };
    PaymentsService.prototype.getCards = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(URL_CARD(gid)).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    PaymentsService.prototype.hasPaymentMethods = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getCards(gid)];
                    case 1: return [2 /*return*/, !!(_b.sent()).length];
                    case 2:
                        _a = _b.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: Change to "fetchPlan" and return a promise, not Observable (is single-shot)
    PaymentsService.prototype.getPlan = function (planId) {
        return this.http.get(ENV.billingApiUrl + "/product/" + planId.toLowerCase());
    };
    PaymentsService.prototype.setDefaultSource = function (gid, cardId) {
        return this.http.post(URL_CARD(gid, cardId) + '/default', {}).toPromise();
    };
    //--------------------------------------------------------------------------
    // New Payment Methods interface 
    //--------------------------------------------------------------------------
    //
    // TODO: Remove the old one after we migrated to the new PaymentMethodComponent for all PM operations
    //
    PaymentsService.prototype.fetchPaymentMethodDefault = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.get(URL_PM(gid, '/default')).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    /**
     * Ask the Backend to use his Stripe Secret key to create a new Customer Session on
     * Stripe.
     */
    PaymentsService.prototype.startCustomerSession = function (gid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(URL_PM(gid, '/customer-session'), {}).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    /**
     * Asks the backend to request Stripe to create a Setup Intent using a Confirmation Token
     */
    PaymentsService.prototype.createSetupIntent = function (gid, confirmationTokenId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(URL_PM(gid, '/setup'), { confirmationTokenId: confirmationTokenId }).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    /**
     * Deletes a Payment Method
     */
    PaymentsService.prototype.deletePaymentMethod = function (gid, paymentMethodId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.delete(URL_PM(gid, '/' + paymentMethodId)).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Sets a Payment Method as the default one
     */
    PaymentsService.prototype.setPaymentMethodDefault = function (gid, paymentMethodId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(URL_PM(gid, '/default'), { paymentMethodId: paymentMethodId }).toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Asks the backend to create the First Payment Invoice and and his Payment Intent on Stripe,
     * for the given initial list of Packages, using a Confirmation Token.
     */
    PaymentsService.prototype.startFirstPayment = function (gid, confirmationTokenId, initialPackages) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(URL_PM(gid, '/first-payment'), { confirmationTokenId: confirmationTokenId,
                            initialPackages: initialPackages,
                        }).toPromise()];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    };
    PaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentsService, providedIn: "root" });
    return PaymentsService;
}());
export { PaymentsService };
export { ɵ0, ɵ1 };
