<ng-container *ngIf="!darkLaunch">
    <div class="dialog__header">
        <h2 mat-dialog-title class="txt--xl m--0"><strong>{{data.title}}</strong></h2>
    </div>
    <div class="dialog__content" mat-dialog-content>
        <div>
            <p class="txt--lg">Businesses that use Map Labs:</p>
            <div class="row">
                <div class="col-1 dialog__content__icon">
                    <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="col-11">
                    <p class="txt--lg">
                        <strong>Get more customers - </strong>
                        1 billion active monthly Google Maps users are ready to choose your business over the competition.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-1 dialog__content__icon">
                    <i class="fas fa-chart-bar"></i>
                </div>
                <div class="col-11">
                    <p class="txt--lg">
                        <strong>Simplify reporting -  </strong>
                        Easily view your Google Business Profile performance. Straightforward, real-world results only.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-1 dialog__content__icon">
                    <i class="fas fa-business-time"></i>
                </div>
                <div class="col-11">
                    <p class="txt--lg">
                        <strong>Save time - </strong>
                        Google Maps' native platform is difficult to use. Optimize hundreds of business listings with Map Labs!
                    </p>
                </div>
            </div>
        </div>
        <div>
            <div class="dialog__content__green">
                <p class="txt--lg m--0">{{data.message}}</p>
                    <!-- <div class="row">
                        <div class="col-6">
                            <div class="w--100"></div>
                            <span>{{locations_qty}}</span>
                            <span>profiles</span>
                        </div>
                        <div class="col-6">
                            <strong>${{price}}/mo</strong>
                            <span>per location</span>
                        </div>
                    </div> -->
            </div>
        </div>    
    </div>
    <div mat-dialog-actions class="dialog__footer m--0">
        <span class="alternate-theme d-inline-block mr--10">
            <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel" (click)="close()" >{{data.buttons['cancel_text']}}</button>
        </span>
        <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--action" (click)="accept_cta()" ><i [ngClass]="data.buttons['accept_icon']"></i>{{data.buttons['accept_text']}}</button>
    </div>
</ng-container>

<!-- Andy decided to keep the old style for non-darkLaunch users for now -->
<ng-container *ngIf="darkLaunch">
    <ng-container *ngIf="paywallStep">
        <div class="dialog__header dl__header">
            <h2 mat-dialog-title class="txt--lg m--0"><strong>{{data.title}}</strong></h2>
        </div>
        <div class="dialog__content dl__content" mat-dialog-content>
            <div>
                <p class="txt--lg fw--black">Upgrade your account to continue accessing Map Labs!</p>
                <p class="txt--lg">Businesses that use Map Labs:</p>
                <div class="row">
                    <div class="col-1 dialog__content__icon dl__icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="col-11">
                        <p class="txt--md">
                            <strong>Get more customers - </strong>
                            1 billion active monthly Google Maps users are ready to choose your business over the competition.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1 dialog__content__icon dl__icon">
                        <i class="fas fa-chart-bar"></i>
                    </div>
                    <div class="col-11">
                        <p class="txt--md">
                            <strong>Simplify reporting -  </strong>
                            Easily view your Google Business Profile performance. Straightforward, real-world results only.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-1 dialog__content__icon dl__icon">
                        <i class="fas fa-business-time"></i>
                    </div>
                    <div class="col-11">
                        <p class="txt--md">
                            <strong>Save time - </strong>
                            Save 5-10 hours per Google Business Profile every month with Map Labs' proprietary industry-leading software.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <div class="dialog__content__green">
                    <!-- the following text needs to be replaced with {{ data.message }} updating it in paywall.service, once darklaunch is removed -->
                    <p class="txt--lg m--0">Simple monthly pricing, based on how many Google Business Profiles you have.</p>
                        <div class="row d-flex flex-gap-10 mb--10 p--20">
                            <div class="col-6 d-flex flex-column flex-1 txt--black justify-content-center align-items-center bg--white h--100">
                                    <span class="fw--black txt--lg">{{locations_qty}}</span>
                                    <span>profiles</span>
                            </div>
                            <div class="col-6 d-flex flex-column flex-1 txt--black justify-content-center align-items-center bg--white h--100">
                                <strong class="fw--black txt--lg">${{totalPrice}}/mo</strong>
                                <span>${{unitPrice}} per profile</span>
                            </div>
                        </div>
                        <div>
                            <span class="disclaimer-pricing">Pricing is in USD, billed monthly. You can cancel anytime.</span>
                        </div>
                </div>
            </div>    
        </div>
        <div mat-dialog-actions class="dialog__footer mt--30">
            <span class="alternate-theme d-inline-block mr--10">
                <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel" (click)="close()" >{{data.buttons['cancel_text']}}</button>
            </span>
            <compass-button (clicked)="moveToPayment()" [color]="'primary-blue'" [text]="'Continue to Payment'"></compass-button>
        </div>

    </ng-container>
    <app-manage-plan 
        *ngIf="paymentStep"
        (onClose)="close($event)"
        (onClosePlanBilling)="onClosePlanBilling($event)"
        [manageSubscription]="true" 
        [title]="data.title"
        [cancelText]="'Cancel and Logout'"
        [confirmText]="'Pay Now'"
        >
    </app-manage-plan>
</ng-container>