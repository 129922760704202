import { of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse, Pagination2 } from '../constants/api-response';
import { environment } from '@environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { Queue } from '../constants/firestore/enqueue';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./snackbar.service";
var PostService = /** @class */ (function () {
    function PostService(_http, _snack) {
        this._http = _http;
        this._snack = _snack;
        this.loading = new BehaviorSubject(false);
        // postEndPoint = `${environment.apiUrlV3}/posts`;
        this.bulkPostEndPoint = environment.apiUrlV3 + "/bulk_posts";
        this.localPostEndPoint = environment.apiUrlV3 + "/posts";
        this.responsePosts = new BehaviorSubject(null);
        this.posts$ = this.responsePosts.asObservable();
    }
    PostService.prototype.isLoading = function (state) {
        this.loading.next(state);
    };
    // TODO: Unused, remove. 
    //   getPosts(placeId, pageable: Pageable, sort?: string, direction?: string, postType?: string): Observable<Pagination> {
    //     let params = new HttpParams();
    //     if (pageable) {
    //       params = params.append('page', pageable.page.toString());
    //       params = params.append('size', pageable.size.toString());
    //     }
    //     if (postType) params = params.append('post_type', postType);
    // 
    //     if (sort) {
    //       params = params.append('sort', sort);
    //     }
    // 
    //     if (direction) {
    //       params = params.append('direction', direction);
    //     }
    // 
    //     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/${placeId}`, {params})
    //       .pipe(
    //         map(response => response.data as Pagination || null)
    //       );
    //   }
    // TODO: Not used, remove?
    //
    //   getWithPostManagement(pageable: Pageable, sort?: string, direction?: string) {
    //     let params = new HttpParams();
    //     if (pageable) {
    //       params = params.append('page', pageable.page.toString());
    //       params = params.append('size', pageable.size.toString());
    //     }
    //     if (sort) {
    //       params = params.append('sort', sort);
    //     }
    // 
    //     if (direction) {
    //       params = params.append('direction', direction);
    //     }
    // 
    //     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement`, {params})
    //       .pipe(
    //         map(response => response.data as Pagination || null)
    //       );
    //   }
    // 
    //   getWithPostManagementByType(pageable: Pageable, type: string, sort?: string, direction?: string) {
    //     let params = new HttpParams();
    //     if (pageable) {
    //       params = params.append('page', pageable.page.toString());
    //       params = params.append('size', pageable.size.toString());
    //     }
    // 
    //     if (sort) params = params.append('sort', sort);
    // 
    //     if (direction) params = params.append('direction', direction);
    // 
    //     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/${type}`, {params })
    //       .pipe(
    //         map(response => response.data as Pagination || null)
    //       );
    //   }
    // update(accountId, locationId, postId, data: Post) {
    //   if (locationId) {
    //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}/${postId}`, data);
    //   } else {
    //     postId = _.get(postId, '$oid', postId);
    //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/${postId}`, data);
    //   }
    // }
    PostService.prototype.validate_placeholders = function (accounts, summary) {
        return this._http.post(environment.apiUrl + "/v2/posts/postManagement/filter_summary", { accounts: accounts, summary: summary })
            .pipe(map(function (response) { return response.data || null; }));
    };
    //   delete(accountId, locationId, postId, isEdit= false) {
    //     if (locationId) {
    //       return this._http.delete<ApiResponse>(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}/${postId}`);
    //     } else {
    //       const headers = new HttpHeaders({'x-delete' : String(isEdit)})
    // 
    //       postId = _.get(postId, '$oid', postId);
    //       return this._http.delete<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/${postId}`, {headers});
    //     }
    //   }
    // TODO: Not used, remove?
    //
    // scheduledDelete(accountId: string, locationId: string, postId: string, schedule: SchedulePost): Observable<ApiResponse> {
    //   let params = new HttpParams();
    // 
    //   if (schedule) {
    //     params = params.append('date', schedule.date.toString());
    //     params = params.append('time', schedule.time);
    //   }
    // 
    //   if (locationId) {
    //     return this._http.delete<ApiResponse>(
    //       `${environment.apiUrl}/v2/posts/scheduled/${accountId}/${locationId}/${postId}`,
    //       {params}
    //     );
    //   } else {
    //     postId = _.get(postId, '$oid', postId);
    //     return this._http.delete<ApiResponse>(
    //       `${environment.apiUrl}/v2/posts/postManagement/scheduled/${postId}`,
    //       {params}
    //     );
    //   }
    // }
    //
    //
    // cancelScheduledDelete( locationId, postId) {
    //   if (locationId) {
    //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/scheduled/${postId}`, {});
    //   } else {
    //     postId = _.get(postId, '$oid', postId);
    //     return this._http.put<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement/scheduled/${postId}`, {});
    //   }
    // }
    //
    // save(accountId, locationId, post: any) {
    //   if (locationId) {
    //     return this._http.post<ApiResponse>(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}`, post);
    //   } else {
    //     return this._http.post<ApiResponse>(`${environment.apiUrl}/v2/posts/postManagement`, post);
    //   }
    // }
    //
    //
    // getPendingPost(pageable: Pageable, gid, accountId, locationId: string, next, last) {
    //   return this.fs.paginate<Protocol>(`${GROUPS}/${gid}/${ACCOUNTS}/${accountId}/${LOCATIONS}/${locationId}/${POST_MANAGEMENT_LOCATION}`,
    //     'status', ref => ref.where('status', 'in', ['PENDING', 'DRAFT', 'SCHEDULE']),
    //     {
    //       size: pageable.size,
    //       page: pageable.page
    //     }, false, next, last);
    // }
    // 
    // saveAll(accountId: string, locationId: any, enqueue: any) {
    //   let params = new HttpParams();
    //   if (enqueue && environment.queuesEnabled) {
    //     params = params.append('enqueue', enqueue);
    //   }
    // 
    //   return this._http.post(`${environment.apiUrl}/v2/posts/${accountId}/${locationId}/all`, {}, {params});
    // }
    PostService.prototype.saveV3All = function (gid, accountId, locationId, enqueue) {
        if (enqueue === void 0) { enqueue = Queue.COMBINED_EXPRESS; }
        var params = new HttpParams();
        if (environment.queuesEnabled) {
            params = params.append('enqueue', enqueue); // TODO: Ignored by backend, remove all 'enqueue' parameters
        }
        return this._http.post(this.localPostEndPoint + "/group/" + gid + "/account/" + accountId + "/location/" + locationId + "/google", {}, { params: params }).toPromise();
    };
    PostService.prototype.purgeGmbDeleted = function (gid, accountId, locationId) {
        return this._http.post(this.localPostEndPoint + "/purge/" + gid + "/" + accountId + "/" + locationId, {}).toPromise();
    };
    // TODO: Not used, remove??
    //
    //   getScheduledPosts(pageable: Pageable, sort?: string, direction?: string): Observable<Pagination> {
    //     let params = new HttpParams();
    //     if (pageable) {
    //       params = params.append('page', pageable.page.toString());
    //       params = params.append('size', pageable.size.toString());
    //     }
    // 
    //     if (sort) {
    //       params = params.append('sort', sort);
    //     }
    // 
    //     if (direction) {
    //       params = params.append('direction', direction);
    //     }
    // 
    //     return this._http.get<ApiResponse>(`${environment.apiUrl}/v2/posts/pending`, {params})
    //       .pipe(
    //         map(response => response.data as Pagination || null)
    //       );
    //   }
    PostService.prototype.transformImage = function (path) {
        var data = { path: path };
        return this._http.post(environment.apiUrl + "/v2/posts/image", data);
    };
    // TODO: Not used, remove?
    // getCountersPosts(posts: any[]) {
    //   return this._http.post<any[]>(`${environment.apiUrl}/v2/posts/getCounters`, { posts });
    // }
    /**
     * Endpoints v3
     */
    PostService.prototype.getLocalPost = function (accountId, locationId, status, pageable, sort, direction) {
        var _this = this;
        var params = new HttpParams();
        if (pageable) {
            params = params.append('page', pageable.page.toString());
            params = params.append('size', pageable.size.toString());
        }
        if (sort) {
            params = params.append('sort', sort);
        }
        if (direction) {
            params = params.append('direction', direction);
        }
        return this._http.get(this.localPostEndPoint + "/account/" + accountId + "/location/" + locationId + "/" + status, { params: params }).pipe(map(function (response) {
            if (response.status) {
                return response.data;
            }
            else {
                _this._snack.openError(response.message, 1500);
                return null;
            }
        })).toPromise();
    };
    PostService.prototype.getBulkPost = function (status, pageable) {
        var _this = this;
        var params = new HttpParams();
        if (pageable) {
            params = params.append('page', pageable.page.toString());
            params = params.append('size', pageable.size.toString());
        }
        return this._http.get(this.bulkPostEndPoint + "/" + status, { params: params }).pipe(map(function (response) {
            if (response.status) {
                return response.data;
            }
            else {
                _this._snack.openError(response.message, 1500);
                return null;
            }
        })).toPromise();
    };
    PostService.prototype.getBulkCounters = function (bulkPostId) {
        return this._http.get(this.bulkPostEndPoint + "/" + bulkPostId + "/get_counters").pipe(map(function (response) { return response.status ? response.data : null; })).toPromise();
    };
    PostService.prototype.getBulkLocationPost = function (bulkPostId, onlyErrors) {
        var _this = this;
        if (onlyErrors === void 0) { onlyErrors = 'true'; }
        var params = new HttpParams();
        params = params.append('onlyErrors', onlyErrors);
        return this._http.get(this.bulkPostEndPoint + "/" + bulkPostId + "/location_post", { params: params }).pipe(map(function (response) {
            if (response.status) {
                return response.data;
            }
            else {
                _this._snack.openError(response.message, 1500);
                return null;
            }
        })).toPromise();
    };
    PostService.prototype.saveV3 = function (accountId, locationId, post, type, tab, publishNow) {
        var _this = this;
        if (publishNow === void 0) { publishNow = false; }
        if (type === 'LOCAL') {
            return this._http.post(this.localPostEndPoint + "/account/" + accountId + "/location/" + locationId, { post: post, publishNow: publishNow }).pipe(map(function (response) {
                if (response.status) {
                    _this.responsePosts.next({ response: response.data, type: type, tab: tab });
                    return response.data;
                }
                else {
                    if (response.data)
                        _this._snack.openWarning(response.message, 10000);
                    else
                        _this._snack.openError(response.message, 1500);
                    return null;
                }
            })).toPromise();
        }
        else {
            setTimeout(function () {
                _this.responsePosts.next({ response: null, type: type, tab: tab });
            }, 1500);
            return this._http.post(this.bulkPostEndPoint + "/", { post: post, publishNow: publishNow }).pipe(map(function (response) {
                if (response.status) {
                    _this.responsePosts.next({ response: response.data, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 1500);
                    return null;
                }
            })).toPromise();
        }
    };
    PostService.prototype.deleteV3 = function (postId, type, tab) {
        var _this = this;
        if (type === 'LOCAL') {
            return this._http.delete(this.localPostEndPoint + "/" + postId).pipe(map(function (response) {
                if (response.status) {
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 1500);
                    return null;
                }
            })).toPromise();
        }
        else {
            return this._http.delete(this.bulkPostEndPoint + "/" + postId).pipe(map(function (response) {
                if (response.status) {
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 1500);
                    return null;
                }
            })).toPromise();
        }
    };
    PostService.prototype.updateV3 = function (postId, post, type, tab, publishNow) {
        var _this = this;
        if (publishNow === void 0) { publishNow = false; }
        if (type === 'LOCAL') {
            return this._http.put(this.localPostEndPoint + "/" + postId, { post: post, publishNow: publishNow }).pipe(map(function (response) {
                if (response.status) {
                    _this.responsePosts.next({ response: response.data, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 1500);
                    return null;
                }
            })).toPromise();
        }
        else {
            return this._http.put(this.bulkPostEndPoint + "/" + postId, { post: post, publishNow: publishNow }).pipe(map(function (response) {
                if (response.status) {
                    _this.responsePosts.next({ response: response.data, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 1500);
                    return null;
                }
            })).toPromise();
        }
    };
    PostService.prototype.retry = function (postId, type, tab) {
        var _this = this;
        if (type === 'LOCAL') {
            return this._http.post(this.localPostEndPoint + "/retry/" + postId, {}).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess('The post has been retried!', 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 3500);
                    return null;
                }
            })).toPromise();
        }
        else {
            return this._http.post(this.bulkPostEndPoint + "/retry/" + postId, {}).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess('The bulk post has been retried!', 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 3500);
                    return null;
                }
            })).toPromise();
        }
    };
    PostService.prototype.retryAll = function (postId, type, tab) {
        var _this = this;
        if (type !== 'LOCAL') {
            return this._http.post(this.bulkPostEndPoint + "/retry/all/" + postId, {}).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess('The bulk post has been retried!', 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 3500);
                    return null;
                }
            })).toPromise();
        }
    };
    PostService.prototype.copy = function (postId, type, tab) {
        var _this = this;
        if (type === 'LOCAL') {
            return this._http.get(this.localPostEndPoint + "/copy/" + postId).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess('The post has been copied!', 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 3500);
                    return null;
                }
            })).toPromise();
        }
        else {
            return this._http.get(this.bulkPostEndPoint + "/copy/" + postId).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess('The bulk post has been copied!', 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 3500);
                    return null;
                }
            })).toPromise();
        }
    };
    PostService.prototype.scheduledDeleteV3 = function (postId, schedule, cancel, type, tab) {
        var _this = this;
        if (cancel === void 0) { cancel = false; }
        if (type === 'LOCAL') {
            return this._http.post(this.localPostEndPoint + "/delete/scheduled/" + postId, { schedule: schedule, cancel: cancel }).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess(response.message, 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 2500);
                    return null;
                }
            })).toPromise();
        }
        else {
            return this._http.post(this.bulkPostEndPoint + "/delete/scheduled/" + postId, { schedule: schedule, cancel: cancel }).pipe(map(function (response) {
                if (response.status) {
                    _this._snack.openSuccess(response.message, 3500);
                    _this.responsePosts.next({ response: null, type: type, tab: tab });
                    return response.data;
                }
                else {
                    _this._snack.openError(response.message, 3500);
                    return null;
                }
            })).toPromise();
        }
    };
    // TODO: Unused, remove?
    // clearErrorsV3(postId: string, type: 'BULK' | 'LOCAL'): Promise<LocalPost | BulkPost> {
    //   if (type === 'LOCAL') {
    //    return this._http.get<ResponseModel<LocalPost>>(
    //      `${this.localPostEndPoint}/clear_errors/${postId}`,
    //      ).pipe(
    //     map( response => {
    //       if (response.status) {
    //         this._snack.openSuccess('Clear errors!', 3500);
    //         const tab = 'Pending';
    //         this.responsePosts.next({response: null, type, tab});
    //         return response.data;
    //       } else {
    //         this._snack.openError(response.message, 2500);
    //         return null;
    //       }
    //     })
    //   ).toPromise();
    //   } else {
    //     // TODO: Bug? POST body is auth headers
    //     // TODO: Route not implemented?
    //     return this._http.post<ResponseModel<BulkPost>>(
    //       `${this.bulkPostEndPoint}/clear_errors/${postId}`,
    //       {headers: this.auth.headers.headers}).pipe(
    //         map( response => {
    //           if (response.status) {
    //             this._snack.openSuccess('The scheduled delete has been set!', 3500);
    //             const tab = 'Pending';
    //             this.responsePosts.next({response: null, type, tab});
    //             return response.data;
    //           } else {
    //             this._snack.openError(response.message, 3500);
    //             return null;
    //           }
    //         })
    //       ).toPromise();
    //   }
    // }
    // NEW POST
    PostService.prototype.getPostList = function (gid, isBulk, pagination, sort, status, occurence, accountId, locationId) {
        if (isBulk === void 0) { isBulk = true; }
        var _a, _b, _c, _d, _e, _f;
        var response;
        var data = {
            "gids": [gid],
            "filter": {
                "status": (_a = status) === null || _a === void 0 ? void 0 : _a.status,
                "bulkStatus": (_b = status) === null || _b === void 0 ? void 0 : _b.bulkStatus,
                "cadence": {
                    "isRecurring": occurence
                }
            },
            "pageSize": (_c = pagination) === null || _c === void 0 ? void 0 : _c.size,
            "page": (_d = pagination) === null || _d === void 0 ? void 0 : _d.page,
            "sortKey": (_e = sort) === null || _e === void 0 ? void 0 : _e.sortBy,
            "reverse": ((_f = sort) === null || _f === void 0 ? void 0 : _f.direction) == 'ASC' ? false : true
        };
        if (isBulk) {
            response = this._http.post(environment.coreApiUrl + "/posts/bulk", data);
        }
        else {
            data['accountIds'] = [accountId];
            data['locationIds'] = [locationId];
            response = this._http.post(environment.coreApiUrl + "/posts/location-view", data);
        }
        return response;
    };
    PostService.prototype.createPost = function (data) {
        return this._http.post(environment.coreApiUrl + "/posts", data);
    };
    PostService.prototype.editPost = function (data, postId) {
        return this._http.put(environment.coreApiUrl + "/posts/" + postId, data);
    };
    PostService.prototype.deletePost = function (postIds, date, time, deleteWholeSeries) {
        var data = {
            "post_ids": postIds,
            "date": date,
            "time": time,
            "deleteWholeSeries": deleteWholeSeries
        };
        return this._http.request('delete', environment.coreApiUrl + "/posts/bulk-delete", {
            body: data
        });
    };
    PostService.prototype.deleteLocationsFromPost = function (postId, locationsId, date, time) {
        var data = {
            "post_ids": locationsId,
            "date": date,
            "time": time,
            "deleteWholeSeries": false
        };
        return this._http.request('delete', environment.coreApiUrl + "/posts/" + postId + "/locations/bulk-delete", {
            body: data
        });
    };
    PostService.prototype.getPost = function (postId) {
        return this._http.get(environment.coreApiUrl + "/posts/" + postId);
    };
    PostService.prototype.copyPost = function (post, imageUrl) {
        // old functionality
        // return this._http.get(`${environment.coreApiUrl}/posts/${post._id}/copy`);
        if (imageUrl === void 0) { imageUrl = ''; }
        // new functionality
        return this._http.post(environment.coreApiUrl + "/posts/copy", { postId: post._id, sourceUrl: imageUrl });
    };
    PostService.prototype.storageImgInFirestore = function (element) {
        var _a, _b;
        // filename should be the name that comes from the URL of the image, usually at the last part of the url
        if ((_b = (_a = element.post) === null || _a === void 0 ? void 0 : _a.media[0]) === null || _b === void 0 ? void 0 : _b.sourceUrl) {
            var imageUrl = element.post.media[0].sourceUrl;
            var fileName = element._id + ".png";
            return this._fetchImageAsFile(imageUrl, fileName);
        }
        else {
            return of(null);
        }
    };
    PostService.prototype._fetchImageAsFile = function (imageUrl, fileName) {
        return new Observable(function (observer) {
            var img = new Image();
            img.crossOrigin = 'Anonymous'; // This is necessary according to Chat GPT for CORS
            img.onload = function () {
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob(function (blob) {
                        if (blob) {
                            var file = new File([blob], fileName, { type: blob.type });
                            observer.next(file);
                            observer.complete();
                        }
                        else {
                            observer.error('Canvas to Blob conversion failed');
                        }
                    });
                }
                else {
                    observer.error('Failed to get 2D context from canvas');
                }
            };
            img.onerror = function (error) {
                observer.error('Image loading failed');
            };
            img.src = imageUrl;
        });
    };
    PostService.prototype.getStatusPost = function (postId) {
        return this._http.get(environment.coreApiUrl + "/posts/" + postId + "/locations");
    };
    PostService.prototype.getInfoRejected = function (postId, instanceId) {
        return this._http.get(environment.coreApiUrl + "/posts/" + postId + "/locations/" + instanceId);
    };
    PostService.prototype.getActionLinks = function (accounts) {
        var data = {
            accounts: accounts
        };
        return this._http.post(environment.coreApiUrl + "/posts/url-attributes", data);
    };
    PostService.prototype.getSchedulePreview = function (data) {
        return this._http.post(environment.coreApiUrl + "/posts/dates", data);
    };
    PostService.prototype.retryAllPosts = function (statusData, callback) {
        var _a;
        if (!statusData || !statusData.length) {
            this._snack.openError('Invalid post instance ID');
            return;
        }
        var postInstanceIds = statusData.map(function (item) { return item.postInstanceId; }).filter(function (id) { return id; });
        if (!((_a = postInstanceIds) === null || _a === void 0 ? void 0 : _a.length)) {
            this._snack.openError('Invalid post instance ID');
            return;
        }
        this.retryPosts(postInstanceIds, callback);
    };
    PostService.prototype.retryPost = function (element, callback) {
        var _a, _b;
        if (!((_a = element) === null || _a === void 0 ? void 0 : _a.postInstanceId)) {
            this._snack.openError('Invalid post instance ID');
            return;
        }
        this.retryPosts([(_b = element) === null || _b === void 0 ? void 0 : _b.postInstanceId], callback);
    };
    PostService.prototype.retryPosts = function (postInstanceIds, callback) {
        var _this = this;
        var body = { postInstanceIds: postInstanceIds };
        this._http.post(environment.coreApiUrl + "/posts/retry-locations", body).subscribe({
            next: function () {
                _this._snack.openSuccess('Retrying the posts succeeded', 4000);
                if (callback) {
                    callback();
                }
            },
            error: function () {
                _this._snack.openError('There was an error while retrying. Please try again or contact support');
            }
        });
    };
    PostService.prototype.retrievePost = function (gid, accountId, locationId) {
        return this._http.get(environment.coreApiUrl + "/posts/" + gid + "/" + accountId + "/" + locationId + "/retrieve");
    };
    PostService.prototype.getOrdinalNumbers = function (number) {
        var suffixes = ["th", "st", "nd", "rd"];
        var value = number % 100;
        return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
    };
    PostService.prototype.generateOrdinalDays = function (start, end) {
        var ordinalSuffixes = { 1: 'st', 2: 'nd', 3: 'rd' };
        var days = [];
        for (var i = start; i <= end; i++) {
            var suffix = ordinalSuffixes[i % 10] && ![11, 12, 13].includes(i) ? ordinalSuffixes[i % 10] : 'th';
            days.push({ value: i, displayName: "" + i + suffix });
        }
        return days;
    };
    PostService.prototype.getDaysUnified = function (days) {
        var _a, _b;
        var daysNames = [];
        var nameOfDaysOptions = [
            { value: [0], displayName: 'Sunday' },
            { value: [1], displayName: 'Monday' },
            { value: [2], displayName: 'Tuesday' },
            { value: [3], displayName: 'Wednesday' },
            { value: [4], displayName: 'Thursday' },
            { value: [5], displayName: 'Friday' },
            { value: [6], displayName: 'Saturday' }
        ];
        (_a = days) === null || _a === void 0 ? void 0 : _a.forEach(function (day) {
            var _a;
            daysNames.push((_a = nameOfDaysOptions.find(function (el) { var _a, _b; return (_b = (_a = el) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.includes(day); })) === null || _a === void 0 ? void 0 : _a.displayName);
        });
        return (_b = daysNames) === null || _b === void 0 ? void 0 : _b.join(', ');
    };
    PostService.prototype.getStatusLabel = function (status) {
        var label = status;
        switch (status) {
            case 'FLAGGED_FOR_DELETE':
                label = 'Pending Delete';
                break;
            case 'CONTACT_SUPPORT':
                label = 'Contact Us';
                break;
            case 'SUCCESS':
                label = 'Published';
                break;
        }
        ;
        return label;
    };
    PostService.prototype.getModalText = function (data) {
        var _this = this;
        var _a, _b, _c;
        var msg = "\n      <div class=\"txt--md txt--left\">\n        <p class=\"mb--30\">This location has failed Google validation for the following reason: </p>";
        (_c = (_b = (_a = data) === null || _a === void 0 ? void 0 : _a.data[0]) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.forEach(function (el) {
            var _a, _b;
            (_b = (_a = el) === null || _a === void 0 ? void 0 : _a.errorDetails) === null || _b === void 0 ? void 0 : _b.forEach(function (detail) {
                var _a, _b;
                msg += "\n              <p class=\"fw--medium mt--5 mb--5\">" + _this._parseField((_a = detail) === null || _a === void 0 ? void 0 : _a.field) + "</p>\n              <p class=\"m--0\">" + ((_b = detail) === null || _b === void 0 ? void 0 : _b.message) + "</p>\n            ";
            });
        });
        msg += " \n        <p class=\"mt--30\">Review the location then try again. If the problem persist, please contact Support.</p>\n      </div>";
        return msg;
    };
    PostService.prototype._parseField = function (field) {
        if (!field)
            return '';
        var formattedString = field.replace(/[_\.]/g, ' ');
        formattedString = formattedString.split(' ')
            .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
            .join(' ');
        return formattedString;
    };
    PostService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostService_Factory() { return new PostService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.SnackbarService)); }, token: PostService, providedIn: "root" });
    return PostService;
}());
export { PostService };
