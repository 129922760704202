<div class="main-container">
    <div *ngIf="isLoading" class="spinner-cont d-flex flex-column">
        <h1 class="mb--30">Loading Info...</h1>
        <mat-spinner></mat-spinner>
    </div>
    <!-- we hide the element, because the stripe component needs to exist while we process the payment -->
    <ng-container>
        <mat-card 
            class="card-modal" 
            [ngClass]="{'card-modal-checkout': manageSubscription && !isPaymentInfo}"
            [ngClass]="{'hidden-element': showNewPricingFlow && !isPaymentInfo}"
        >
            <mat-card-header class="main-card-header">
                <mat-card-title>{{ title }}</mat-card-title>
            </mat-card-header>
            <ng-container *ngIf="!isLoading">

                <mat-card-content class="card-content">
                    <div class="content-wrapper">

                        <div class="content-col content-col-70 content-scrollable"> 
                            <ng-container *ngIf="!manageSubscription || manageSubscription && isPaymentInfo">
                                <mat-card class="w--100" *ngIf="manageSubscription">
                                <div class="payment-error d-flex flex-gap-10" *ngIf="paymentError">
                                    <div>
                                        <em class="fas fa-exclamation-triangle"></em>
                                    </div>
                                    <div>
                                        <span>Payment failed. <strong>{{ paymentErrorMsg }}</strong> Please double check your payment details, or try again later.</span>
                                    </div>
                                </div>
                            
                                    <mat-card-header>
                                        <mat-card-title>Your Subscription</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content class="subscription-details-card">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <div class="sub-detail-item">{{pricingObject?.totalLocations}} Profiles</div>
                                                <div class="sub-detail-desc">${{ (pricingObject?.unitPrice - pricingObject?.unitDiscount) }} per profile</div>
                                                <div class="sub-detail-message" *ngIf="pricingObject?.assignedTierDiscount > 0">
                                                    You qualify for pricing tier {{ pricingObject?.assignedCoreTierNumber }} and save ${{ pricingObject?.assignedTierDiscount }} per profile!</div>
                                            </div>
                                            <div>${{(pricingObject?.unitPrice - pricingObject?.unitDiscount)* pricingObject?.totalLocations}}/mo</div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card class="w--100">
                                    <mat-card-header>
                                        <mat-card-title>Select Add-Ons</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content class="subscription-details-card">
                                        <!-- single addon details -->
                                        <div class="d-flex justify-content-start flex-gap-10" *ngFor="let addon of pricingObject?.availableAddons">
                                            <div>
                                                <input type="checkbox" [(ngModel)]="addon.isActive" (change)="updateTotal()">
                                            </div>
                                            <div class="d-flex flex-column addon-details-container">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        <div class="sub-detail-item">{{addon.formattedName}}</div>
                                                        <div class="sub-detail-desc">${{ (addon.unitPrice - addon.unitDiscount) }} per profile</div>
                                                        <div class="sub-detail-message" *ngIf="addon.assignedTierDiscount > 0">
                                                            You qualify for pricing tier {{addon.assignedTierNumber}} and save ${{ addon.assignedTierDiscount }} per profile!
                                                        </div>
                                                    </div>
                                                    <div>${{(addon.unitPrice - addon.unitDiscount)* pricingObject?.totalLocations}}/mo</div>
                                                </div>
                                                <div *ngFor="let feature of addon.features" class="addon-feature-container">
                                                    <div class="addon-feature-title">{{feature.title}}</div>
                                                    <div class="addon-feature-description">{{feature.description}}</div>
                                                </div>
                                                <div *ngIf="addon.isTerminationScheduled">
                                                    <span class="addon-expiration-message">Available until {{ addon.terminationDate | date: "MMM d, y" }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card class="w--100" *ngIf="manageSubscription">
                                    <mat-card-header>
                                        <mat-card-title>Payment Method</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content class="subscription-details-card">
                                        <div>
                                            <!-- TODO: Set correct payAmount (fixed point cents) - dynamic, changes
                                                    when the user selects/unselects addons                                     
                                            -->
                                            <app-payment-method [canShowPayButton]="false"
                                                                [payAmount]="totalAmount*100"
                                                                [payCreatePaymentIntentFn]="createFirstPayment"
                                                                [payAfterPaymentOkFn]="afterPaymentOk"
                                                                [onErrorFn]="paymentErrorExists"
                                                                >
                                            </app-payment-method>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </ng-container>
                            
                            <ng-container *ngIf="isProcessingPayment">
                                <div class="checkout-container w--100 d-flex flex-column justify-content-center align-items-center">
                                    <div class="mb--30">
                                        <mat-spinner [diameter]="35"></mat-spinner>
                                    </div>
                                    <h1 class="mb--30">Processing Payment...</h1>
                                    <p class="disclaimer-text">Please don't leave this window or refresh your browser</p>
                                </div>
                            </ng-container>
                            
                            <ng-container *ngIf="paymentSuccess">
                                <div class="checkout-container w--100 d-flex flex-column justify-content-center align-items-center">
                                    <svg-icon src="/assets/images/icons/success.svg" class="mb--30"></svg-icon>
                                    <h1 class="mb--30">Payment Success</h1>
                                    <div class="disclaimer-text txt--align--center">
                                        <span>Thank you for your purchase! ${{ totalAmount }} was charged to your card. </span>
                                        <br>
                                        <span>The invoice is available for download on the Plan and Billing page.</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="content-col content-col-30">  
                            <mat-card class="w--100 summary-card">
                                <mat-card-header>
                                    <mat-card-title>Summary</mat-card-title>
                                </mat-card-header>
                                <mat-card-content>
                                    <div class="summary-item" *ngIf="manageSubscription">
                                        <div class="item-description">Pricing for {{pricingObject?.totalLocations}} profiles</div>
                                        <div class="item-detail">
                                            <div class="item-detail-name">Profiles</div>
                                            <div class="item-detail-price">${{(pricingObject?.unitPrice - pricingObject?.unitDiscount)* pricingObject?.totalLocations}}</div>
                                        </div>
                                    </div>
                                    <div class="summary-item">
                                        <div class="item-description" *ngIf="displayAddonsInSummary()">Add-Ons</div>
                                        <ng-container *ngFor="let addon of pricingObject?.availableAddons">
                                            <div class="item-detail" *ngIf="addon.isActive">
                                                <div class="item-detail-name">Bulk features</div>
                                                <div class="item-detail-price">${{ addon.totalPrice }}</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="d-flex justify-content-between txt--lg fw--black mt--10">
                                        <div>Total</div>
                                        <div>${{ totalAmount }}</div>
                                    </div>
                                    <div class="total-disclaimer">
                                        <ul>
                                            <li>Pricing is in USD, billed monthly.</li>
                                            <li>All upgrades and add-ons purchased will be pro-rated for the current month.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p class="disclaimer-text">
                                            Need assistance or looking for other purchase options? Contact us at
                                            <a href="mailto:{{supportEmail}}"> {{supportEmail}} </a>
                                        </p>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="card-actions">
                    <ng-container *ngIf="!manageSubscription || !paymentSuccess">
                        <compass-button (clicked)="close()" [color]="'noborder-blue'" [text]="cancelText"></compass-button>
                        <!-- we need a next button to move to summary section -->
                        <compass-button *ngIf="!manageSubscription" (clicked)="next()" [color]="'primary-blue'" [text]="confirmText"></compass-button>
                        <compass-button *ngIf="manageSubscription"
                                        (clicked)="next()" 
                                        [disabled]="!payComponent?.payButtonEnabled"
                                        [disabledTooltip]="'You need a valid payment method'"
                                        [loading]="isProcessingPayment"
                                        [color]="'primary-blue'" 
                                        [text]="confirmText">
                        </compass-button>
                    </ng-container>
                    
                    <ng-container *ngIf="paymentSuccess">
                        <div class="w--100 d-flex flex-gap-10 justify-content-end">
                            <compass-button (clicked)="closePlanBilling(true)" [color]="'secondary-blue'" [text]="cancelText"></compass-button>
                            <compass-button (clicked)="close(true)" [color]="'primary-blue'" [text]="confirmText"></compass-button>
                        </div>
                    </ng-container>
                </mat-card-actions>
            </ng-container>
        </mat-card>
    </ng-container>
</div>