/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./dialog-template.component";
var styles_DialogTemplateComponent = [i0.styles];
var RenderType_DialogTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogTemplateComponent, data: {} });
export { RenderType_DialogTemplateComponent as RenderType_DialogTemplateComponent };
function View_DialogTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.actionText)); _ck(_v, 1, 0, currVal_0); }); }
function View_DialogTemplateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"]))], null, null); }
export function View_DialogTemplateComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h2", [["class", "m--0 txt--xl mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i3.MatDialogTitle, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "dialog__content mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.MatDialogContent, [], null, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(10, 0, null, null, 12, "div", [["class", "dialog__footer mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "span", [["class", "alternate-theme d-inline-block mr--10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-cancel"], ["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).dialogRef.close(i1.ɵnov(_v, 15).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(15, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "button", [["class", "btn btn--action"], ["mat-flat-button", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.action() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DialogTemplateComponent_1)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DialogTemplateComponent_2)), i1.ɵdid(22, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_6 = "primary"; _ck(_v, 14, 0, currVal_6); var currVal_7 = ""; _ck(_v, 15, 0, currVal_7); var currVal_10 = _co.color; _ck(_v, 18, 0, currVal_10); var currVal_11 = _co.actionText; _ck(_v, 20, 0, currVal_11); var currVal_12 = !_co.actionText; _ck(_v, 22, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.title)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 14).disabled || null); var currVal_3 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 15).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 15).type; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = (i1.ɵnov(_v, 18).disabled || null); var currVal_9 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_8, currVal_9); }); }
export function View_DialogTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-template", [], null, null, null, View_DialogTemplateComponent_0, RenderType_DialogTemplateComponent)), i1.ɵdid(1, 114688, null, 0, i8.DialogTemplateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogTemplateComponentNgFactory = i1.ɵccf("app-dialog-template", i8.DialogTemplateComponent, View_DialogTemplateComponent_Host_0, { title: "title", actionText: "actionText", color: "color" }, { apply: "apply" }, ["*"]);
export { DialogTemplateComponentNgFactory as DialogTemplateComponentNgFactory };
