// dep
import { OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type Finalizer = (Subscription | (()=>unknown));

// Note, the VSCode angular extensions here marks the following error:
// "Class is using Angular features but is not decorated. Please add an explicit 
// Angular decorator.(-992007)". But that should be done only on later
// Angular versions. (on later versions use a simple @Directive() decorator)

/**
 * Base class to manage finalizers.
 */
export abstract class BaseComponent implements OnDestroy {
  private _finalizers : Finalizer[] = []
  protected readonly _destroy$ = new Subject<void>();
  protected isDestroyed = false;

  protected _addFinalizer(f : Finalizer) : void {
    this._finalizers.push(f);
  }

  /** 
   * Subscribes to an observable, the subscription is automatically
   * unsubscribed when the component is destroyed.
   */
  protected _subscribeSafe<T>(o         : Observable<T>, 
                              next      : (value : T) => void,
                              error?    : (error : unknown) => void,
                              complete? : () => void) : Subscription {
    return o.pipe(takeUntil(this._destroy$)).subscribe(next, error, complete);
  }
  
  /**
   * Run all finalizers
   * If derived classes override ngOnDestroy(), then super.ngOnDestroy() must
   * be called at the end of their ngOnDestroy() implementation.
   */
  ngOnDestroy() : void {
    for(const f of this._finalizers.reverse())
        try {
            if(f instanceof Subscription) {
                f.unsubscribe();
            } else {
                f();
            }
        } catch(e) {
            console.error("Error running finalizer", e);
        }        
    this._finalizers = [];
    this._destroy$.next();
    this._destroy$.complete();
    this.isDestroyed = true;
  }

}
