import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
var DownloadManagerComponent = /** @class */ (function () {
    function DownloadManagerComponent(_route, _subService, _snackS, _sessionS) {
        this._route = _route;
        this._subService = _subService;
        this._snackS = _snackS;
        this._sessionS = _sessionS;
    }
    DownloadManagerComponent.prototype.ngOnInit = function () {
        var _a, _b;
        this.type = this._route.snapshot.paramMap.get('type');
        this.id = this._route.snapshot.paramMap.get('id');
        this.companyName = ((_b = (_a = this._sessionS.getDomain()) === null || _a === void 0 ? void 0 : _a.branding) === null || _b === void 0 ? void 0 : _b.company_name) || null;
        switch (this.type) {
            case 'invoice':
                this.title = 'Invoice Download';
                this.subtitle = 'Your Invoice';
                this.iconClass = 'fas fa-file-invoice-dollar';
                break;
        }
    };
    DownloadManagerComponent.prototype.download = function () {
        var filename;
        var apiUrl;
        switch (this.type) {
            case 'invoice':
                filename = "invoice_" + this.id + ".pdf";
                apiUrl = this._subService.getInvoicePDF(this.id);
                break;
            default:
                console.error('Unknown download type:', this.type);
                return;
        }
        this.getFile(apiUrl, filename);
    };
    DownloadManagerComponent.prototype.handleDownload = function (type, id) {
        var apiUrl = null;
        var filename = null;
        switch (type) {
            case 'invoice':
                filename = "invoice_" + id + ".pdf";
                apiUrl = this._subService.getInvoicePDF(id);
                this.title = 'Invoice Download';
                this.subtitle = 'Your Invoice';
                this.iconClass = 'fas fa-file-invoice-dollar';
                break;
            default:
                console.error('Unknown download type:', type);
                return;
        }
        this.getFile(apiUrl, filename);
    };
    DownloadManagerComponent.prototype.getFile = function (apiUrl, filename) {
        var _this = this;
        if (!apiUrl) {
            this._snackS.openError('The API URL is invalid');
            return;
        }
        apiUrl.subscribe({
            next: function (response) {
                if (response && response.size > 0) {
                    _this.downloadFile(response, filename);
                }
                else {
                    _this._snackS.openError('The file could not be downloaded. Blob is empty.');
                }
            },
            error: function (err) {
                console.error('Download failed:', err);
                _this._snackS.openError('There was an issue downloading the file');
            },
        });
    };
    DownloadManagerComponent.prototype.downloadFile = function (blob, filename) {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };
    return DownloadManagerComponent;
}());
export { DownloadManagerComponent };
