import { __awaiter, __extends, __generator } from "tslib";
import { BaseComponent } from 'src/app/components/base.component';
// dep
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// app
import { SessionService } from './../../../../../services/session.service';
import { KeywordsReportDataService } from './../../../services/keywords-report-data.service';
import { KeywordsFilterConfigService } from './../../../services/keywords-filter-config.service';
import { COLORS_CHART } from './../../../../../constants/chart-color';
var SearchKeywordsComponent = /** @class */ (function (_super) {
    __extends(SearchKeywordsComponent, _super);
    function SearchKeywordsComponent(_keyFilConfS, _keyRepDataS, _route, _router, _sessionS) {
        var _this = _super.call(this) || this;
        _this._keyFilConfS = _keyFilConfS;
        _this._keyRepDataS = _keyRepDataS;
        _this._route = _route;
        _this._router = _router;
        _this._sessionS = _sessionS;
        _this.isReport = false;
        _this.isShared = false;
        // original identifiers (might be changed)
        _this.loading = true;
        _this.updatingData = true;
        _this.isComparisonVisible = false;
        _this.keywordSearchInput = '';
        _this.dataRange = { comparisonID: null, name: 'None' }; // <-- not entirely sure what it does
        _this.colors = COLORS_CHART;
        _this.currentVisibleGraph = "Total Impressions";
        _this.visibleGraph = 'total-impressions';
        _this.sort = {
            sortBy: 'impressions',
            sortOrder: -1,
            sortDirection: 'desc'
        };
        _this.sortMultiloc = {
            sortBy: 'locationName',
            direction: 'asc'
        };
        _this.displayedColumnsKeywords = ['keywords', 'impressions'];
        _this.displayedColumnsMultLoc = ['location', 'Impressions', 'Keywords', 'Branded', 'Non-branded'];
        _this.fieldsColumnsMultiLoc = [
            { displayName: 'locationName', fieldSort: 'locationName' },
            { displayName: 'totalImpressions', fieldSort: 'totalImpressions' },
            { displayName: 'totalKeywords', fieldSort: 'totalKeywords' },
            { displayName: 'brandedImpressions', fieldSort: 'brandedImpressions' },
            { displayName: 'nonBrandedImpressions', fieldSort: 'nonBrandedImpressions' }
        ];
        _this.fieldsColumnsTopics = ['rank', 'details'];
        _this.keywordsPaginate = { size: 25, page: 1 };
        _this.keywordsPagination = {
            items: [],
            total: 0,
            page: 1,
            pages: 0,
            per_page: _this.keywordsPaginate.size,
            hasNext: false,
            hasPrev: false,
        };
        _this.topicsPaginate = { size: 25, page: 1 };
        _this.topicsPagination = {
            items: [],
            total: 0,
            page: 1,
            pages: 0,
            per_page: _this.topicsPaginate.size,
            hasNext: false,
            hasPrev: false,
        };
        _this.isSingleMonth = false;
        _this._keywordChangedSubject$ = new Subject();
        _this._paginationChangedSubject$ = new Subject();
        _this._tableOrderChangedSubject$ = new Subject();
        return _this;
    }
    SearchKeywordsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.gid = this._sessionS.getSession().gid;
                        _a = this;
                        return [4 /*yield*/, this._route.parent.snapshot.params.accountId];
                    case 1:
                        _a.accountId = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this._route.parent.snapshot.params.locationId];
                    case 2:
                        _b.locationId = _c.sent();
                        this._initializeFilterConfig();
                        if (!this.isReport) { // is location-view keywords page
                            this.keywordsReportParams = {
                                gid: this.gid,
                                accountId: this.accountId,
                                locationId: this.locationId
                            };
                            this._keyFilConfS.setInitialFilterConfigData(this.keywordsReportParams);
                        }
                        else { // is a keywords report, not yet supported (reports will connect to the old component until we feature flag them)
                            // this.reportId = this._route.snapshot.params.id;
                            // if (this.report?.accounts?.length > 0) {
                            //   this.locations = this.report?.accounts[0]?.locations;
                            // }
                            // this.dataRange = (this.report?.compareToValue && Object.keys(this.report?.compareToValue).length) ? this.report?.compareToValue : this.dataRange;
                            // this.report.showComparison = this.report.showComparison || false;
                            // if(this.report.showComparison) {
                            //   this.isComparisonVisible = true;
                            //   this.displayedColumnsKeywords = ['keywords', 'impressions', 'differencePercentage'];
                            //   this.sort = {
                            //     sortBy: this.dataRange?.value,
                            //     sortOrder: -1,
                            //     sortDirection: 'desc'
                            //   }
                            // };
                        }
                        this._subscribeSafe(this._keyRepDataS.keywordsReportDataSubject$, function (keywordsReportData) {
                            console.log('report data', keywordsReportData);
                            _this.keywordsReportData = keywordsReportData;
                            _this.lineChartDataSet = _this.keywordsReportData.impressionsStats; // <- we initialize the graph with keywords data
                            _this.loading = false;
                            _this.updatingData = false;
                        }, function (error) {
                            console.error('error while retrieving report data');
                            _this.loading = false;
                            _this.updatingData = false;
                        });
                        // reload table data when the keyword filter changes
                        this._subscribeSafe(this._keywordChangedSubject$
                            .pipe(debounceTime(650)), // <- not 100% sure this is the way of declaring a pipe with _subscribeSafe but seems logical
                        function (// <- not 100% sure this is the way of declaring a pipe with _subscribeSafe but seems logical
                        value) {
                            _this.updatingData = true;
                            _this._keyFilConfS.getTableDataFilteredByKeyword(value);
                        });
                        // reload table data when pagination changes
                        this._subscribeSafe(this._paginationChangedSubject$, function (value) {
                            _this.updatingData = true;
                            _this._keyFilConfS.getTableDataPaginated(value.tableType, value.pagination);
                        });
                        // reload table data when order changes
                        this._subscribeSafe(this._tableOrderChangedSubject$, function (value) {
                            _this.updatingData = true;
                            _this._keyFilConfS.getTableDataSorted(value);
                        });
                        //
                        this._subscribeSafe(this._keyFilConfS.dateComparisonSubject$, function (period) {
                            var _a, _b;
                            _this.updatingData = true;
                            _this.isComparisonVisible = !!((_a = period) === null || _a === void 0 ? void 0 : _a.name) && ((_b = period) === null || _b === void 0 ? void 0 : _b.name) !== 'None';
                            _this.dataRange = period;
                            _this.displayedColumnsKeywords = _this.isComparisonVisible ? ['keywords', 'impressions', 'differencePercentage'] : ['keywords', 'impressions'];
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchKeywordsComponent.prototype.changedVisibleGraph = function (event) {
        this.visibleGraph = event.value;
        this.currentVisibleGraph = event.value.replace('-', ' ');
        this.setGraphData();
    };
    SearchKeywordsComponent.prototype.setGraphData = function () {
        this.lineChartDataSet = this.visibleGraph === 'total-impressions'
            ? this.keywordsReportData.impressionsStats
            : this.keywordsReportData.keywordsStats;
    };
    SearchKeywordsComponent.prototype.getImpressionsValue = function (numberOfImpressions) {
        return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
    };
    SearchKeywordsComponent.prototype.getComparisonBoxClass = function (field, row) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var percentage;
        switch (field) {
            case 'keywords':
                percentage = (_c = (_b = (_a = this.keywordsReportData) === null || _a === void 0 ? void 0 : _a.searchKeywordsData) === null || _b === void 0 ? void 0 : _b.comparison) === null || _c === void 0 ? void 0 : _c.trend;
                break;
            case 'impressions':
                percentage = (_f = (_e = (_d = this.keywordsReportData) === null || _d === void 0 ? void 0 : _d.searchImpressionsData) === null || _e === void 0 ? void 0 : _e.trend) === null || _f === void 0 ? void 0 : _f.total;
                break;
            case 'branded':
                percentage = (_j = (_h = (_g = this.keywordsReportData) === null || _g === void 0 ? void 0 : _g.searchImpressionsData) === null || _h === void 0 ? void 0 : _h.trend) === null || _j === void 0 ? void 0 : _j.direct;
                break;
            case 'non-branded':
                percentage = (_m = (_l = (_k = this.keywordsReportData) === null || _k === void 0 ? void 0 : _k.searchImpressionsData) === null || _l === void 0 ? void 0 : _l.trend) === null || _m === void 0 ? void 0 : _m.discovery;
                break;
            case 'keywords-table':
                percentage = (_p = (_o = row) === null || _o === void 0 ? void 0 : _o.prevComparison) === null || _p === void 0 ? void 0 : _p.percentage;
                break;
        }
        if (percentage > 0) {
            return 'chip--trend--success';
        }
        else if (percentage < 0) {
            return 'chip--trend--fail';
        }
        else {
            return 'chip--trend--info';
        }
    };
    SearchKeywordsComponent.prototype.getChipValue = function (value) {
        return (value === 0 ? '-' :
            value > 1000 ? 1000 :
                value);
    };
    SearchKeywordsComponent.prototype.keywordsSortChanged = function (event) {
        console.log(event);
        this._tableOrderChangedSubject$.next({ sortBy: event.active, sortOrder: event.direction });
    };
    SearchKeywordsComponent.prototype.keywordsFilterChanged = function (event) {
        this._keywordChangedSubject$.next(event);
    };
    SearchKeywordsComponent.prototype.tablePaginationChanged = function (tableType, event) {
        this._paginationChangedSubject$.next({ tableType: tableType, pagination: event });
    };
    SearchKeywordsComponent.prototype.goToLocations = function () {
        this._router.navigate(['/accounts', this.accountId, 'locations']);
    };
    SearchKeywordsComponent.prototype.handleExportCsvKeywordsSearch = function () {
        // to be defined, or removed
    };
    SearchKeywordsComponent.prototype.handleExportCsvTopic = function (value) {
        // to be defined, or removed
    };
    SearchKeywordsComponent.prototype.onDateSelectionChanged = function (event) {
        var sameYear = event.minDate.getFullYear() === event.maxDate.getFullYear();
        var sameMonth = event.minDate.getMonth() === event.maxDate.getMonth();
        this.isSingleMonth = sameYear && sameMonth;
    };
    SearchKeywordsComponent.prototype._initializeFilterConfig = function () {
        var _this = this;
        this.updatingData = true;
        this._subscribeSafe(this._keyFilConfS.getDateValidation('keyword', [this.accountId], [this.gid], [this.locationId]), function (data) {
            if (data && data.minDate && data.maxDate) {
                _this._keyFilConfS.initializeDateRestriction(data, 'keyword');
            }
            _this.updatingData = false;
        });
    };
    return SearchKeywordsComponent;
}(BaseComponent));
export { SearchKeywordsComponent };
