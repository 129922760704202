import { __awaiter, __generator } from "tslib";
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// app
import { GoogleService } from '../../services/google.service';
import { ReviewsService } from '../../services/reviews.service';
import { ModalService } from '../../services/modal.service';
var ModalReplyReviewComponent = /** @class */ (function () {
    function ModalReplyReviewComponent(config, googleS, reviewS, dialog, modalS) {
        var _this = this;
        var _a, _b, _c, _d;
        this.config = config;
        this.googleS = googleS;
        this.reviewS = reviewS;
        this.dialog = dialog;
        this.modalS = modalS;
        this.comment = new FormControl('', Validators.required);
        this.confirmDelete = false;
        this.data = config.data;
        if (this.data.activeDelete) {
            this.activeDelete = true;
        }
        this.googleS.getProfilePhoto(this.data.accountId, this.data.locationId).subscribe(function (result) {
            _this.profilePhoto = result;
        });
        this.reviewer = this.data.reviewer;
        if ((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.reviewReply) === null || _b === void 0 ? void 0 : _b.comment) {
            this.comment.setValue((_c = this.data) === null || _c === void 0 ? void 0 : _c.reviewReply.comment);
        }
        else {
            this.comment.setValue((_d = this.data) === null || _d === void 0 ? void 0 : _d.reviewReply);
        }
        if (this.data.reviewReply) {
            this.isEdit = true;
            this.title = 'edit reply';
            this.actionText = 'update';
        }
        else {
            this.isEdit = false;
            this.title = 'reply review';
            this.actionText = 'reply';
        }
    }
    ModalReplyReviewComponent.prototype.onApply = function () {
        var _this = this;
        if (this.comment.invalid) {
            return;
        }
        this.progress = true;
        this.reviewS.reply(this.data.accountId, this.data.locationId, this.data.reviewId, this.comment.value).subscribe(function (result) {
            _this.progress = false;
            if (result) {
                delete _this.data.error;
                _this.dialog.close(_this.comment.value);
                return;
            }
            _this.dialog.close(false);
        }, function (err) {
            _this.dialog.close(false);
        });
    };
    ModalReplyReviewComponent.prototype.handleConfirmDelete = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalS.openConfirmModal('Delete Reply', '')];
                    case 1:
                        if (!_a.sent()) return [3 /*break*/, 6];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, 5, 6]);
                        this.progress = true;
                        return [4 /*yield*/, this.reviewS.replyDelete(this.data.accountId, this.data.locationId, this.data.reviewId).toPromise()];
                    case 3:
                        _a.sent();
                        this.dialog.close(true);
                        return [3 /*break*/, 6];
                    case 4:
                        err_1 = _a.sent();
                        this.dialog.close(false);
                        return [3 /*break*/, 6];
                    case 5:
                        this.progress = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return ModalReplyReviewComponent;
}());
export { ModalReplyReviewComponent };
