/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
Payment Method Component
 
Used for:
  - Displaying the default Payment Method (already saved)
  - Entering a new Payment Method for future offline/online usages.
  - Changing the default Payment Method.
  - Removing a Payment Method.
  - Making a Synchronous Payment by entering a new Payment Method o reusing a
    saved one. If new, it will be saved and set as the default one.
  - As it uses the Stripe Payment Element, external authorizations
    flows like the one used on like 3D Secure are also managed in-session.

TODO: This component is currently limited to Credit Cards but can be easily
extended to other payment methods when required.

Docs:
  - https://docs.stripe.com/payments/payment-element
  - https://docs.stripe.com/payments/payment-card-element-comparison
  - https://docs.stripe.com/payments/payment-element/design-an-integration
  - https://docs.stripe.com/payments/save-customer-payment-methods
  - https://docs.stripe.com/payments/build-a-two-step-confirmation#save-payment-methods
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment#additional-options
  - https://docs.stripe.com/payments/payment-element/migration-ct#conditional-options
  - https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements#web-create-payment-intent
  - https://docs.stripe.com/payments/accept-a-payment-deferred
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=payment
  - https://docs.stripe.com/payments/accept-a-payment-deferred?platform=web&type=setup
  - https://docs.stripe.com/payments/finalize-payments-on-the-server?platform=web&type=payment#additional-options
  - Test
    - https://docs.stripe.com/payments/payment-element/migration?integration-path=future#test-the-integration

Implementation Notes / Design constraints:

- The Stripe Customer will be already created, even when the Maplabs subscription is
  still on the TRIAL stage. No need to create it at this point.

- The only way to make a Payment Element display a saved PM (or more) is to create a
  Customer Session. It doesn't work to create a Setup/Payment Intent that specify a
  `payment_method` and associate it to the Payment Element. It doesn't work either to
  specify the `readOnly=true` Payment Element flag, as that flag is only intended
  to prevent further user interaction with the P.E., probably meant to be set to `true`
  not on P.E. creation but by using a payel.update({readOnly : true}) method triggered by
  some post-creation UI event.

- PaymentIntents and SetupIntents doesn't have a clear expiration date, but is documented
  that they can become invalid with time. In constrast, the Customer Session has a
  clear expiration date for his clientSecret, and there is no need to delete them
  (also, there is no endpoint on Stripe to do that). Note that Payment/Setup Intents
  have an 'id' identity, but Customer Session doesn't, so it's like a singleton with
  a rolling secret.
  
- There is no specific Stripe Element do display the Payment Methods in a readonly
  way without a CustomerSession or Intent, all documentation point that you should
  fetch the payment methods list and use custom components to show them.

- Is possible to use set both the Payment/Setup Intent `clientSecret` and the
  CustomerSession `customerSessionClientSecret` on a Payment Element (docs are
  not clear on this point).

- We can trigger synchronous payments using a saved Payment Method from the backend
  without using a Payment Element, but is preferable to trigger it from the
  Payment Element to handle external authorizations like the 3D Secure flow on
  an in-session way. This forces us to use Customer Session to display the saved
  PM.

- Using a Customer Session, is possible to show a "delete" icon-button on the right
  of every saved PM, but the Payment Element doesn't give us a way to intercept that
  action. So we need a custom button to trigger it ourselves.

- The UI tweaks that can be done on a Payment Element is limited, this is related
  to the fact that the Payment Element is a Stripe-hosted iframe, an
  additional security layer by Stripe.
                                
TODO:
  - This component is not related to the skeleton-payment-method one, that
     component should be removed.
  - The update-card-component should also be removed after is fully replaced
     by this component.
  - Remove the 'as any' casting after the stripe-js typings lib is upgraded.


 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-method.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./payment-method.component";
import * as i8 from "../services/session.service";
import * as i9 from "../services/payments.service";
import * as i10 from "ngx-stripe";
import * as i11 from "../services/modal.service";
import * as i12 from "../services/snackbar.service";
var styles_PaymentMethodComponent = [i0.styles];
var RenderType_PaymentMethodComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentMethodComponent, data: {} });
export { RenderType_PaymentMethodComponent as RenderType_PaymentMethodComponent };
function View_PaymentMethodComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse"]], null, null, null, null, null))], null, null); }
function View_PaymentMethodComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse"]], null, null, null, null, null))], null, null); }
function View_PaymentMethodComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "btn btn--action"], ["color", "accent"], ["mat-flat-button", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Pay "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentMethodComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.payButtonEnabled; var currVal_3 = "accent"; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = (_co.state === "IN_PROGRESS_PAY"); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PaymentMethodComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse"]], null, null, null, null, null))], null, null); }
function View_PaymentMethodComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn--action"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePMSetAsDefault() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Set for Monthly Payments "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentMethodComponent_7)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.state !== "IDLE"); var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = (_co.state === "IN_PROGRESS_SET_DEFAULT"); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PaymentMethodComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "pricing-badge compass-badge-blue"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Used for Monthly Payments "]))], null, null); }
function View_PaymentMethodComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "pricing-badge compass-badge-orange"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Will be used for Monthly Payments "]))], null, null); }
function View_PaymentMethodComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse"]], null, null, null, null, null))], null, null); }
function View_PaymentMethodComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn--action"], ["color", "accent"], ["mat-flat-button", ""], ["tabIndex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePMSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Save "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentMethodComponent_11)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.state !== "IDLE") || _co.selectedPMId) || !_co.inputCompleted); var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = (_co.state === "IN_PROGRESS_SAVE"); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PaymentMethodComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse"]], null, null, null, null, null))], null, null); }
function View_PaymentMethodComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "btn btn--action"], ["color", "accent"], ["mat-flat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handlePMRemove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Remove "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentMethodComponent_13)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.state !== "IDLE"); var currVal_3 = "accent"; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = (_co.state === "IN_PROGRESS_REMOVE"); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PaymentMethodComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_6)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_8)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_9)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_10)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_12)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedPMId && (_co.selectedPMId !== _co.defaultPMId)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.selectedPMId && (_co.selectedPMId === _co.defaultPMId)); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.selectedPMId; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.selectedPMId; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.selectedPMId; _ck(_v, 10, 0, currVal_4); }, null); }
function View_PaymentMethodComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.canShowPayButton && _co.isPayMode); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isPayMode; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PaymentMethodComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["hidden", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["state=", " selectedPMId=", " defaultPMId=", " inputCompleted=", " isPayMode=", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["id", "stripe-payment-element"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.state === "LOADING"); _ck(_v, 5, 0, currVal_5); var currVal_7 = (_co.state !== "LOADING"); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; var currVal_1 = _co.selectedPMId; var currVal_2 = _co.defaultPMId; var currVal_3 = _co.inputCompleted; var currVal_4 = _co.isPayMode; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = (_co.state === "LOADING"); _ck(_v, 6, 0, currVal_6); }); }
export function View_PaymentMethodComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-method", [], null, null, null, View_PaymentMethodComponent_0, RenderType_PaymentMethodComponent)), i1.ɵdid(1, 770048, null, 0, i7.PaymentMethodComponent, [i8.SessionService, i9.PaymentsService, i10.StripeService, i11.ModalService, i12.SnackbarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentMethodComponentNgFactory = i1.ɵccf("app-payment-method", i7.PaymentMethodComponent, View_PaymentMethodComponent_Host_0, { payAmount: "payAmount", payCreatePaymentIntentFn: "payCreatePaymentIntentFn", payAfterPaymentOkFn: "payAfterPaymentOkFn", onErrorFn: "onErrorFn", canShowPayButton: "canShowPayButton" }, {}, []);
export { PaymentMethodComponentNgFactory as PaymentMethodComponentNgFactory };
